import React, { useState, useEffect } from "react" 
import { Helmet } from 'react-helmet'
import ReportEvents from '../../../components/report-organizations/ReportOrganizations'
import useBranchesHook from '../../../api/branches'


const ReportOrganizations = () => {
  

  const { getBranchesAll } = useBranchesHook({ })
  const { data } = getBranchesAll

  var [organizations, setOrganizations] = useState([])
  var [organizationBranches, setOrganizationBranches] = useState([])
    useEffect(() => {

    const userRoles = localStorage.getItem('userRole') ? JSON.parse(localStorage.getItem('userRole')): null
    var listOrganizationsTemporal = []
    var listOrganizationBranchTemporal = []
    
    if(userRoles.isSuperAdmin) {

      if(data != undefined) {
        for (let i = 0; i < data.data.length; i++) {  
          listOrganizationsTemporal.push({ 
            _id: data.data[i].organization._id,
            name: data.data[i].organization.name
          })

          listOrganizationBranchTemporal.push({ 
            organization_id: data.data[i].organization._id,
            _id: data.data[i]._id,
            name: data.data[i].name,
            role_type: "SUPER_ADMIN",
            isAdmin: true  
          })
        }
      } 
      
    } else {

      // Obtenemos listado de las organizaciones y sucursales
      for (let i = 0; i < userRoles.listRoleOrganizationBranch.length; i++) {  
        listOrganizationsTemporal.push({ 
          _id: userRoles.listRoleOrganizationBranch[i].organization_id,
          name: userRoles.listRoleOrganizationBranch[i].organization_name,
        })

        listOrganizationBranchTemporal.push({ 
          organization_id: userRoles.listRoleOrganizationBranch[i].organization_id,
          _id: userRoles.listRoleOrganizationBranch[i].branch_id,
          name: userRoles.listRoleOrganizationBranch[i].branch_name,
          role_type: userRoles.listRoleOrganizationBranch[i].role_type,
          isAdmin: userRoles.isSuperAdmin
        })
      }
      
    }
    // Quitar duplicados de las organizaciones
    listOrganizationsTemporal = Array.from(new Set(listOrganizationsTemporal.map(JSON.stringify))).map(JSON.parse)

    // Guardamos las organizaciones
    setOrganizations(listOrganizationsTemporal)

    // Quitar duplicados de las organizaciones y sus sucursales
    listOrganizationBranchTemporal = Array.from(new Set(listOrganizationBranchTemporal.map(JSON.stringify))).map(JSON.parse)

    // Guardamos las organizaciones sucursales
    setOrganizationBranches(listOrganizationBranchTemporal)

  }, [data])



  return (
    <>
      <Helmet>
        <title>Reportes de Organización</title>
        <meta property='og:title' content='Reportes de Organización' key='title' />
      </Helmet>
    

        <ReportEvents
          organizationBranches={organizationBranches}
          organizations={organizations}
        />

    </>
  )
}

export default ReportOrganizations
