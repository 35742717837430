import dynamicAPI from './dynamicAPI'
import { useMutation, useQueryClient } from 'react-query'
import useAuth from '../hooks/useAuth'

const url = '/api/auth'

const useAuthHook = () => {
  const { setAuth } = useAuth()
  const queryClient = useQueryClient()
  const postLogin = useMutation(
    async (obj) => await dynamicAPI('post', `${url}/login`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries(['login']), 
    }
  )

  const postLogout = () => {
    setAuth(null)
    localStorage.removeItem('userRole')
    return localStorage.removeItem('userInfo')
  }

  const postForgotPassword = useMutation(
    async (obj) => await dynamicAPI('post', `${url}/forgot-password`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries(['forgot password']),
    }
  )

  const postRegister = useMutation(
    async (obj) => await dynamicAPI('post', `${url}/register/postRegister`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries(['Registro exitoso']),
    }
  )

  const postLocationUser = useMutation(
    async (obj) => await dynamicAPI('post', `${url}/register/postLocationUser`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries(['Localización exitosa']),
    }
  )

  const postFindUser = useMutation(
    async (obj) => await dynamicAPI('post', `${url}/register/postFindUser`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries(['Usuario encontrado exitoso']),
    }
  )

  const postResetPassword = useMutation(
    async (obj) => await dynamicAPI('post', `${url}/reset-password`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries(['reset password']),
    }
  )

  const postAccountValidation = useMutation(
    async (obj) => await dynamicAPI('post', `${url}/account-validation`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries(['account validation']),
    }
  )

  const postInitialPassword = useMutation(
    async (obj) => await dynamicAPI('post', `${url}/initial-password`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries(['initial password']),
    }
  )

  return { postLogin, postLogout, postForgotPassword, postRegister, postLocationUser, postFindUser, postResetPassword, postAccountValidation, postInitialPassword }
}

export default useAuthHook
