import React, { useState, useEffect } from "react" 
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import FormEvents from '../../../components/event-organizations/FormEvents'
import ViewEvents from '../../../components/event-organizations/ViewEvents'
import useBranchesHook from '../../../api/branches'
import useUsersHook from '../../../api/users'
import moment from 'moment'


const EventOrganizations = () => {
  
  const [id, setId] = useState('')
  const [edit, setEdit] = useState(false)
  const { getBranchesAll } = useBranchesHook({ })
  const { data } = getBranchesAll
  const [event, setEvent] = useState([])

  const {
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      auth: true,
    },
  })

  const formCleanHandler = () => {
    setEdit(false)
    reset()
  }

  var [organizations, setOrganizations] = useState([])
  var [organizationBranches, setOrganizationBranches] = useState([])
  const { postUserById } = useUsersHook({})
  var { mutateAsync: mutateAsyncPostUserById } = postUserById
  

  useEffect(() => {

    const userRoles = localStorage.getItem('userRole') ? JSON.parse(localStorage.getItem('userRole')): null
    var listOrganizationsTemporal = []
    var listOrganizationBranchTemporal = []
    
    if(userRoles.isSuperAdmin) {

      if(data != undefined) {
        for (let i = 0; i < data.data.length; i++) {  
          listOrganizationsTemporal.push({ 
            _id: data.data[i].organization._id,
            name: data.data[i].organization.name
          })

          listOrganizationBranchTemporal.push({ 
            organization_id: data.data[i].organization._id,
            _id: data.data[i]._id,
            name: data.data[i].name,
            role_type: "SUPER_ADMIN",
            isAdmin: true  
          })
        }
      } 
      
    } else {

      // Obtenemos listado de las organizaciones y sucursales
      for (let i = 0; i < userRoles.listRoleOrganizationBranch.length; i++) {  
        listOrganizationsTemporal.push({ 
          _id: userRoles.listRoleOrganizationBranch[i].organization_id,
          name: userRoles.listRoleOrganizationBranch[i].organization_name,
        })

        listOrganizationBranchTemporal.push({ 
          organization_id: userRoles.listRoleOrganizationBranch[i].organization_id,
          _id: userRoles.listRoleOrganizationBranch[i].branch_id,
          name: userRoles.listRoleOrganizationBranch[i].branch_name,
          role_type: userRoles.listRoleOrganizationBranch[i].role_type,
          isAdmin: userRoles.isSuperAdmin
        })
      }
      
    }
    // Quitar duplicados de las organizaciones
    listOrganizationsTemporal = Array.from(new Set(listOrganizationsTemporal.map(JSON.stringify))).map(JSON.parse)

    // Guardamos las organizaciones
    setOrganizations(listOrganizationsTemporal)

    // Quitar duplicados de las organizaciones y sus sucursales
    listOrganizationBranchTemporal = Array.from(new Set(listOrganizationBranchTemporal.map(JSON.stringify))).map(JSON.parse)

    // Guardamos las organizaciones sucursales
    setOrganizationBranches(listOrganizationBranchTemporal)

  }, [data])



  const editHandler = async (event) => {

    setId(event._id)
    setEdit(true)
    
    var user = await mutateAsyncPostUserById({ _id: event.user._id })


    setEvent({
        id:event._id,
        name: event.name,
        userId: event.user._id,
        userName: event.user.name +' '+ event.user.lastname,
        latitude: event.location.coordinates[0],
        longitude: event.location.coordinates[1],
        service: event.service == '' || event.service == undefined ? 'Sin servicio asignado':event.service.name,
        state: event.state,
        subNetwork: event.subNetwork != undefined? event.subNetwork.name:'',
        organization: event.organization == '' || event.organization == undefined ? 'Sin empresa asignado':event.organization.name,
        branch: event.branch == '' || event.branch == undefined ? 'Sin sucursal asignado':event.branch.name,
        locationName: event.locationName,
        phone: event.user.phone == '' || event.user.phone == undefined ? 'Sin número celular':event.user.phone,
        email: event.user.email == '' || event.user.email == undefined ? 'Sin Correo Electrónico':event.user.email,
        sex: event.user.sex == '' || event.user.sex == undefined ? 'Sexo no ingresado':event.user.sex,
        identification: event.user.identification == '' || event.user.identification == undefined ? 'Identificación no ingresada ': event.user.typeIdentification +' - '+ event.user.identification,
        detail: event.detail,
        createdAt: event.createdAt == '' || event.createdAt == undefined ? 'Sin fecha de evento ': moment(event.createdAt).format("YYYY-MM-DD HH:mm:ss") ,
        image: user && user.image
       }
    )

  }

  return (
    <>
      <Helmet>
        <title>Eventos Organización</title>
        <meta property='og:title' content='Eventos Organización' key='title' />
      </Helmet>
      
      <FormEvents
        id={id}
        edit={edit}
        formCleanHandler={formCleanHandler}
        errors={errors}
        event={event}
        watch={watch}
      />

        <ViewEvents
          organizationBranches={organizationBranches}
          organizations={organizations}
          editHandler={editHandler}
        />


    </>
  )
}

export default EventOrganizations
