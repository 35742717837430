import { FaPenAlt, FaTrash, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { Search } from '..'

const ViewBranches = ({
  data,
  editHandler,
  formCleanHandler,
  deleteHandler,
  isLoadingDelete,
  setQ,
  q,
  searchHandler,
  isSuperAdmin,
}) => {
  return (
    <div id="containerBranch" name="containerBranch" className='table-responsive bg-light p-3 mt-2'>
      <div className='d-flex align-items-center flex-column mb-2'>
        <h3 className='fw-light text-muted'>
          Listado de Sucursales <sup className='fs-6'> [{data && data.total}] </sup>
        </h3>
        {isSuperAdmin ? (
        <button
          className='btn btn-outline-primary btn-sm shadow my-2'
          data-bs-toggle='modal'
          data-bs-target='#branchModal'
          onClick={ formCleanHandler}
        >
          Agregar una Sucursal
        </button>):''}

        <div className='col-auto'>
          <Search
            placeholder='Buscar por nombre'
            setQ={setQ}
            q={q}
            searchHandler={searchHandler}
          />
        </div>
      </div>
      <table className='table table-sm table-border text-center'>
        <thead className='border-0'>
          <tr>
            <th>Nombre</th>
            <th>Miembros</th>
            <th>Visibilidad</th>
            <th>Activo</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data.data.map((branch) => (
              <tr key={branch._id}>
                <td>{branch.name}</td>
                <td>{branch.members.length}</td>
                <td>
                  {branch.isPublic ? (
                    <FaCheckCircle className='text-success' />
                  ) : (
                    <FaTimesCircle className='text-danger' />
                  )}
                </td>
                <td>
                  {branch.active ? (
                    <FaCheckCircle className='text-success' />
                  ) : (
                    <FaTimesCircle className='text-danger' />
                  )}
                </td>

                <td>
                  <div className='btn-group'>
                    <button
                      id={`${branch._id}_edit_button`}
                      className='btn btn-primary btn-sm rounded-pill'
                      onClick={() => editHandler(branch)}
                      data-bs-toggle='modal'
                      data-bs-target='#branchModal'
                    >
                      <FaPenAlt />
                    </button>
                    {isSuperAdmin ? (                    
                      <button
                        className='btn btn-danger btn-sm ms-1 rounded-pill'
                        onClick={() => { if (window.confirm('Desea eliminar la Sucursal de la Organización? Tenga cuidado se eliminarán todos usuarios ')) deleteHandler(branch._id) } }
                        disabled={isLoadingDelete}
                      >
                        {isLoadingDelete ? (
                        <span className='spinner-border spinner-border-sm' />
                        ) : (
                        <span>
                          <FaTrash />
                        </span>
                      )}
                    </button>):''}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default ViewBranches
