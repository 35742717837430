import { Spinner, Message } from '..'
import { AiFillGolden, AiOutlineRadarChart, AiOutlineAppstoreAdd, AiOutlineUserAdd } from "react-icons/ai"
import React, { useState, useEffect } from "react"
import { FaPenAlt, FaTrash, FaSearch, FaBroom, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import useNetworksHook from '../../api/networks'
import useUsersHook from '../../api/users'
import PhoneInput from 'react-phone-number-input'
import * as Yup from "yup"
import "yup-phone"


var FormNetworks = ({
  id,
  edit,
  members,
  branchId,
  subNetwork,
  formCleanHandler,
  isLoading,
  isError,
  handleSubmit,
  submitHandler,
  error,
}) => {

  const { updateNetwork } = useNetworksHook({ page:1, q:'' })
  const { isLoading: isLoadingMember, mutateAsync: mutateAsyncUpdateNetwork } = updateNetwork
  const { postUserByInput } = useUsersHook({ limit: 100000, page: 1 })
  const { mutateAsync: mutateAsyncPostSearchUser } = postUserByInput

  var urlComplete = `${process.env.REACT_APP_URL_DOMAIN}`

  // Inicio - Network

  const [idNetwork, setIdNetwork] = useState('')
  const [nameNetwork, setNameNetwork] = useState('')
  const [typeNetwork, setTypeNetwork] = useState('')
  const [isActiveNetwork, setIsActiveNetwork] = useState(true)
  var countEditNetwork = 0


  var networks = []
  //var networksListTemporal = []


  const addNetwork = async ()  => {

    setIdNetwork('')
    setNameNetwork('')
    setTypeNetwork('')
    setIsActiveNetwork(false)

    document.getElementById('isActiveNetwork').removeAttribute('checked')

    const networkUpdate = await mutateAsyncUpdateNetwork({_id: id,
      subNetwork: [{  
        name: nameNetwork,
        typeSubNetwork: typeNetwork,
        active: isActiveNetwork,
        }],update: '0'})


      networks.splice(0)

      for (let i = 0; i < networkUpdate.subNetworks.length; i++) {
        networks.push({
          _id: networkUpdate.subNetworks[i]._id,
          name: networkUpdate.subNetworks[i].subNetwork.name,
          typeSubNetwork: networkUpdate.subNetworks[i].subNetwork.typeSubNetwork,
          members: networkUpdate.subNetworks[i].subNetwork.members,
          active: networkUpdate.subNetworks[i].subNetwork.active,  
        })
      }
      setIdNetwork('')
      setNameNetwork('')
      setTypeNetwork('')
      setIsActiveNetwork(false)

  }

  const editNetwork = async () => {
    
    if (document.getElementById('isActiveNetwork').checked) {
      setIsActiveNetwork(true)
    } else {
      setIsActiveNetwork(false)
    }

    const networkUpdate = await mutateAsyncUpdateNetwork({
      _id: id,
      subNetwork: [{
        _id: idNetwork,   
        name: nameNetwork,
        typeSubNetwork: typeNetwork,
        active: isActiveNetwork,
        }],update: '1'})

        networks.splice(0)

        for (let i = 0; i < networkUpdate.subNetworks.length; i++) {
          networks.push({
            _id: networkUpdate.subNetworks[i].subNetwork._id,
            name: networkUpdate.subNetworks[i].subNetwork.name,
            typeSubNetwork: networkUpdate.subNetworks[i].subNetwork.typeSubNetwork,
            members: networkUpdate.subNetworks[i].subNetwork.members,
            active: networkUpdate.subNetworks[i].subNetwork.active,  
          })
        }
        //setIdNetwork('')
        //setNameNetwork('')
        //setTypeNetwork('')
        //setIsActiveNetwork(false)    

  }

useEffect(() => {
  
    if(subNetwork != undefined){
      countEditNetwork++
      if(countEditNetwork ==1){
        setIdNetwork(subNetwork._id != undefined ? subNetwork._id: '')
        setNameNetwork(subNetwork.name != undefined ? subNetwork.name: '')
        setTypeNetwork(subNetwork.typeSubNetwork != undefined ? subNetwork.typeSubNetwork: '')
        setIsActiveNetwork(subNetwork.active != undefined ? subNetwork.active: false)
      }
    }
}, [subNetwork])

// Fin - Network



// Inicio - Member
const [idMember, setIdMember] = useState('')
const [typeIdentificationMember, setTypeIdentificationMember] = useState(undefined)
const [identificationMember, setIdentificationMember] = useState('')
const [nameMember, setNameMember] = useState('')
const [lastNameMember, setLastNameMember] = useState('')
const [emailMember, setEmailMember] = useState('')
const [phoneNumberMember, setPhoneNumberMember] = useState()
const [sexMember, setSexMember] = useState('')
const [birthdayMember, setBirthdayMember] = useState('')
const [editMember, setEditMember] = useState(false)
const [inputSearchMember, setInputSearchMember] = useState('')
const [searchTypeMember, setSearchTypeMember] = useState('')
const [isActiveMember, setIsActiveMember] = useState(true)
const [isDisabledMember, setIsDisabledMember] = useState(false)
const [errorsListMember, setErrorsListMember] = useState('')
var membersListTemporal = []

const cleanMember = async () => {
  
  setInputSearchMember('')
  setSearchTypeMember('')
  setIsDisabledMember(true)
  setIdMember('')
  setTypeIdentificationMember('')
  setIdentificationMember('')
  setBirthdayMember('')
  setSexMember('')
  setNameMember('')
  setLastNameMember('')
  setEmailMember('')
  setPhoneNumberMember('')
  setIsActiveMember(false)
  setEditMember(false)  
  setErrorsListMember('')
  membersListTemporal = []
  document.getElementById('isActiveMember').removeAttribute('checked')
}

const addMember = async ()  => {

  var typeMember = ''

  if(typeNetwork == 'Red Familiar')
    typeMember = 'Familia'
  
  if(typeNetwork == 'Red Amigos') 
    typeMember = 'Amigo'

  membersListTemporal.push({
    _id: idMember,
    typeIdentification:typeIdentificationMember,
    identification:identificationMember,
    birthday: birthdayMember,
    sex: sexMember,
    name: nameMember,
    lastname: lastNameMember,
    email: emailMember,
    phone: phoneNumberMember,
    role: 'USER_MEMBER', 
    typeMember: typeMember,
  })

  const networkUpdate = await mutateAsyncUpdateNetwork({_id: id,
      subNetwork: [{
        _id: idNetwork,   
        name: nameNetwork,
        typeSubNetwork: typeNetwork,
        active: isActiveNetwork,
      }],
      organization_id: '',
      branch_id: branchId ,
      members: membersListTemporal,
      urlComplete: urlComplete,
      update: '1'})
   
    members.splice(0)

    console.log(networkUpdate)

    for (let i = 0; i < networkUpdate.subNetworks.members.length; i++) {
      
        members.push({
          _id: networkUpdate.subNetworks.members[i].user._id,
          typeIdentification: networkUpdate.subNetworks.members[i].user.typeIdentification,
          identification: networkUpdate.subNetworks.members[i].user.identification,
          birthday: networkUpdate.subNetworks.members[i].user.birthday,
          sex: networkUpdate.subNetworks.members[i].user.sex,
          name: networkUpdate.subNetworks.members[i].user.name,
          lastname: networkUpdate.subNetworks.members[i].user.lastname,
          email: networkUpdate.subNetworks.members[i].user.email,
          phone: networkUpdate.subNetworks.members[i].user.phone,
          active: networkUpdate.subNetworks.members[i].active,  
        })
      
    }
    cleanMember()
    document.getElementById("close-network-member").click()
}

const editMemberFunction = (idMember) => {
  setEditMember(true)
  setIsDisabledMember(false)
  var memberFound = members.find((e) => e._id == idMember)
  setIdMember(memberFound._id)
  setTypeIdentificationMember(memberFound.typeIdentification)
  setIdentificationMember(memberFound.identification)
  setBirthdayMember(memberFound.birthday.substring(0, 10))
  setSexMember(memberFound.sex)
  setNameMember(memberFound.name)
  setLastNameMember(memberFound.lastname)
  setEmailMember(memberFound.email)
  setPhoneNumberMember(memberFound.phone)
  setIsActiveMember(memberFound.active)
}

const schemaSearchMemberEmail = Yup.object().shape({
  email: Yup.string().email("* Correo Electrónico con formato incorrecto").required("* Correo Electrónico es requerido para realizar la búsqueda")
  .matches(/\S+@\S+\.+\S+/, "* Correo Electrónico con formato incorrecto"),
})

const schemaSearchMemberType = Yup.object().shape({
  typeSearch: Yup.string().required("* Seleccione una opción de búsqueda"),
})

const schemaSearchMemberRUC = Yup.object().shape({
  searchRUC: Yup.string().required("* Ingrese el RUC para la búsqueda"),
})

const schemaSearchMemberCedula = Yup.object().shape({
  searchCedula: Yup.string().required("* Ingrese una Cédula para la búsqueda"),
})

const schemaSearchMemberPasaporte = Yup.object().shape({
  searchPasaporte: Yup.string().required("* Ingrese un Pasaporte para la búsqueda"),
})

/*const schemaAddMember = Yup.object().shape({
  name: Yup.string().required("* Nombres requerido"),
  phone: Yup.string().phone('Formato incorrecto ').required('Teléfono es requerido'), 
  sex: Yup.string().required('Teléfono es requerido'),
  birthday: Yup.string().required('Fecha Nacimiento es requerido'),
})*/

const deleteMemberFunction = async (memberObject) => {

  const networkUpdate = await mutateAsyncUpdateNetwork({
    _id: id,
    subNetwork: [{
      _id: idNetwork,   
      name: nameNetwork,
      typeSubNetwork: typeNetwork,
      active: isActiveNetwork,
      members: memberObject,
      }],update: '3'})


  members.splice(0)

    for (let i = 0; i < networkUpdate.members.length; i++) {
      members.push({
        _id: networkUpdate.subNetworks.members[i].user._id,
        typeIdentification: networkUpdate.subNetworks.members[i].user.typeIdentification,
        identification: networkUpdate.subNetworks.members[i].user.identification,
        birthday: networkUpdate.subNetworks.members[i].user.birthday,
        sex: networkUpdate.subNetworks.members[i].user.sex,
        name: networkUpdate.subNetworks.members[i].user.name,
        lastname: networkUpdate.subNetworks.members[i].user.lastname,
        email: networkUpdate.subNetworks.members[i].user.email,
        phone: networkUpdate.subNetworks.members[i].user.phone,
        active: networkUpdate.subNetworks.members[i].active,  
      })
    }
}

const searchMember = async () => {

  const retorno = await mutateAsyncPostSearchUser({input:inputSearchMember, type: searchTypeMember})
  setIsDisabledMember(false)

  var errorMessage = ''

  if(searchTypeMember != 'Email' && retorno._id == undefined ) {
    if(searchTypeMember == 'RUC') {
      errorMessage = '* RUC no encontrado. Ingrese el usuario mediante correo electrónico'
    }   
    if(searchTypeMember == 'Cedula') {
      errorMessage = '* Cedula no encontrada. Ingrese el usuario mediante correo electrónico'
    }    

    if(searchTypeMember == 'Pasaporte') {
      errorMessage = '* Pasaporte no encontrado. Ingrese el usuario mediante correo electrónico'
    }    

    cleanMember()
    setErrorsListMember(errorMessage)
  }

  setIdMember(retorno && retorno._id != undefined ? retorno._id: '')
  setTypeIdentificationMember(retorno && retorno.typeIdentification != undefined ?retorno.typeIdentification:'')
  setIdentificationMember(retorno && retorno.identification != undefined ? retorno.identification: '')
  setBirthdayMember(retorno && retorno.birthday!= undefined ? retorno.birthday.substring(0, 10):'')
  setSexMember(retorno && retorno.sex != undefined ? retorno.sex : '')
  setNameMember(retorno && retorno.name != undefined ? retorno.name: '')
  setLastNameMember(retorno && retorno.lastname!= undefined ? retorno.lastname: '')
  setEmailMember(retorno && retorno.email != undefined ? retorno.email:searchTypeMember=='Email'? inputSearchMember :'')
  setPhoneNumberMember(retorno && retorno.phone != undefined ? retorno.phone :'')
  setIsActiveMember(retorno && retorno.active != undefined ? retorno.active :false)
}


const formValidationSearchMember = async () => {

  let validacion = ''

  try {
    if(searchTypeMember == '') {
      validacion =  await schemaSearchMemberType.validate({ typeSearch: inputSearchMember })
    } else {
        if(searchTypeMember == 'Email') {
          validacion =  await schemaSearchMemberEmail.validate({ email: inputSearchMember })
        } 

        if(searchTypeMember == 'RUC') {
          validacion =  await schemaSearchMemberRUC.validate({ searchRUC: inputSearchMember })
        }

        if(searchTypeMember == 'Cedula') {
          validacion =  await schemaSearchMemberCedula.validate({ searchCedula: inputSearchMember })
        }

        if(searchTypeMember == 'Pasaporte') {
          validacion =  await schemaSearchMemberPasaporte.validate({ searchPasaporte: inputSearchMember })
        }
    }  
  }
  catch (errors) {
    setErrorsListMember(errors.message)     
  }  
 
  if(validacion!== ''){
    searchMember()
  }
}

// Fin - Member


  return (
    <div
      className='modal fade'
      id='networkModal'
      tabIndex='-1'
      aria-labelledby='networkModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-grande'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='networkModalLabel'>
              {edit ? 'Editar Red' : 'Agregar Red'}
            </h3>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={formCleanHandler}
            ></button>
          </div>
          <div className='modal-body'>
            
            
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <a className="nav-link active" id="icon-tab-0" data-bs-toggle="tab" href="#icon-tabpanel-0" role="tab" aria-controls="icon-tabpanel-0" aria-selected="true"><AiOutlineRadarChart   /> Datos de la Red</a>
            </li>
            <li className="nav-item" role="presentation" style={{display: edit === true ? 'block' : 'none'}}> 
              <a className="nav-link" id="icon-tab-1" data-bs-toggle="tab" href="#icon-tabpanel-1" role="tab" aria-controls="icon-tabpanel-1" aria-selected="false"><AiOutlineAppstoreAdd  /> Usuarios </a>
            </li>
          </ul>
          <div className="tab-content pt-3" id="tab-content">
            <div className="tab-pane active" id="icon-tabpanel-0" role="tabpanel" aria-labelledby="icon-tab-0">
              {isLoading ? (
                <Spinner />
              ) : isError ? (
                <Message variant='danger'>{error}</Message>
              ) : (
                <form onSubmit={handleSubmit(submitHandler)}>
                  
                  <div className='row'>
                    <div className="col-md-6">
                      <label htmlFor="nameNetwork"><b>Nombres:</b></label>
                      <input
                        type='text'
                        name='nameNetwork'
                        value={nameNetwork}
                        onChange={(event) => setNameNetwork(event.target.value)}
                        className='form-control'
                      /> 
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="typeNetwork"><b>Tipo de Red:</b></label>
                      <select onChange={(event) => setTypeNetwork(event.target.value)} 
                        name='typeNetwork' className='form-control' value={typeNetwork}> 
                        <option key="" >Seleccione una opción</option>
                        <option key="Red Familiar" >Red Familiar</option>
                        <option key="Red Amigos" >Red Amigos</option>
                      </select>
                    </div>
                            
                    <div className="col-md-6">    
                      <label htmlFor="isActiveNetwork"><b>Estado:</b></label>
                      <div className='form-check form-switch form-control'>
                        <input className='form-check-input'
                          type ='checkbox'
                          id ='isActiveNetwork'
                          name ='isActiveNetwork'
                          value ={isActiveNetwork}
                          onChange={(event) => setIsActiveNetwork(event.target.checked)}
                        />
                      <label className='form-check-label' htmlFor='isActiveMember'>Activo</label>
                      </div>
                    </div>
                              
                    <div className='row'>&nbsp;</div>
                    <div className='row'>
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <button type='button' onClick={edit ? editNetwork : addNetwork }   data-bs-dismiss='modal' className='btn btn-primary form-control' >
                          <AiFillGolden  /> &nbsp; {edit ? 'Actualizar' : 'Crear '}
                        </button>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </form>
              )}  
            </div>
            <div className="tab-pane" id="icon-tabpanel-1" role="tabpanel" aria-labelledby="icon-tab-1">
            <center>
              <a className="btn btn-primary" href="#open-modal-member-network" onClick={() => cleanMember()}> <AiOutlineUserAdd /> &nbsp;Agregar un Miembro</a>
            </center>        
            <br></br>
            <table className='table * table-sm table-border'>
              <thead className='border-0'>
                <tr className='text-center'>
                  <th>Nombre del Miembro</th>
                  <th>Estado</th>
                  <th>Identificación</th>
                  <th>Número Celular</th>
                  <th>Email</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                       
              { members.map(( listValue, index ) => {
                return (
                <tr key={index} className='text-center'>
                  <td>{listValue.name}&nbsp;{listValue.lastname}</td>
                  <td>
                    {listValue.active ? (
                      <FaCheckCircle className='text-success' />
                    ) : (
                      <FaTimesCircle className='text-danger' />
                    )}
                  </td>
                  <td>{listValue.identification}</td>
                  <td>{listValue.phone}</td>
                  <td>{listValue.email}</td>
                  <td>
                    <div className='btn-group'>
                      <a className="btn btn-primary btn-sm rounded-pill"  href="#open-modal-member-network"  onClick={() => editMemberFunction(listValue._id)}> <FaPenAlt /></a>
                        &nbsp;
                      <button className='btn btn-danger btn-sm ms-1 rounded-pill'   onClick={() => { if (window.confirm('Desea eliminar el miembro de la Red ?')) deleteMemberFunction(listValue) } }>
                        <span><FaTrash /></span>
                      </button>
                    </div>
                  </td>
                </tr> )
                      })}          
                </tbody>     
              </table>
                    <div id="open-modal-member-network" className="modal-window-subinternal" style={{marginTop:'280px'}}>
                      <div>
                          <b><h5 >
                            {editMember ? 'Editar Usuario' : 'Agregar Usuario'}
                          </h5></b>
                          <a href="#" id="close-network-member" title="Close" className="modal-close">&#x2715;</a>
                        <hr></hr>                        
                        <div className='row'>
                          <div className="col-md-12">
                          
                          {editMember == false ? (
                            <div className='row'>
                              <div className="col-md-12 text-center">
                                <label><b>Búsqueda:</b></label>
                                &nbsp;&nbsp;
                                <input
                                      type='text'
                                      name='inputSearchMember'
                                      value={inputSearchMember}
                                      onChange={(event) => setInputSearchMember(event.target.value)}
                                  />
                                 &nbsp;&nbsp;
                                <select onChange={(event) => setSearchTypeMember(event.target.value)} style={{height:'28px'}}
                                  name='searchTypeMember' value={searchTypeMember}> 
                                  <option value="" >Seleccione una opción</option>
                                  <option value="Email" >Correo electrónico</option>
                                  <option value="RUC" >RUC</option>
                                  <option value="Cedula" >Cedula</option>
                                  <option value="Pasaporte" >Pasaporte</option>
                                </select>  
                                &nbsp;
                                <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Buscar">
                                <button type='button' className='btn btn-primary btn-sm rounded-pill'
                                     onClick={() => formValidationSearchMember()}>
                                    <FaSearch />
                                  </button>
                                </span> 
                                &nbsp;
                                <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Limpiar">
                                <button className='btn btn-primary btn-sm rounded-pill'
                                    onClick={() => cleanMember()}>
                                    <FaBroom />
                                  </button>
                                </span>  
                              </div>  
                              &nbsp;
                              <div className='row text-center'>
                                { errorsListMember ? ( <span className='text-danger'>{errorsListMember}</span> ):''}
                              </div>
                              <hr></hr>
                            </div>
                            
                          ):''}
                          
                          </div>
                        </div>
                          
                        <div className='row'>
                          <div className="col-md-6">
                            <label htmlFor="typeIdentificationMember"><b>Tipo Identificación:</b></label>
                            <select onChange={(event) => setTypeIdentificationMember(event.target.value)} disabled={isDisabledMember}
                                name='typeIdentificationMember' className='form-control' value={typeIdentificationMember}> 
                                <option key="" >Seleccione una opción</option>
                                <option key="RUC" >RUC</option>
                                <option key="Cedula" >Cedula</option>
                                <option key="Pasaporte" >Pasaporte</option>
                            </select>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="identificationMember"><b>Identificación:</b></label>
                            <input
                                  type='text'
                                  name='identificationMember'
                                  value={identificationMember}
                                  onChange={(event) => setIdentificationMember(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledMember}
                              /> 
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="nameMember"><b>Nombres:</b></label>
                            <input
                                  type='text'
                                  name='nameMember'
                                  value={nameMember}
                                  onChange={(event) => setNameMember(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledMember}
                              /> 
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="lastNameMember"><b>Apellidos:</b></label>
                            <input
                                  type='text'
                                  name='lastNameMember'
                                  value={lastNameMember}
                                  onChange={(event) => setLastNameMember(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledMember}
                              /> 
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="emailMember"><b>Correo Electrónico:</b></label>
                            <input
                                  type='email'
                                  name='emailMember'
                                  value={emailMember}
                                  onChange={(event) => setEmailMember(event.target.value)}
                                  className='form-control'
                                  disabled
                              /> 
                          </div>  

                          <div className="col-md-6">
                              <label htmlFor="phoneNumberMember"><b>Teléfono:</b></label>    
                              <PhoneInput
                                name="phoneNumberMember"
                                placeholder="Ingrese un número telefónico"
                                value={phoneNumberMember}
                                onChange={setPhoneNumberMember}
                                className='form-control'
                                disabled={isDisabledMember}
                              />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="sexMember"><b>Sexo:</b></label>
                            <select onChange={(event) => setSexMember(event.target.value)} disabled={isDisabledMember}
                                name='sexMember' className='form-control' value={sexMember}> 
                                <option key="" >Seleccione una opción</option>
                                <option key="Masculino" >Masculino</option>
                                <option key="Femenino" >Femenino</option>
                            </select>
                          </div>    
                          <div className="col-md-6">
                            <label htmlFor="birthdayMember"><b>F. Nacimiento:</b></label>
                            <input
                                  type='date'
                                  name='birthdayMember'
                                  value={birthdayMember}
                                  onChange={(event) => setBirthdayMember(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledMember}
                              /> 
                          </div>
                          <div className="col-md-6">    
                            <label htmlFor="isActiveMember"><b>Estado:</b></label>
                            <div className='form-check form-switch form-control'>
                              <input className='form-check-input'
                                type ='checkbox'
                                id ='isActiveMember'
                                name ='isActiveMember'
                                value ={isActiveMember}
                                onChange={(event) => setIsActiveMember(event.target.checked)}
                                disabled={isDisabledMember}
                              />
                              <label className='form-check-label' htmlFor='isActiveMember'>
                                Activo
                              </label>
                            </div>
                          </div>
                            
                          <div className='row'>&nbsp;</div>
                          <div className='row'>
                            <div className="col-md-4">
                              
                            </div>
                            <div className="col-md-4">
                              <button type='button' onClick={() => addMember()}   className='btn btn-primary form-control' disabled={isDisabledMember} >
                              {isLoadingMember ? (
                                <span className='spinner-border spinner-border-sm' />
                                ) : (
                                  ''
                                )} &nbsp;
                                <AiOutlineUserAdd /> &nbsp; {editMember ? 'Actualizar' : 'Crear '}
                              </button>    
                            </div>
                            <div className="col-md-4">
                              
                            </div>
                          </div>

                        </div>
                          
                      </div>
                    </div> 
            </div>  
          </div>  
           

                 
            

          </div>
        </div>
      </div>
    </div>
  )
}

export default FormNetworks
