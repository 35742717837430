import { useEffect, useState } from 'react'
import { AiOutlineUserAdd, AiOutlineHome, AiOutlineWarning   } from "react-icons/ai"
import { FormContainer, Message } from '../../components'
import { useNavigate, Link } from 'react-router-dom'
import PhoneInput from 'react-phone-number-input'
import { useForm } from 'react-hook-form'
import useAuthHook from '../../api/auth'
import useAuth from '../../hooks/useAuth'
import { Helmet } from 'react-helmet'

//import * as Yup from "yup"
import "yup-phone"

const Register = () => {
  useAuth()
  const { postRegister, postLocationUser,postFindUser } = useAuthHook()
  const navigate = useNavigate()
  const { auth } = useAuth()
  const {
    handleSubmit,
    reset,
  } = useForm()

  const [availableBranches, setAvailableBranches] = useState(false)
  const [branch, setBranch] = useState('')
  var [branches, setBranches] = useState([])

  const [typeIdentificationUser, setTypeIdentificationUser] = useState(undefined)
  const [identificationUser, setIdentificationUser] = useState('')
  const [nameUser, setNameUser] = useState('')
  const [lastNameUser, setLastNameUser] = useState('')
  const [emailUser, setEmailUser] = useState('')
  const [phoneNumberUser, setPhoneNumberUser] = useState()
  const [sexUser, setSexUser] = useState('')
  const [birthdayUser, setBirthdayUser] = useState('')


  const {mutateAsync: mutateAsyncPostUserLocation} = postLocationUser  

  var options = { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }

  async function success(pos) {
      
    var crd = pos.coords;
      console.log(crd.latitude +''+crd.longitude)
      
    let branches = await mutateAsyncPostUserLocation({
        latitude: crd.latitude,
        longitude: crd.longitude,
      })

      console.log(branches)
      if(branches.length > 0) {
        setAvailableBranches(true)
        setBranches(branches)
      } else {
        setAvailableBranches(false)
      }
      
  }


  
  const validateIdentification = async (identification) => {

    // Buscamos mediante el Id del branch dentro de los branches que tenemos si tiene auth 
    console.log('identification1 '+identification)
    
    console.log('branches 1')
    console.log(branches)

    console.log('branch 1')
    console.log(branch)

    const branchSelected = branches.filter(branchObject => branchObject.branchId === branch)
    console.log('sdsdsdsd branchSelected 1')
    console.log(branchSelected[0].auth)

    if(branchSelected[0].auth) {
      // Consumimos el servicio de busqueda de usuarios por empresa
      const {mutateAsync: mutateAsyncPostFindUser} = postFindUser 
      let userFound = await mutateAsyncPostFindUser({
        identification: identification,
        organization: branchSelected[0].organizationId,
        branch: branchSelected[0].branchId
      })

      console.log(userFound)
    } 
    

    setIdentificationUser(identification)
  
    
  }

  function errorFuction(err) {
    console.log('ERROR(' + err.code + '): ' + err.message);
  }

  useEffect(() => {
    // Llamamos una vez a la la funcion para obtener la geolocalizacion
    navigator.geolocation.getCurrentPosition(success, errorFuction, options)
  }, [])

  const { isLoading, isError, error, isSuccess, mutateAsync } = postRegister

  useEffect(() => {
    isSuccess && reset()
  }, [isSuccess])

  useEffect(() => {
    auth?.userInfo && navigate('/')
  }, [navigate])

  const submitHandler = () => {


    // Ingresamos los datos del nuevo usuario
    mutateAsync({
      branchId: branch,
      typeIdentification: typeIdentificationUser,
      identification: identificationUser,
      name: nameUser, 
      lastname: lastNameUser,
      email: emailUser,
      phone: phoneNumberUser,
      sex: sexUser,
      birthday:birthdayUser,
    })
    // Limpiamos los campos de datos ingresados
    setTypeIdentificationUser('')
    setIdentificationUser('')
    setNameUser('')
    setLastNameUser('')
    setEmailUser('')
    setPhoneNumberUser('')
    setSexUser('')
    setBirthdayUser('')
  }

  return (
    <FormContainer>
      <Helmet>
        <title>Registro de nuevo Usuario</title>
        <meta property='og:title' content='Registro nuevo usuario' key='title' />
      </Helmet>
      <h4 className='fw-light font-monospace text-center'> { availableBranches ? ('Ingrese los siguientes datos para el acceso a la plataforma'):('Registro de nuevos usuarios en la plataforma ')}</h4>
      {isSuccess && (
        <Message variant='success'>
          Usuario creado exitosamente. Revise su correo electrónico para validar la cuenta.
        </Message>
      )}
      {isError && <Message variant='danger'>{error}</Message>}

      { availableBranches ? (
        <div className='row'>
          <div className='col-md-3'></div>
          <div className='col-md-6'>
            <label htmlFor="branch"><b>Sucursal:</b></label>
            <select onChange={(event) => setBranch( event.target.value)} 
              id='branch' name='branch' className='form-control' value={branch}> 
                  <option key="" >Seleccione una opción</option>
                {branches.map((branch) => (
                  <option key={branch.branchId} value={branch.branchId} >{branch.branchName} - {branch.organizationName} </option>
                ))}
            </select>
          </div>
          <div className='col-md-3'></div>
        </div>
       ):( 
        <div className="alert alert-primary" role="alert">
          <AiOutlineWarning  /> &nbsp; Lo sentimos, en este momento no contamos con servicios en tu área.
          <div className='row'>
            <div className="col-md-2"></div>
            <div className='col-md-8'>
              <Link to='/auth/login' className='btn btn-primary form-control' >
                <AiOutlineHome /> &nbsp;    Retornar Página Inicio
              </Link>
            </div>  
            <div className='col-md-2'></div>
          </div>   
        </div>
        )} 
      <br></br>
      { branches.length > 0 ? (  
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className='row'>
          <div className="col-md-6">
            <label htmlFor="typeIdentificationUser"><b>Tipo Identificación:</b></label>
              <select onChange={(event) => setTypeIdentificationUser(event.target.value)} 
                name='typeIdentificationUser' className='form-control' value={typeIdentificationUser}> 
                <option key="" >Seleccione una opción</option>
                <option key="RUC" >RUC</option>
                <option key="Cedula" >Cedula</option>
                <option key="Pasaporte" >Pasaporte</option>
              </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="identificationUser"><b>Identificación:</b></label>
              <input
                type='text'
                name='identificationUser'
                value={identificationUser}
                onChange={(event) => validateIdentification(event.target.value)}
                className='form-control'
                
              /> 
          </div>
          <div className="col-md-6">
            <label htmlFor="nameUser"><b>Nombres:</b></label>
              <input
                type='text'
                name='nameUser'
                value={nameUser}
                onChange={(event) => setNameUser(event.target.value)}
                className='form-control'
              /> 
          </div>
          <div className="col-md-6">
            <label htmlFor="lastNameUser"><b>Apellidos:</b></label>
              <input
                type='text'
                name='lastNameUser'
                value={lastNameUser}
                onChange={(event) => setLastNameUser(event.target.value)}
                className='form-control'
              /> 
          </div>
          <div className="col-md-6">
            <label htmlFor="emailUser"><b>Correo Electrónico:</b></label>
              <input
                  type='email'
                  name='emailUser'
                  value={emailUser}
                  onChange={(event) => setEmailUser(event.target.value)}
                  className='form-control'
              /> 
          </div>
          <div className="col-md-6">
            <label htmlFor="phoneNumberUser"><b>Teléfono:</b></label>    
                  <PhoneInput
                    name="phoneNumberUser"
                    placeholder="Ingrese un número telefónico"
                    value={phoneNumberUser}
                    onChange={setPhoneNumberUser}
                    className='form-control'
                  />
          </div>
          <div className="col-md-6">
            <label htmlFor="sexUser"><b>Sexo:</b></label>
            <select onChange={(event) => setSexUser(event.target.value)} 
                  name='sexUser' className='form-control' value={sexUser}> 
                  <option key="" >Seleccione una opción</option>
                  <option key="Masculino" >Masculino</option>
                  <option key="Femenino" >Femenino</option>
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="birthdayUser"><b>F. Nacimiento:</b></label>
                <input
                  type='date'
                  name='birthdayUser'
                  value={birthdayUser}
                  onChange={(event) => setBirthdayUser(event.target.value)}
                  className='form-control'
                /> 
          </div>
          <div className='col-12'>
            &nbsp;
          </div>
      </div>


      <div className='row'>
        <div className="col-md-6">  
          <Link to='/auth/login' className='btn btn-primary form-control' >
            <AiOutlineHome /> &nbsp;    Retornar Página Inicio
          </Link> 
        </div>
        <div className='col-md-6'>
        <button
            type='submit'
            className='btn btn-primary form-control '
            disabled={isLoading}>
            
            {isLoading ? (
              <span className='spinner-border spinner-border-sm' /> ) : ('')}        
              <AiOutlineUserAdd /> &nbsp; Crear Usuario
          </button>
        </div>  
      </div>   
        
      </form>
      ):''}

    </FormContainer>
  )
}

export default Register
