
import { Message } from '../../../components'
import { Helmet } from 'react-helmet'
import { FaPenAlt} from 'react-icons/fa'
import React from "react" 
import { Spinner } from '../../../components'
import useMemberUserOrganizationHook from '../../../api/member-user-organization'
import "yup-phone"

const MemberUserOrganization = () => {
  
  const { getMemberUserOrganization } = useMemberUserOrganizationHook({ page:1, q:'' })
  const { data, isLoading, isError, error } = getMemberUserOrganization
   
  return (

      
    <>
      <Helmet>
        <title>Mis Redes</title>
        <meta property='og:title' content='Mis Redes' key='title' />
      </Helmet>
        
      <div id="containerNetwork" name="containerNetwork" className='table-responsive bg-light p-3 mt-2'>
      <div className='d-flex align-items-center flex-column mb-2'>

      <h3 className='fw-light font-monospace text-center'>Mis Redes</h3>


        {isError && <Message variant='danger'>{error}</Message>}

        {isLoading && <Spinner />}


      </div>
      <table className='table table-sm table-border text-center'>
        <thead className='border-0'>
          <tr>
            <th>Organización</th>
            <th>Sucursal</th>
            <th>Redes</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data.data.map((userRoles) => (
            
              <tr key={userRoles._id}>
                <td>{userRoles.organization.name}</td>
                <td>{userRoles.branch.name}</td>
                <td>{userRoles.network.subNetworks.length}</td>

                <td>
                  <div className='btn-group'>
                    <button
                      className='btn btn-primary btn-sm rounded-pill'
                      data-bs-toggle='modal'
                      data-bs-target='#networkModal'>
                      <FaPenAlt /> Mis Redes
                    </button>
                  </div>
                  <div
                      className='modal fade'
                      id='networkModal'
                      tabIndex='-1'
                      aria-labelledby='networkModalLabel'
                      aria-hidden='true'
                    >
                      <div className='modal-dialog modal-dialog-grande'>
                        <div className='modal-content modal-background'>
                          <div className='modal-header'>
                            <center>
                            <h3 className='modal-title ' id='networkModalLabel'>
                            Listado de Redes del Miembro
                            </h3>
                            </center>
                            <button
                              type='button'
                              className='btn-close'
                              data-bs-dismiss='modal'
                              aria-label='Close'
                            ></button>
                          </div>
                          <div className='modal-body'>
                            <iframe  src={`/admin/auth/networks?network_id=${userRoles.network._id}&branch_id=${userRoles.branch._id}`}  width="100%"  height="1000px" ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                </td>
              </tr>
            )
            )}
        </tbody>
      </table>
    </div>
    </>
    
  
  )
}

export default MemberUserOrganization
