import { Spinner, Message } from '..'
import {
  inputCheckBox,
  inputText,
  staticInputSelect,
} from '../../utils/dynamicForm'

const FormServices = ({
  edit,
  formCleanHandler,
  isLoading,
  register,
  isError,
  errors,
  watch,
  isLoadingUpdate,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  error,
}) => {
  return (
    <div
      className='modal fade'
      id='serviceModal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='serviceModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='serviceModalLabel'>
              {edit ? 'Editar Servicio' : 'Publicar Servicio'}
            </h3>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={formCleanHandler}
            ></button>
          </div>
          <div className='modal-body'>
            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <form onSubmit={handleSubmit(submitHandler)}>


                <div className='row'>
                  
                  <div className='col-md-6'>
                      {inputText({
                      register,
                      errors,
                      label: 'Nombre:',
                      name: 'name',
                      placeholder: 'Nombre',
                    })}
                  </div>  
                  <div className='col-md-6'>
                    {inputText({
                    register,
                    errors,
                    label: 'Descripción:',
                    name: 'description',
                    placeholder: 'Descripción',
                  })}
                  </div>
                  <div className='col-md-3'>
                    {inputCheckBox({
                    register,
                    errors,
                    watch,
                    name: 'ispublic',
                    label: 'Es Público?',
                    isRequired: false,
                    placeholder: 'Es Público?',
                  })}
                  </div>
                  <div className='col-md-5'>
                    {inputText({
                      register,
                      errors,
                      label: 'Ruta:',
                      name: 'path',
                      placeholder: 'Ruta',
                    })} 
                  </div>
                  <div className='col-md-4'>
                    {staticInputSelect({
                        register,
                        errors,
                        label: 'Tipo:',
                        name: 'type',
                        placeholder: 'Tipo',
                        data: [
                          { name: 'Alerta Maxima' },
                          { name: 'Emergencia Mecanica' },
                          { name: 'Evento de Incendio' },
                          { name: 'Panico en Curso' },
                          { name: 'Emergencia Medica' },
                        ],
                      })} 
                  </div>
                  <div className='col-md-6'>
                  {staticInputSelect({
                        register,
                        errors,
                        label: 'Tipo:',
                        name: 'type',
                        placeholder: 'Tipo',
                        data: [
                          { name: 'alerta-maxima-rojo.png' },
                          { name: 'alerta-maxima-naranja.png' },
                          { name: 'alerta-maxima-verde.png' },
                          { name: 'alerta-maxima-azul.png' },
                          
                          { name: 'emergencia-mecanica-verde.png' },
                          { name: 'emergencia-mecanica-rojo.png' },
                          { name: 'emergencia-mecanica-naranja.png' },
                          { name: 'emergencia-mecanica-azul.png' },

                          { name: 'emergencia-medica-verde.png' },
                          { name: 'emergencia-medica-rojo.png' },
                          { name: 'emergencia-medica-naranja.png' },
                          { name: 'emergencia-medica-azul.png' },

                          { name: 'evento-incendio-verde.png' },
                          { name: 'evento-incendio-rojo.png' },
                          { name: 'evento-incendio-naranja.png' },
                          { name: 'evento-incendio-azul.png' },

                          { name: 'panico-verde.png' },
                          { name: 'panico-rojo.png' },
                          { name: 'panico-naranja.png' },
                          { name: 'panico-azul.png' },
                        ],
                      })}
                  </div>
                  <div className='col-md-6'>
                    {inputCheckBox({
                    register,
                    errors,
                    watch,
                    name: 'active',
                    label: 'Activo',
                    isRequired: false,
                    placeholder: 'Activo',
                  })}
                  </div>

                  
                </div>
                
                
                
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-secondary '
                    data-bs-dismiss='modal'
                    onClick={formCleanHandler}
                  >
                    Cerrar
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary '
                    disabled={isLoadingPost || isLoadingUpdate}
                  >
                    {isLoadingPost || isLoadingUpdate ? (
                      <span className='spinner-border spinner-border-sm' />
                    ) : (
                      'Enviar'
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormServices
