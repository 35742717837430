import dynamicAPI from './dynamicAPI'
import { useQuery } from 'react-query'

const url = '/api/auth'

export default function accountValidationHook(props) {

  const { token = '', id = '' } = props
  const queryKey = 'account_validation'

  const getAccountValidation = useQuery(
    queryKey,
    async () => 
    await dynamicAPI('get', `${url}/account-validation?token=${token}&id=${id}`, {}),
    { retry: 0 }
  )

  return  {
    getAccountValidation
  }    
  
}


