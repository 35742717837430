
import { FormContainer, Message } from '../../components'
import accountValidationHook from '../../api/accountValidationAPI'
import { useSearchParams, Link } from 'react-router-dom'
import { AiOutlineHome } from "react-icons/ai" 
import { Helmet } from 'react-helmet'
import { useEffect, useState } from 'react'
import { AiOutlineKey, AiOutlineClear } from "react-icons/ai"
import useAuthHook from '../../api/auth'

const AccountValidation = () => {

  const [searchParams] = useSearchParams();
  const [messageValue, setMessageValue] = useState('')
  const [errorValue, setErrorValue] = useState('')

  const [messageValueInitialPassword, setMessageValueInitialPassword] = useState('')
  const [errorValueInitialPassword, setErrorValueInitialPassword] = useState('')

  const { postInitialPassword } = useAuthHook()
  const { isLoading, mutateAsync } = postInitialPassword

  const { getAccountValidation } = accountValidationHook({
    token: searchParams.get('token'),
    id: searchParams.get('id'),
  })
  

  const { data } = getAccountValidation

  useEffect(() => {
    
    setErrorValue('')
    setMessageValue('')
    
    if(data != undefined) {

      if (data.indexOf("Error") >= 0) {
        setErrorValue(data)
      } else {
        setMessageValue(data) 
      }
    }

  }, [data])


  const [input, setInput] = useState({
    id: searchParams.get('id'),
    password: '',
    confirmPassword: ''
  })

  const [error, setError] = useState({
    id: '',
    password: '',
    confirmPassword: ''
  })

  const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(e);
  }

  const validateInput = e => {
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };
   
      switch (name) {
   
        case "password":
          if (!value) {
            stateObj[name] = "Ingrese la Contraseña.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] = "Contraseña y Confirmar Contraseña no coinciden.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
          }
          break;
   
        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Ingrese la Confirmación de la Contraseña.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Contraseña y Confirmar Contraseña no coinciden.";
          }
          break;
   
        default:
          break;
      }
   
      return stateObj;
    });
  }


  const changePassword = async () => {

    const password = input.password
    const tokenPass = searchParams.get('tokenPass')
    const id = searchParams.get('id')

   const returnInitialPassword = await mutateAsync({ password, tokenPass, id })
   console.log(returnInitialPassword.message)
   console.log(returnInitialPassword.error)

   if(returnInitialPassword.message) {
    setMessageValueInitialPassword(returnInitialPassword.message)
   }

   if(returnInitialPassword.error) {
    setErrorValueInitialPassword(returnInitialPassword.error)
   }
    

  }

  const cleanPassword = () => {
    setInput({id:searchParams.get('id'), password: '', confirmPassword: '' })
    setError({id:'' , password: '', confirmPassword: '' })
  }


  
  return (
    <FormContainer>
      <Helmet>
        <title>Proceso de validación de cuenta</title>
        <meta property='og:title' content='Proceso de validación de cuenta' key='title' />
      </Helmet>
      <h3 className=''>Proceso de validación de cuenta</h3>
      <hr></hr>

      {messageValueInitialPassword && (<Message variant='success'>{messageValueInitialPassword}</Message>)}

      {errorValueInitialPassword && <Message variant='danger'>{errorValueInitialPassword.replace('Error', '')}</Message>}

     
      <form >


      <div className="accordion" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            1. {messageValue ? messageValue : errorValue.replace('Error', '')}
            </button>
          </h2>
          <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
            {messageValue ? 'Su cuenta fue validada exitosamente' : 'Ya se encuentra validada su cuenta. Continue con el ingreso de las credenciales o realice el proceso de cambio de clave en la Página de Inicio'}

            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="true" aria-controls="flush-collapseTwo"   style={({backgroundColor:'#e7f1ff'})}>
              2. Ingrese una credencial de acceso a la plataforma
            </button>
          </h2>
          <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
             
              <div className="row">
                <div className='col-md-2'></div>
                <div className='col-md-8'>
                <input
                    type="password"
                    name="password"
                    placeholder='Ingrese la Contraseña'
                    value={input.password}
                    onChange={onInputChange}
                    onBlur={validateInput} className='form-control' ></input>
                  <center>{error.password && <span className='err' style={({color:'red'})}>{error.password}</span>}</center>  
                </div>
                <div className='col-md-2'></div>
              </div>
              &nbsp;
              <div className="row">
                <div className='col-md-2'></div>
                <div className='col-md-8'>
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder='Confirme la Contraseña'
                    value={input.confirmPassword}
                    onChange={onInputChange}
                    onBlur={validateInput} className='form-control'></input>
                  <center>{error.confirmPassword && <span className='err' style={({color:'red'})}>{error.confirmPassword}</span>}</center>
                </div>
                <div className='col-md-2'></div>
              </div>
              &nbsp;
              <div className="row">
                <div className='col-md-6'>
                    <button type='button' className='btn btn-primary form-control' disabled={isLoading}  onClick={changePassword}>
                    {isLoading ? (
                      <span className='spinner-border spinner-border-sm' />
                    ) : ( 
                      ''
                    )} <AiOutlineKey  /> &nbsp; Cambiar
                  </button> &nbsp;
                </div>
                <div className='col-md-6'>
                  <button type='button' className='btn btn-primary form-control'  onClick={() => cleanPassword()}>
                    <AiOutlineClear  /> &nbsp; Limpiar
                  </button>
                </div>
               
              </div>
            </div>
          </div>
        </div>
  
      </div>  


      <div className='row'>&nbsp;</div>  
      <div className='row'>
        <div className="col-md-3"></div>
        <div className="col-md-6">
            <Link to='/auth/login' className='btn btn-primary form-control' >
                    <AiOutlineHome /> &nbsp;    Retornar Página Inicio
            </Link> 
        </div>
        <div className="col-md-3"></div>
      </div>

      
        
      </form>
    </FormContainer>
  )
}

export default AccountValidation
