import React, { useState } from "react"
import { FaPenAlt } from 'react-icons/fa'
import { AiOutlineFileSearch} from "react-icons/ai"
import { Search } from '..'
import moment from 'moment'
import useReportHook from '../../api/report'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

const ViewEventIndividuals = ({
  data,
  editHandler,
  setQ,
  q,
  searchHandler,
}) => {

  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').format('yyyy-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('yyyy-MM-DD'))
  const [state, setState] = useState(undefined);

  const { postReport } = useReportHook()



  const {
    //data: dataUpload,
    //isLoading: isLoadingUpload,
    //isError: isErrorUpload,
    //error: errorUpload,
    mutateAsync: mutateAsyncReport,
    //isSuccess: isSuccessUpload,
  } = postReport

  

  const userInfo = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

  const submitGenerateReport = async ()  => {
    const returnData = await mutateAsyncReport({type: 'report-events', id: userInfo._id, startDate: startDate,endDate:endDate, state:state })
    const worksheet = XLSX.utils.json_to_sheet(returnData.data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Historial de Eventos")
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
    saveAs(blob, "data.xlsx")
  } 

  return (
    <div className='table-responsive bg-light mt-2'>
      <div className='d-flex align-items-center flex-column mb-2'>
        <h3 className='fw-light text-muted'>
          Listado de Eventos de Ayuda <sup className='fs-6'> [{data && data.total}] </sup> &nbsp;
          <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            <AiOutlineFileSearch /> Reportes
          </button>
        </h3>
        
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-pequeno modal-dialog-centered">
            <div className="modal-content mx-auto">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Generador de Reportes dinámico</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">

                <div className='row'>
                <div className="col-md-12">
                  <label htmlFor="startDate"><b>Fecha de Inicio:</b></label>
                  <input
                        type='date'
                        name='startDate'
                        value={startDate}
                        onChange={(event) => setStartDate(event.target.value)}
                        className='form-control'
                    />
                  </div>
                  <div className="col-md-12">
                      <label htmlFor="endDate"><b>Fecha Fin:</b></label>
                      <input
                            type='date'
                            name='endDate'
                            value={endDate}
                            onChange={(event) => setEndDate(event.target.value)}
                            className='form-control'
                        />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="state"><b>Estado:</b></label>
                    <select onChange={(event) => setState(event.target.value)}
                        name='state' className='form-control'> 
                        <option key="" >Seleccione una opción</option>
                        <option key="TODOS" >TODOS</option>
                        <option key="En proceso de atencion" >En proceso de atencion</option>
                        <option key="Atendida" >Atendida</option>
                        <option key="Asignada" >Asignada</option>
                    </select>
                  </div> 
                </div>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" onClick={submitGenerateReport} className="btn btn-primary">Generar</button>
              </div>
            </div>
          </div>
        </div>


        <div className='col-auto'>
          <Search
            placeholder='Buscar por Evento'
            setQ={setQ}
            q={q}
            searchHandler={searchHandler}
          />
        </div>
      </div>
      <table className='table table-sm table-border'>
        <thead className='border-0'>
          <tr className='text-center'>
            <th>Evento</th>
            <th>Fecha Evento</th>
            <th>Solicitante ayuda</th>
            <th>Estado</th>
            <th>Red</th>
            <th>Servicio</th>
            <th>Cliente</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data.data.map((event) => (
              <tr key={event._id} className='text-center'>
                <td>{event.name}</td>
                <td>{moment(event.createdAt).format("YYYY-MM-DD hh:mm:ss")}</td>
                <td>{event.user.name} {event.user.lastname}</td>
                <td>{event.state}</td>
                <td>{event.subNetwork.name}</td>
                <td>{event.service.name}</td>
                <td></td>
                <td>
                  <div className='btn-group'>
                    
                    <button
                      className='btn btn-primary btn-sm rounded-pill'
                      onClick={() => editHandler(event)}
                      data-bs-toggle='modal'
                      data-bs-target='#eventModal'
                    >
                      &nbsp;&nbsp;<FaPenAlt /> &nbsp; Atender&nbsp;&nbsp;
                    </button>

                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default ViewEventIndividuals
