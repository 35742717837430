import { Spinner, Message } from '..'
import { AiOutlineApartment, AiOutlineTeam, AiOutlineUserAdd, AiOutlineAppstoreAdd, AiOutlineEdit, AiOutlineUpload, AiOutlineFileDone, AiOutlineGlobal, AiOutlineSync, AiOutlinePushpin } from "react-icons/ai"
import { FaPenAlt, FaTrash, FaSearch, FaBroom, FaCheckCircle, FaTimesCircle, FaEye, FaQuestion } from 'react-icons/fa'
import React, { useState, Component } from "react" 
import PhoneInput from 'react-phone-number-input'
import useBranchesHook from '../../api/branches'
import useUsersHook from '../../api/users'
import useUploadHook from '../../api/upload'
import GoogleMap from 'google-map-react'

import * as Yup from "yup"
import "yup-phone"
import * as XLSX from 'xlsx'
import * as FileSaver from "file-saver"

import {
  inputCheckBox,
  inputText,
} from '../../utils/dynamicForm'

// Inicio - Mapa

let circle
let marker
var defaultRadius = 1000
var defaultLatitude = 0
var defaultLongitude = 0
var currentLatitude = 0
var currentLongitude = 0
let zoom =10
let isLoadingUpdate = false
let updateSuccess = ''

class GoogleMaps extends Component {

  
  constructor(props) {
    super(props)
    this.state = {  latitude: defaultLatitude, longitude:  defaultLongitude , inputRadius: defaultRadius  }
  }

  addCircleRadius = (radiusValue) => {
    var radius = radiusValue
    if(radius >= defaultRadius) {
      this.setState({
        inputRadius: Number(radius)
      })
      this.forceUpdate()
      circle.setRadius(Number(radius))
    }
  }

  resetCircleRadius = () => {
 

      if (navigator.geolocation) {
        // Obtenemos la actual localizacion de los usuarios
        navigator.geolocation.getCurrentPosition(
          (position) => {
            
            // Obtenemos las coordenadas en dos variables
            defaultLatitude = position.coords.latitude 
            defaultLongitude = position.coords.longitude 
            defaultRadius = 10000
        
            this.setState({
              latitude: defaultLatitude, longitude: defaultLongitude, inputRadius: defaultRadius
            })
            
            this.forceUpdate()
            circle.setRadius(Number(defaultRadius))
            circle.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
          
            document.getElementById('inputRadius').value = defaultRadius
            marker.setPosition({lat: defaultLatitude, lng: defaultLongitude})

            marker.bindTo("position", circle, "center")

          },
          // Si hay un error al conseguir la localizacion del usuario
          (error) => {
            console.error('Error getting user location:', error);
          }
        )
      }
      // Si la localización no es soportada por el navegador del usuario
      else {
        console.error('Geolocation no es soportado por este navegador.');
      }
  }
  
  addLocationBranch = async (state) => {

    var { mutateAsync: mutateAsyncUpdate } = this.props.updateBranch.updateBranch
    isLoadingUpdate = true
    updateSuccess = 'Nueva Ubicación de la sucursal'

    const updateReturn = await mutateAsyncUpdate({
        _id: this.props.branch_id, 
        location: state,
        type: 'update_branch_location'})
        console.log(updateReturn)
        isLoadingUpdate = false
        updateSuccess = ''
      
  }  
  
  render() {
   
    
    var apiIsLoaded = (map, maps) => {
      if (navigator.geolocation) {
        // Obtenemos la actual localizacion de los usuarios
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Obtenemos las coordenadas en dos variables
            currentLatitude = position.coords.latitude
            currentLongitude = position.coords.longitude
            
            defaultLatitude = this.props.latitudeBranch.latitudeBranch == '' ? currentLatitude : this.props.latitudeBranch.latitudeBranch
            defaultLongitude = this.props.longitudeBranch.longitudeBranch == '' ? currentLongitude : this.props.longitudeBranch.longitudeBranch
            defaultRadius = this.props.radiusBranch.radiusBranch == '' ? defaultRadius : this.props.radiusBranch.radiusBranch

            this.setState({
              latitude: defaultLatitude, longitude: defaultLongitude, inputRadius: defaultRadius
            })
            circle = new maps.Circle({
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.3,
              map,
              center: { lat: defaultLatitude, lng: defaultLongitude },
              radius: defaultRadius,
              draggable: true
            })
            
            marker = new maps.Marker({
              position: {lat: defaultLatitude, lng: defaultLongitude},
              map,
              draggable: true
            })
            
            document.getElementById('inputRadius').value = defaultRadius


            marker.bindTo("position", circle, "center")

            maps.event.addListener(marker, 'dragend',
              function(marker) {
                var latLng = marker.latLng
                this.setState({
                  latitude: latLng.lat()
                })
                this.forceUpdate() 
                this.setState({
                  longitude: latLng.lng()
                }) 
                this.forceUpdate()
              }.bind(this)
            )

          },
          // Si hay un error al conseguir la localizacion del usuario
          (error) => {
            console.error('Error getting user location:', error);
          }
        )
      }
      // Si la localización no es soportada por el navegador del usuario
      else {
        console.error('Geolocation no es soportado por este navegador.');
      }
    }
    return (
      <div>
        <div style={{ height: "500px", width: "100%" }}>
          
          <div className="card border-info mb-3 z-1 position-absolute" style={({zIndex:'9999', marginTop: '10px' })}>
            <div className="card-header"><b>Ubicación del cerco virtual de disponibilidad del servicio</b></div>
            <div className="card-body">
              <p className="card-text">Seleccione el radio de disponibilidad de su servicio y de clic en el botón Guardar</p>

                <div className='row text-center'>
                  <div className="col-md-6">
                    <label htmlFor="inputRadius"><b>Seleccione el Radio:</b></label>
                  </div>
                  <div className="col-md-3">&nbsp;</div>
                  <div className="col-md-3">&nbsp;</div>
                </div>

                <div className='row'>
                  <div className="col-md-6">
                      <select onChange={(event) => this.addCircleRadius(event.target.value)} 
                            id='inputRadius' name='inputRadius' className='form-control'> 
                            <option value="">Seleccione una opción</option>
                            <option value="100">100 m</option>
                            <option value="200">200 m</option>
                            <option value="300">300 m</option>
                            <option value="400">400 m</option>
                            <option value="500">500 m</option>
                            <option value="1000">1 km</option>
                            <option value="2000">2 km</option>
                            <option value="3000">3 km</option>
                            <option value="4000">4 km</option>
                            <option value="5000">5 km</option>
                            <option value="6000">6 km</option>
                            <option value="7000">7 km</option>
                            <option value="8000">8 km</option>
                            <option value="9000">9 km</option>
                            <option value="10000">10 km</option>
                            <option value="15000">15 km</option>
                            <option value="20000">20 km</option>
                            <option value="25000">25 km</option>
                            <option value="30000">30 km</option>
                            <option value="35000">35 km</option>
                            <option value="40000">40 km</option>
                            <option value="45000">45 km</option>
                            <option value="50000">50 km</option>
                            <option value="60000">60 km</option>
                            <option value="70000">70 km</option>
                            <option value="80000">80 km</option>
                            <option value="90000">90 km</option>
                            <option value="100000">100 km</option>
                            <option value="200000">200 km</option>
                            <option value="300000">300 km</option>
                            <option value="400000">400 km</option>
                            <option value="500000">500 km</option>
                            <option value="1000000">1000 km</option>
                        </select>
                  </div>    
                  <div className="col-md-3">
                      <button name="reset" type='button' onClick={() => this.resetCircleRadius()}  className='btn btn-outline-primary'  > 
                            <AiOutlineSync  />&nbsp;Resetear  
                      </button>
                  </div>        
                  <div className="col-md-3">
                      <button name="save" type='button' onClick={() => this.addLocationBranch(this.state)}  className='btn btn-outline-primary'  > 
                            {isLoadingUpdate ? (
                                <span className='spinner-border spinner-border-sm' />
                                ) : (
                                  <AiOutlinePushpin  />
                                )}
                             &nbsp;Guardar  
                      </button>
                  </div>
                </div>
            </div>
          </div>
          <GoogleMap
            bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_API_KEY}`}}
            defaultZoom={zoom}
            center={{ lat: this.state.latitude, lng: this.state. longitude }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
            className="z-2 position-absolute"
          />
        </div>
      </div>
    )
  }
}

// Fin - Mapa

var FormBranches = ({
  id,
  edit,
  users,
  members,
  validationWithLicense,
  formCleanHandler,
  isLoading,
  register,
  isError,
  errors,
  watch,
  //isLoadingUpdate,
  refetchHandler,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  error,
  isSuperAdmin,
  latitudeBranch,
  longitudeBranch,
  radiusBranch,
}) => {

  const { updateBranch } = useBranchesHook({ page:1, q:'' })
  const { isLoading: isLoadingBranch, error: errorUpdate, mutateAsync: mutateAsyncUpdate } = updateBranch
  const { postUserByInput } = useUsersHook({ limit: 100000, page: 1 })
  const { mutateAsync: mutateAsyncPostSearchUser } = postUserByInput
  const { postUpload } = useUploadHook()
  const { isLoading: isLoadingUpload, mutateAsync: mutateAsyncUpload } = postUpload

  var urlComplete = `${process.env.REACT_APP_URL_DOMAIN}` 

  // Inicio - User
  const [idUser, setIdUser] = useState('')
  const [typeIdentificationUser, setTypeIdentificationUser] = useState(undefined)
  const [identificationUser, setIdentificationUser] = useState('')
  const [nameUser, setNameUser] = useState('')
  const [lastNameUser, setLastNameUser] = useState('')
  const [emailUser, setEmailUser] = useState('')
  const [phoneNumberUser, setPhoneNumberUser] = useState()
  const [sexUser, setSexUser] = useState('')
  const [birthdayUser, setBirthdayUser] = useState('')
  const [editUser, setEditUser] = useState(false)
  const [inputSearchUser, setInputSearchUser] = useState('')
  const [isActiveUser, setIsActiveUser] = useState(true)
  const [roleUser, setRoleUser] = useState('')
  const [isDisabledUser, setIsDisabledUser] = useState(true)
  const [isDisabledUserEdit, setIsDisabledUserEdit] = useState(true)
  const [errorsListUser, setErrorsListUser] = useState('')



  const cleanUser = async () => {
  
    setInputSearchUser('')
    setIsDisabledUser(true)
    setIsDisabledUserEdit(true)
    setIdUser('')
    setTypeIdentificationUser('')
    setIdentificationUser('')
    setBirthdayUser('')
    setSexUser('')
    setNameUser('')
    setLastNameUser('')
    setEmailUser('')
    setPhoneNumberUser('')
    setIsActiveUser(false)
    setRoleUser('')
    setEditUser(false)
    setErrorsListUser('')
    document.getElementById('isActiveUser').removeAttribute('checked')

  }

  const addUser = async ()  => {

    let formatDateBirthday = ''
    // Validacion de la fecha por conversion del componente web
    if(birthdayUser!= '') {
      let dateArray= birthdayUser.split('-')
      formatDateBirthday =`${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
    }

  const branchUpdate = await mutateAsyncUpdate({_id: id, 
        users: {
          _id: idUser,
          typeIdentification:typeIdentificationUser,
          identification:identificationUser,
          birthday: formatDateBirthday,     
          sex: sexUser,
          name: nameUser,
          lastname: lastNameUser,
          email: emailUser,
          phone: phoneNumberUser,  
          role: roleUser,
        }, type: 'update_branch_users', urlComplete: urlComplete})

    users.splice(0)

    for (let i = 0; i < branchUpdate.users.length; i++) {
      users.push({
        _id: branchUpdate.users[i].user._id,
        typeIdentification: branchUpdate.users[i].user.typeIdentification,
        identification: branchUpdate.users[i].user.identification,
        birthday: branchUpdate.users[i].user.birthday,
        sex: branchUpdate.users[i].user.sex,
        name: branchUpdate.users[i].user.name,
        lastname: branchUpdate.users[i].user.lastname,
        email: branchUpdate.users[i].user.email,
        phone: branchUpdate.users[i].user.phone,  
        active: branchUpdate.users[i].active, 
        role: branchUpdate.users[i].typeUser
      })
    }
    cleanUser()
    document.getElementById("close-user-branch").click()
}

const editUserFunction = (idUser) => {
  setEditUser(true)
  setIsDisabledUser(true)
  setIsDisabledUserEdit(false)
  var userFound = users.find((e) => e._id == idUser)
  
  setIdUser(userFound._id)
  setTypeIdentificationUser(userFound.typeIdentification)
  setIdentificationUser(userFound.identification)
  setBirthdayUser(userFound.birthday != undefined ? userFound.birthday.substring(0, 10):'')
  setSexUser(userFound.sex)
  setNameUser(userFound.name)
  setLastNameUser(userFound.lastname)
  setEmailUser(userFound.email)
  setPhoneNumberUser(userFound.phone)
  setIsActiveUser(userFound.active)
  setRoleUser(userFound.role  == 'Admin'? 'ADMIN_BRANCH':'MONITOR_BRANCH')
  if(userFound.active)
    document.getElementById("isActiveUser").checked = true
  else
    document.getElementById("isActiveUser").checked = false
}

const schemaSearchUser = Yup.object().shape({
  search: Yup.string().required("* Ingrese un correo electrónico, un número telefónico o una identificación"),
})

const deleteUserFunction = async (idUser) => {

  const branchUpdate =  await mutateAsyncUpdate({_id: id, 
    users: {
      _id: idUser,
    },
    type: 'delete_branch_users',
  })

  users.splice(0)

    for (let i = 0; i < branchUpdate.users.length; i++) {
      users.push({
        _id: branchUpdate.users[i].user._id,
        typeIdentification: branchUpdate.users[i].user.typeIdentification,
        identification: branchUpdate.users[i].user.identification,
        birthday: branchUpdate.users[i].user.birthday,
        sex: branchUpdate.users[i].user.sex,
        name: branchUpdate.users[i].user.name,
        lastname: branchUpdate.users[i].user.lastname,
        email: branchUpdate.users[i].user.email,
        phone: branchUpdate.users[i].user.phone,  
        active: branchUpdate.users[i].active,
        role: branchUpdate.users[i].typeUser
      })
    }
  }

  const searchUser = async () => {

    const retorno = await mutateAsyncPostSearchUser({input:inputSearchUser })
    var errorMessage = ''
    cleanUser()
  
    if(retorno._id == undefined ) {
      errorMessage = '* No se ha encontrado ningún usuario. Ingrese un nuevo usuario'
      setErrorsListUser(errorMessage)
      setEditUser(false)
      setIsDisabledUser(false)
      setIsDisabledUserEdit(false)
    } else {  
      setEditUser(false)
      setIsDisabledUser(true)
      setIsDisabledUserEdit(false)
    }
    
    setIdUser(retorno && retorno._id != undefined ? retorno._id: '')
    setTypeIdentificationUser(retorno && retorno.typeIdentification != undefined ?retorno.typeIdentification:'')
    setIdentificationUser(retorno && retorno.identification != undefined ? retorno.identification: '')
    setBirthdayUser(retorno && retorno.birthday!= undefined ? retorno.birthday.substring(0, 10):'')
    setSexUser(retorno && retorno.sex != undefined ? retorno.sex : '')
    setNameUser(retorno && retorno.name != undefined ? retorno.name: '')
    setLastNameUser(retorno && retorno.lastname!= undefined ? retorno.lastname: '')
    setEmailUser(retorno && retorno.email != undefined ? retorno.email: '')
    setPhoneNumberUser(retorno && retorno.phone != undefined ? retorno.phone: '')
    setIsActiveUser(retorno && retorno.active != undefined ? retorno.active :false)  
  }

  const formValidationSearchUser = async () => {
    let validacion = undefined
    try {
    
      validacion = await schemaSearchUser.validate({ search: inputSearchUser })
    }
    catch (errors) {
      setErrorsListUser(errors.message)     
    }  
  
    if(validacion!== ''){
      searchUser()
    }
  }

// Fin - User

// Inicio - Member
const [idMember, setIdMember] = useState('')
const [typeIdentificationMember, setTypeIdentificationMember] = useState(undefined)
const [identificationMember, setIdentificationMember] = useState('')
const [nameMember, setNameMember] = useState('')
const [lastNameMember, setLastNameMember] = useState('')
const [emailMember, setEmailMember] = useState('')
const [phoneNumberMember, setPhoneNumberMember] = useState()
const [sexMember, setSexMember] = useState('')
const [birthdayMember, setBirthdayMember] = useState('')
const [editMember, setEditMember] = useState(false)
const [inputSearchMember, setInputSearchMember] = useState('')
const [isActiveMember, setIsActiveMember] = useState(true)
const [isDisabledMember, setIsDisabledMember] = useState(true)
const [isDisabledMemberEdit, setIsDisabledMemberEdit] = useState(true)
const [errorsListMember, setErrorsListMember] = useState('')


const cleanMember = async () => {
  
  setInputSearchMember('')
  setIsDisabledMember(true)
  setIsDisabledMemberEdit(true)
  setIdMember('')
  setTypeIdentificationMember('')
  setIdentificationMember('')
  setBirthdayMember('')
  setSexMember('')
  setNameMember('')
  setLastNameMember('')
  setEmailMember('')
  setPhoneNumberMember('')
  setIsActiveMember(false)
  //setEditMember(false)
  setErrorsListMember('')
  document.getElementById('isActiveMember').removeAttribute('checked')

}

const addMember = async ()  => {

  let formatDateBirthday = ''
  // Validacion de la fecha por conversion del componente web
  if(birthdayMember!= '') {
    let dateArray= birthdayMember.split('-')
    formatDateBirthday =`${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
  }

   
   const branchUpdate = await mutateAsyncUpdate({_id: id, 
      members: {
    _id: idMember,
    typeIdentification:typeIdentificationMember,
    identification:identificationMember,
    birthday: formatDateBirthday,
    sex: sexMember,
    name: nameMember,
    lastname: lastNameMember,
    email: emailMember,
    phone: phoneNumberMember,
    active: isActiveMember,
    role: 'MEMBER_BRANCH',
    },type: 'update_branch_members'})  
  
    members.splice(0)

    for (let i = 0; i < branchUpdate.members.length; i++) {
      members.push({
        _id: branchUpdate.members[i].user._id,
        typeIdentification: branchUpdate.members[i].user.typeIdentification,
        identification: branchUpdate.members[i].user.identification,
        birthday: branchUpdate.members[i].user.birthday,
        sex: branchUpdate.members[i].user.sex,
        name: branchUpdate.members[i].user.name,
        lastname: branchUpdate.members[i].user.lastname,
        email: branchUpdate.members[i].user.email,
        phone: branchUpdate.members[i].user.phone,
        active: branchUpdate.members[i].active, 
        network: branchUpdate.members[i].network._id,
        branch: id,   
      })
    }
    cleanMember()
    document.getElementById("close-branch-member").click()
}

const editMemberFunction = (idMember) => {
  setEditMember(true)
  setIsDisabledMember(true)
  setIsDisabledMemberEdit(false)
  var memberFound = members.find((e) => e._id == idMember)

  setIdMember(memberFound._id)
  setTypeIdentificationMember(memberFound.typeIdentification)
  setIdentificationMember(memberFound.identification)
  setBirthdayMember(memberFound.birthday != undefined ? memberFound.birthday.substring(0, 10):'')
  setSexMember(memberFound.sex)
  setNameMember(memberFound.name)
  setLastNameMember(memberFound.lastname)
  setEmailMember(memberFound.email)
  setPhoneNumberMember(memberFound.phone)
  setIsActiveMember(memberFound.active)
  if(memberFound.active)
    document.getElementById("isActiveMember").checked = true
  else
    document.getElementById("isActiveMember").checked = false
}

const schemaSearchMember = Yup.object().shape({
  search: Yup.string().required("* Ingrese un correo electrónico, un número telefónico o una identificación"),
})

const deleteMemberFunction = async (idMember) => {
  
  const branchUpdate =  await mutateAsyncUpdate({_id: id, 
    members: {
      _id: idMember,
    },
    type: 'delete_branch_members',
  })

  console.log(branchUpdate)
  if(branchUpdate.members.length > 1) {
    members.splice(0)
  }

    for (let i = 0; i < branchUpdate.members.length; i++) {
      members.push({
        _id: branchUpdate.members[i].user._id,
        typeIdentification: branchUpdate.members[i].user.typeIdentification,
        identification: branchUpdate.members[i].user.identification,
        birthday: branchUpdate.members[i].user.birthday,
        sex: branchUpdate.members[i].user.sex,
        name: branchUpdate.members[i].user.name,
        lastname: branchUpdate.members[i].user.lastname,
        email: branchUpdate.members[i].user.email,
        phone: branchUpdate.members[i].user.phone,
        active: branchUpdate.members[i].active, 
        network: branchUpdate.members[i].network._id, 
        branch: id,  
      })
    }
    document.getElementById("icon-tab-1")!= undefined ? document.getElementById("icon-tab-1").click():''  
}

const searchMember = async () => {

  const retorno = await mutateAsyncPostSearchUser({input:inputSearchMember})
  var errorMessage = ''
  cleanMember()

  if(retorno._id == undefined ) {
    errorMessage = '* No se ha encontrado ningún usuario. Ingrese un nuevo usuario'
    setErrorsListMember(errorMessage)
    setEditMember(false)
    setIsDisabledMember(false)
    setIsDisabledMemberEdit(false)
  } else {
    setEditMember(false)
    setIsDisabledMember(true)
    setIsDisabledMemberEdit(false)
  }

  setIdMember(retorno && retorno._id != undefined ? retorno._id: '')
  setTypeIdentificationMember(retorno && retorno.typeIdentification != undefined ?retorno.typeIdentification:'')
  setIdentificationMember(retorno && retorno.identification != undefined ? retorno.identification: '')
  setBirthdayMember(retorno && retorno.birthday!= undefined ? retorno.birthday.substring(0, 10):'')
  setSexMember(retorno && retorno.sex != undefined ? retorno.sex: '')
  setNameMember(retorno && retorno.name != undefined ? retorno.name: '')
  setLastNameMember(retorno && retorno.lastname!= undefined ? retorno.lastname: '')
  setEmailMember(retorno && retorno.email != undefined ? retorno.email:'')
  setPhoneNumberMember(retorno && retorno.phone != undefined ? retorno.phone:'')
  setIsActiveMember(retorno && retorno.active != undefined ? retorno.active: false)
}

const formValidationSearchMember = async () => {
  let validacion = undefined
  try {
  
    validacion = await schemaSearchMember.validate({ search: inputSearchMember })
  }
  catch (errors) {
    setErrorsListMember(errors.message)     
  }  

  if(validacion!== ''){
    searchMember()
  }
}

const handleFileChange = (e) => {
  if (e.target.files) {
    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = async () => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      const uploadResponse = await mutateAsyncUpload({type: 'upload-members', formData, id: id })

      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
      const fileExtension = ".xlsx"

      const workSheet = XLSX.utils.json_to_sheet(uploadResponse.resultado_excel);
      XLSX.utils.sheet_add_aoa(workSheet, [["MIEMBRO", "MAIL", "RESPUESTA"]], { origin: "A1" })
      const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] }
      const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, 'Respuesta - Proceso subida de miembros' + fileExtension)
      
      members.splice(0)

      for (let i = 0; i < uploadResponse.resultado_members.members.length; i++) {
        members.push({
          _id: uploadResponse.resultado_members.members[i].user._id,
          typeIdentification: uploadResponse.resultado_members.members[i].user.typeIdentification,
          identification: uploadResponse.resultado_members.members[i].user.identification,
          birthday: uploadResponse.resultado_members.members[i].user.birthday,
          sex: uploadResponse.resultado_members.members[i].user.sex,
          name: uploadResponse.resultado_members.members[i].user.name,
          lastname: uploadResponse.resultado_members.members[i].user.lastname,
          email: uploadResponse.resultado_members.members[i].user.email,
          phone: uploadResponse.resultado_members.members[i].user.phone,
          active: uploadResponse.resultado_members.members[i].active, 
          network: uploadResponse.resultado_members.members[i].network._id,
          branch: id,   
        })
      }
    }
  }
}
// Fin - Member

const refetchValues = (update, branchId ) => {

  if(update == true) {
    refetchHandler(true, branchId)
  }
}

  return (
    <div
      className='modal fade'
      id='branchModal'
      tabIndex='-1'
      aria-labelledby='branchModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-grande'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='branchModalLabel'>
              {edit ? 'Editar Sucursal' : 'Agregar Sucursal'}
            </h3>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={formCleanHandler}
            ></button>
          </div>
          <div className='modal-body'>
          {errorUpdate ? <Message variant='danger'>{errorUpdate}</Message> :''}
          {updateSuccess ? <Message variant='success'>{updateSuccess}</Message> :''}
            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <form onSubmit={handleSubmit(submitHandler)}>
                
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a className="nav-link active" id="icon-tab-0"  onClick={() => refetchValues(true, id)}   data-bs-toggle="tab" href="#icon-tabpanel-0" role="tab" aria-controls="icon-tabpanel-0" aria-selected="true"><AiOutlineApartment  /> Datos de la Sucursal</a>
                  </li>
                  <li className="nav-item" role="presentation" style={{display: edit === true ? 'block' : 'none'}}>
                    <a className="nav-link" id="icon-tab-1" data-bs-toggle="tab" href="#icon-tabpanel-1" role="tab" aria-controls="icon-tabpanel-1" aria-selected="false"><AiOutlineAppstoreAdd  onClick={formCleanHandler} /> Miembros </a>
                  </li>
                  <li className="nav-item" role="presentation" style={{display: edit === true ? 'block' : 'none'}}>
                    <a className="nav-link" id="icon-tab-2" data-bs-toggle="tab" href="#icon-tabpanel-2" role="tab" aria-controls="icon-tabpanel-2" aria-selected="false"><AiOutlineTeam  /> Administradores </a>
                  </li>
                  
                  {isSuperAdmin ? (  
                  <li className="nav-item" role="presentation" style={{display: edit === true ? 'block' : 'none'}}>
                    <a className="nav-link" id="icon-tab-3" data-bs-toggle="tab" href="#icon-tabpanel-3" role="tab" aria-controls="icon-tabpanel-3" aria-selected="false"><AiOutlineFileDone /> Licencias</a>
                  </li>):''}

                  {isSuperAdmin ? (
                  <li className="nav-item" role="presentation" style={{display: edit === true ? 'block' : 'none'}}>
                    <a className="nav-link" id="icon-tab-4" data-bs-toggle="tab" href="#icon-tabpanel-4" role="tab" aria-controls="icon-tabpanel-4" aria-selected="false"><AiOutlineGlobal /> Mi Ubicación</a>
                  </li>):''}
                  
                </ul>

                <div className="tab-content pt-3" id="tab-content">
                  <div className="tab-pane active" id="icon-tabpanel-0" role="tabpanel" aria-labelledby="icon-tab-0">
                    
                      <div className='row'>
                        <div className='col-md-6'>   
                          {inputText({
                            register,
                            errors,
                            label: 'Nombre:',
                            name: 'name',
                            placeholder: 'Nombre',
                          })}
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='isPublic'><b>Visibilidad:</b></label>
                          {inputCheckBox({
                            register,
                            errors,
                            watch,
                            name: 'isPublic',
                            label: 'Es Público?',
                            isRequired: false,
                            placeholder: 'Es Público?',
                          })}
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor='active'><b>Estado</b></label>
                          {inputCheckBox({
                            register,
                            errors,
                            watch,
                            name: 'active',
                            label: 'Activo',
                            isRequired: false,
                            placeholder: 'Activo',
                          })}                
                        </div> 
                      </div>        
                      
                      <div className='row'>
                        <div className="col-md-5">
                          
                        </div>
                        <div className="col-md-2">
                          <button type='submit' className='btn btn-primary form-control' data-bs-dismiss='modal' disabled={isLoadingPost}>
                            <AiOutlineEdit/> &nbsp; {isLoadingPost ? (
                              <span className='spinner-border spinner-border-sm' />
                            ) : (
                              edit ? 'Actualizar' : 'Crear '
                            )}
                          </button>    
                        </div>
                        <div className="col-md-5">
                          
                        </div>
                      </div>
                            
                  </div>
                  <div className="tab-pane" id="icon-tabpanel-1" role="tabpanel" aria-labelledby="icon-tab-1">
                  {validationWithLicense ? (
                  <div>
                  <center>
                    <a className="btn btn-primary" href="#open-modal-member" onClick={() => cleanMember()}> <AiOutlineUserAdd /> &nbsp;Agregar un Miembro</a>                   
                      &nbsp;
                      <label htmlFor="uploadFile" className="btn btn-primary btn-block btn-outlined">
                      {isLoadingUpload ? (
                      <span className='spinner-border spinner-border-sm' />
                      ) : (
                        ''
                      )}
                       <AiOutlineUpload /> &nbsp; Subir Miembros
                        </label>
                      <input id="uploadFile" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleFileChange} className='form-control' style={{ display: 'none' }} />
                      &nbsp;
                      <a href="#open-modal-upload-members" className='btn btn-primary btn-sm rounded-pill' >
                        <FaQuestion />
                      </a>
                  </center>
                  <div id="open-modal-upload-members" className="modal-window-internal">
                        <div>
                        <b><h5 >Manual para subir múltiples miembros por Sucursal </h5></b>
                          <a href="#" title="Close" className="modal-close">&#x2715;</a>
                          <hr></hr>
                          <ol type = "1">
                              <li>Descargar la siguiente plantilla de Excel para la la subida de miembros <a href={process.env.PUBLIC_URL + "/PlantillaMiembros.xlsx"} download={"Plantilla miembros.xlsx"} >Plantilla</a></li>
                              <li>Llenar las columnas con la información. Las columnas con color verde es información requerida</li>
                              <li>Dar clic en el botón <b>Subir Miembros</b> y seleccionar el archivo. Esperamos a que se realice el respectivo proceso</li>
                              <li>Se descarga un archivo con la información del procesamiento de cada uno de los miembros agregados o cuales tengan problemas</li>
                          </ol>


                        </div>
                  </div>
                  <br></br>
                   </div>):(
                    <div>
                      <div className="alert alert-primary" role="alert">
                      No existe Licencia, no puede agregar miembros a la Sucursal. Agregue una Licencia.
                      </div>
                      <br></br>
                    </div>
                    )}

                    <table className='table * table-sm table-border'>
                      <thead className='border-0'>
                        <tr className='text-center'>
                          <th>Nombre del Miembro</th>
                          <th>Estado</th>
                          <th>Identificación</th>
                          <th>Número Celular</th>
                          <th>Email</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                       
                      { members.map(( listValue, index ) => {
                        return (
                          <tr key={index} className='text-center'>
                            <td>{listValue.name}&nbsp;{listValue.lastname}</td>
                            <td>
                              {listValue.active ? (
                                <FaCheckCircle className='text-success' />
                              ) : (
                                <FaTimesCircle className='text-danger' />
                              )}
                            </td>
                            <td>{listValue.identification}</td>
                            <td>{listValue.phone}</td>
                            <td>{listValue.email}</td>
                            <td>
                              <div className='btn-group'>
                                <a className="btn btn-primary btn-sm rounded-pill"  href="#open-modal-member"  onClick={() => editMemberFunction(listValue._id)}> <FaPenAlt /></a>
                                &nbsp;
                                <button className='btn btn-danger btn-sm ms-1 rounded-pill'   onClick={() => { if (window.confirm('Desea eliminar el miembro de la Sucursal ?')) deleteMemberFunction(listValue._id)  } }>
                                  <span><FaTrash /></span>
                                </button>
                                &nbsp;
                              
                                
                                <a className='btn btn-success btn-sm ms-1 rounded-pill'   href={`#open-modal-network${index}`}  ><FaEye /></a>
                                <div id={`open-modal-network${index}`} className="modal-window-internal">
                                  <div style={{ marginTop:"250px" }}  >
                                      <b><h5 >
                                        Listado de Redes del Miembro
                                      </h5></b>
                                      <a href="#" title="Close" className="modal-close">&#x2715;</a>
                                    <hr></hr>

                                    <iframe  src={`/admin/auth/networks?network_id=${listValue.network._id}&branch_id=${listValue.branch}`}  width="100%"  height="1000px" ></iframe>
                                  </div>
                                </div>
                                

                              </div>
                            </td>
                          </tr>
                        )
                      })}          
                      </tbody>     
                    </table>
                    <div id="open-modal-member" className="modal-window-internal">
                      <div>
                          <b><h5 >
                            {editMember ? 'Editar Miembro' : 'Agregar Miembro'}
                          </h5></b>
                          <a href="#" id="close-branch-member" title="Close" className="modal-close">&#x2715;</a>
                        <hr></hr>
                        <div className='row'>
                          <div className="col-md-12">
                          
                          {editMember == false ? (

                            <div className='row'>
                              <div className="row">
                                <div className="col-md-2">
                                  <label><b>Búsqueda:</b></label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                        type='text'
                                        name='inputSearchMember'
                                        placeholder='Usuarios por identificación, email o teléfono'
                                        value={inputSearchMember}
                                        style={({ width:'100%'})}
                                        onChange={(event) => setInputSearchMember(event.target.value)}      
                                    />
                                </div>
                                <div className="col-md-2">
                                  <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Buscar">
                                    <button type='button' className='btn btn-primary btn-sm rounded-pill'
                                      onClick={() => formValidationSearchMember()}>
                                      <FaSearch />
                                    </button>
                                  </span> 
                                  &nbsp;
                                  <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Limpiar">
                                    <button className='btn btn-primary btn-sm rounded-pill'
                                      onClick=
                                      {() => cleanMember()}>
                                      <FaBroom />
                                    </button>
                                  </span>
                                </div>
                              </div>  
                              &nbsp;
                              <div className='row text-center'>
                                { errorsListMember ? ( <span className='text-danger'>{errorsListMember}</span> ):''}
                              </div>
                              <br></br> 
                              <hr></hr>
                            </div>
                            
                          ):''}
                          
                          </div>
                        </div>
                          
                        <div className='row'>
                          <div className="col-md-6">
                            <label htmlFor="typeIdentificationMember"><b>Tipo Identificación:</b></label>
                            <select onChange={(event) => setTypeIdentificationMember(event.target.value)} disabled={isDisabledMember}
                                name='typeIdentificationMember' className='form-control' value={typeIdentificationMember}> 
                                <option key="" >Seleccione una opción</option>
                                <option key="RUC" >RUC</option>
                                <option key="Cedula" >Cedula</option>
                                <option key="Pasaporte" >Pasaporte</option>
                            </select>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="identificationMember"><b>Identificación:</b></label>
                            <input
                                  type='text'
                                  name='identificationMember'
                                  value={identificationMember}
                                  onChange={(event) => setIdentificationMember(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledMember}
                              /> 
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="nameMember"><b>Nombres:</b></label>
                            <input
                                  type='text'
                                  name='nameMember'
                                  value={nameMember}
                                  onChange={(event) => setNameMember(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledMember}
                              /> 
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="lastNameMember"><b>Apellidos:</b></label>
                            <input
                                  type='text'
                                  name='lastNameMember'
                                  value={lastNameMember}
                                  onChange={(event) => setLastNameMember(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledMember}
                              /> 
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="emailMember"><b>Correo Electrónico:</b></label>
                            <input
                                  type='email'
                                  name='emailMember'
                                  value={emailMember}
                                  onChange={(event) => setEmailMember(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledMember}
                              /> 
                          </div>  

                          <div className="col-md-6">
                              <label htmlFor="phoneNumberMember"><b>Teléfono:</b></label>    
                              <PhoneInput
                                name="phoneNumberMember"
                                placeholder="Ingrese un número telefónico"
                                value={phoneNumberMember}
                                onChange={setPhoneNumberMember}
                                className='form-control'
                                disabled={isDisabledMember}
                              />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="sexMember"><b>Sexo:</b></label>
                            <select onChange={(event) => setSexMember(event.target.value)} disabled={isDisabledMember}
                                name='sexMember' className='form-control' value={sexMember}> 
                                <option key="" >Seleccione una opción</option>
                                <option key="Masculino" >Masculino</option>
                                <option key="Femenino" >Femenino</option>
                            </select>
                          </div>    
                          <div className="col-md-6">
                            <label htmlFor="birthdayMember"><b>F. Nacimiento:</b></label>
                            <input
                                  type='date'
                                  name='birthdayMember'
                                  value={birthdayMember}
                                  onChange={(event) => setBirthdayMember(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledMember}
                              /> 
                          </div>
                          <div className="col-md-6">    
                            <label htmlFor="isActiveMember"><b>Estado:</b></label>
                            <div className='form-check form-switch form-control'>
                              <input className='form-check-input'
                                type ='checkbox'
                                id ='isActiveMember'
                                name ='isActiveMember'
                                value ={isActiveMember}
                                onChange={(event) => setIsActiveMember(event.target.checked)}
                                disabled={isDisabledMemberEdit}
                              />
                              <label className='form-check-label' htmlFor='isActiveMember'>
                                Activo
                              </label>
                            </div>
                          </div>
                            
                          <div className='row'>&nbsp;</div>
                          <div className='row'>
                            <div className="col-md-4">
                            <p style={({display: 'none'})}>{idMember}</p>
                            </div>
                            <div className="col-md-4">
                              <button type='button' onClick={() => addMember()} className='btn btn-primary form-control' disabled={isDisabledMemberEdit} >
                              <AiOutlineUserAdd /> &nbsp;
                              
                              {isLoadingBranch ? (
                                <span className='spinner-border spinner-border-sm' />
                                ) : ('')} 

                              {editMember ? 'Actualizar' : 'Crear '}
                              </button>    
                            </div>
                            <div className="col-md-4">
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="icon-tabpanel-2" role="tabpanel" aria-labelledby="icon-tab-2">
                         
                    <center><a className="btn btn-primary" href="#open-modal-user" onClick={() => cleanUser()}> <AiOutlineUserAdd /> &nbsp;Agregar un Usuario</a></center>
                    <br></br>

                    <table className='table * table-sm table-border'>
                      <thead className='border-0'>
                        <tr className='text-center'>
                          <th>Nombre del Usuario</th>
                          <th>Activo</th>
                          <th>Rol</th>
                          <th>Identificación</th>
                          <th>Número Celular</th>
                          <th>Email</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                      { users.map(( listValue, index ) => {
                        return (
                          <tr key={index} className='text-center'>
                            <td>{listValue.name}&nbsp;{listValue.lastname}</td>
                            <td>
                              {listValue.active ? (
                                <FaCheckCircle className='text-success' />
                              ) : (
                                <FaTimesCircle className='text-danger' />
                              )}
                            </td>
                            <td>{listValue.role}</td>
                            <td>{listValue.identification}</td>
                            <td>{listValue.phone}</td>
                            <td>{listValue.email}</td>
                            <td>
                              <div className='btn-group'>
                                <a className="btn btn-primary btn-sm rounded-pill"  href="#open-modal-user"  onClick={() => editUserFunction(listValue._id)}> <FaPenAlt /></a>
                                <button className='btn btn-danger btn-sm ms-1 rounded-pill'   onClick={() => { if (window.confirm('Desea eliminar el usuario de la Sucursal ?')) deleteUserFunction(listValue._id) } }>
                                  <span><FaTrash /></span>
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}          
                      </tbody>     
                    </table>
                    <div id="open-modal-user" className="modal-window-internal">
                      <div>
                          <b><h5 >
                            {editUser ? 'Editar Usuario' : 'Agregar Usuario'}
                          </h5></b>
                          <a href="#" id="close-user-branch" title="Close" className="modal-close">&#x2715;</a>
                        <hr></hr>
                        <div className='row'>
                          <div className="col-md-12">
                          
                          {editUser == false ? (
                            <div className='row'>
                            <div className="row">
                              <div className="col-md-2">
                                <label><b>Búsqueda:</b></label>
                              </div>
                              <div className="col-md-8">
                                <input
                                      type='text'
                                      name='inputSearchUser'
                                      placeholder='Usuarios por identificación, email o teléfono'
                                      value={inputSearchUser}
                                      style={({ width:'100%'})}
                                      onChange={(event) => setInputSearchUser(event.target.value)}      
                                  />
                              </div>
                              <div className="col-md-2">
                                <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Buscar">
                                  <button type='button' className='btn btn-primary btn-sm rounded-pill'
                                    onClick={() => formValidationSearchUser()}>
                                    <FaSearch />
                                  </button>
                                </span> 
                                &nbsp;
                                <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Limpiar">
                                  <button className='btn btn-primary btn-sm rounded-pill'
                                    onClick=
                                    {() => cleanUser()}>
                                    <FaBroom />
                                  </button>
                                </span>
                              </div>
                            </div>  
                            &nbsp;
                            <div className='row text-center'>
                              { errorsListUser ? ( <span className='text-danger'>{errorsListUser}</span> ):''}
                            </div>
                            <br></br> 
                            <hr></hr>
                          </div>                           
                            
                          ):''}
                          
                          </div>
                        </div>
                          
                        <div className='row'>
                          <div className="col-md-6">
                            <label htmlFor="typeIdentificationUser"><b>Tipo Identificación:</b></label>
                            <select onChange={(event) => setTypeIdentificationUser(event.target.value)} disabled={isDisabledUser}
                                name='typeIdentificationUser' className='form-control' value={typeIdentificationUser}> 
                                <option key="" >Seleccione una opción</option>
                                <option key="RUC" >RUC</option>
                                <option key="Cedula" >Cedula</option>
                                <option key="Pasaporte" >Pasaporte</option>
                            </select>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="identificationUser"><b>Identificación:</b></label>
                            <input
                                  type='text'
                                  name='identificationUser'
                                  value={identificationUser}
                                  onChange={(event) => setIdentificationUser(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledUser}
                              /> 
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="nameUser"><b>Nombres:</b></label>
                            <input
                                  type='text'
                                  name='nameUser'
                                  value={nameUser}
                                  onChange={(event) => setNameUser(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledUser}
                              /> 
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="lastNameUser"><b>Apellidos:</b></label>
                            <input
                                  type='text'
                                  name='lastNameUser'
                                  value={lastNameUser}
                                  onChange={(event) => setLastNameUser(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledUser}
                              /> 
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="emailUser"><b>Correo Electrónico:</b></label>
                            <input
                                  type='email'
                                  name='emailUser'
                                  value={emailUser}
                                  onChange={(event) => setEmailUser(event.target.value)}
                                  className='form-control'
                                  disabled
                              /> 
                          </div>  

                          <div className="col-md-6">
                              <label htmlFor="phoneNumberUser"><b>Teléfono:</b></label>    
                              <PhoneInput
                                name="phoneNumberUser"
                                placeholder="Ingrese un número telefónico"
                                value={phoneNumberUser}
                                onChange={setPhoneNumberUser}
                                className='form-control'
                                disabled={isDisabledUser}
                              />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="sexUser"><b>Sexo:</b></label>
                            <select onChange={(event) => setSexUser(event.target.value)} disabled={isDisabledUser}
                                name='sexUser' className='form-control' value={sexUser}> 
                                <option key="" >Seleccione una opción</option>
                                <option key="Masculino" >Masculino</option>
                                <option key="Femenino" >Femenino</option>
                            </select>
                          </div>    
                          <div className="col-md-6">
                            <label htmlFor="birthdayUser"><b>F. Nacimiento:</b></label>
                            <input
                                  type='date'
                                  name='birthdayUser'
                                  value={birthdayUser}
                                  onChange={(event) => setBirthdayUser(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledUser}
                              /> 
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="roleUser"><b>Rol:</b></label>
                            <select onChange={(event) => setRoleUser(event.target.value)} disabled={isDisabledUserEdit}
                                name='roleUser' className='form-control' value={roleUser}> 
                                <option value="" >Seleccione una opción</option>
                                <option value="ADMIN_BRANCH" >Admin</option>
                                <option value="MONITOR_BRANCH" >Monitor</option>
                            </select>
                          </div>
                          <div className="col-md-6">    
                            <label htmlFor="isActiveUser"><b>Estado:</b></label>
                            <div className='form-check form-switch form-control'>
                              <input className='form-check-input'
                                type ='checkbox'
                                id ='isActiveUser'
                                name ='isActiveUser'
                                value ={isActiveUser}
                                onChange={(event) => setIsActiveUser(event.target.checked)}
                                disabled={isDisabledUserEdit}
                              />
                              <label className='form-check-label' htmlFor='isActiveUser'>
                                Activo
                              </label>
                            </div>
                          </div>
                          <div className='row'>&nbsp;</div>
                          <div className='row col-md-12'>
                            <div className="col-md-4">
                              <p style={({display: 'none'})}>{idUser}</p>
                            </div>
                            <div className="col-md-4">
                              <button type='button' onClick={addUser}  className='btn btn-primary form-control' disabled={isDisabledUserEdit} >
                                <AiOutlineUserAdd /> &nbsp; 

                                {isLoadingBranch ? (
                                    <span className='spinner-border spinner-border-sm' />
                                  ) : ('')}

                                  {editUser ? ' Actualizar' : ' Crear '}

                              </button>    
                            </div>
                            <div className="col-md-4">
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {isSuperAdmin ? ( 
                  <div className="tab-pane" id="icon-tabpanel-3" role="tabpanel" aria-labelledby="icon-tab-3">
                    <iframe src={`/admin/auth/branch-licenses?branch_id=${id}`} width="100%"  height="1200px"  ></iframe>
                  </div>):''}
                  
                  {isSuperAdmin ? (
                  <div className="tab-pane" id="icon-tabpanel-4" role="tabpanel" aria-labelledby="icon-tab-4">
                    <div className="row">
                    <GoogleMaps branch_id={`${id}`} updateBranch ={{updateBranch}} latitudeBranch= {{latitudeBranch}}  longitudeBranch= {{longitudeBranch}}  radiusBranch= {{radiusBranch}} />
                    </div>        
                  </div>):''}

                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormBranches
