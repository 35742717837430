import { Helmet } from 'react-helmet';

const Terminos = () => {
	return (
		<>
			<Helmet>
				<title>Términos y Condiciones</title>
				<meta property='og:title' content='Alerta Global - Constecoin' key='title' />
			</Helmet>
			<div style={{
				width: '100%',
				height: 'auto',
				background: '#B65353',
				alignContent: 'center',
				textAlign: 'center'
			}}>
				<h1 style={{
					textTransform: 'uppercase',
					color: 'white',
					animation: 'slideFromLeft 1s ease forwards'
				}}>Términos y condiciones de alerta global</h1>
			</div>
			<div style={{ marginTop: '15px', width: '100%', height: 'auto', background: 'white', display: 'flex', justifyContent: 'center' }}>
				<div style={{ width: '75%', textAlign: 'justify', animation: 'slideFromLeft 1s ease forwards' }}>
					<div className='subtitle-politicas'>
						<h4>Introducción</h4>
					</div>
					<p>	Bienvenido a Alerta Global (&quot;Alerta Global&quot;, &quot;nosotros&quot;, &quot;nos&quot; o &quot;nuestro&quot;).
						Estos Términos y Condiciones (&quot;Términos&quot;) rigen su uso de nuestra aplicación móvil
						Alerta Global (la &quot;Aplicación&quot;). Al utilizar la Aplicación, usted acepta estos Términos.
						Si no está de acuerdo con estos Términos, no debe utilizar la Aplicación.</p>

					<div className='subtitle-politicas'>
						<h4>1. Uso de la aplicación</h4>
					</div>
					<ol>
						<li style={{ listStyleType: "none" }}>1.1 Debe tener al menos 11 años para utilizar la Aplicación.</li>
						<li style={{ listStyleType: "none" }}>1.2 Usted acepta usar la Aplicación de una manera legal,
							ética y de acuerdo con estos Términos.</li>
						<li style={{ listStyleType: "none" }}>1.3 Usted acepta no utilizar la Aplicación para ningún
							propósito ilegal o dañino, incluyendo, pero no limitado a:</li>
						<ol>
							<li>Enviar spam o contenido malicioso.</li>
							<li>Acceder o utilizar sin autorización las cuentas de otros usuarios.</li>
							<li>Interferir con el funcionamiento de la Aplicación.</li>
							<li>Violar cualquier ley o regulación aplicable dentro de la jurisdicción ecuatoriana.</li>
						</ol>
						<li style={{ listStyleType: "none" }}>1.4	Usted acepta ser responsable de toda la información
							que proporciona  o transmite a la Aplicación.</li>
						<li style={{ listStyleType: "none" }}>1.5	Nos reservamos el derecho de suspender o cancelar su
							cuenta en cualquier momento, sin previo aviso, si usted viola estos Términos o si creemos que
							su uso de la Aplicación es dañino o ilegal.</li>
					</ol>

					<div className='subtitle-politicas'>
						<h4>2. Propiedad intelectual</h4>
					</div>
					<ol>
						<li style={{ listStyleType: "none" }}>2.1	Toda la propiedad intelectual de la Aplicación, incluyendo,
							pero no limitado a, el código, el diseño y el contenido, es propiedad nuestra o de nuestros proveedores. </li>
						<li style={{ listStyleType: "none" }}>2.2	Usted no tiene ningún derecho sobre la propiedad intelectual
							de la Aplicación, excepto el derecho limitado de usar la Aplicación de acuerdo con estos Términos.</li>
						<li style={{ listStyleType: "none" }}>2.3	Usted no puede copiar, modificar, distribuir,
							vender o crear obras derivadas de la propiedad intelectual de la Aplicación sin
							nuestro consentimiento previo por escrito.</li>
					</ol>

					<div className='subtitle-politicas'>
						<h4>3. Privacidad</h4>
					</div>
					<ol>
						<li style={{ listStyleType: "none" }}>3.1	Su privacidad es importante para nosotros.
							Consulte nuestro <a href='/auth/aviso-privacidad' rel='noreferrer'>Aviso de Privacidad</a> para obtener más información
							sobre cómo recolectamos, utilizamos y divulgamos su información personal</li>
					</ol>

					<div className='subtitle-politicas'>
						<h4>4. Indemnización</h4>
					</div>
					<ol>
						<li style={{ listStyleType: "none" }}>4.1	 Usted, como usuario, acepta responsabilizarse
							por cualquier daño, pérdida o costo que cause a nosotros o a terceros como resultado
							de su uso indebido de la aplicación o de su incumplimiento de estos Términos.
							Como liberarse de esta indeminización:</li>
						<ol>
							<li style={{ listStyle: "disc" }}>Utilice la aplicación de manera responsable y ética,
								siguiendo los Términos y Condiciones.</li>
							<li style={{ listStyle: "disc" }}>No realice actividades ilegales o dañinas.</li>
							<li style={{ listStyle: "disc" }}>Respete la propiedad intelectual y la privacidad de los demás.</li>
							<li style={{ listStyle: "disc" }}>Sea consciente de las leyes y regulaciones aplicables en Ecuador. </li>
						</ol>
						<li style={{ listStyleType: "none" }}>4.2	Es importante recordar que esta cláusula de indemnización
							busca proteger a Alerta Global de los daños que puedan ocasionarle como consecuencia
							de un uso indebido de la aplicación por parte de los usuarios.</li>
					</ol>

					<div className='subtitle-politicas'>
						<h4>5. Ley y jurisdicción</h4>
					</div>
					<ol>
						<li style={{ listStyleType: "none" }}>5.1	 Estos Términos y Condiciones se rigen e
							interpretan de acuerdo con las leyes de Ecuador.</li>
						<li style={{ listStyleType: "none" }}>5.2	 Cualquier disputa que surja o esté relacionada
							con estos Términos y Condiciones se someterá a la jurisdicción exclusiva de los tribunales
							de Ecuador.</li>
					</ol>

					<div className='subtitle-politicas'>
						<h4>6. Disposiciones generales</h4>
					</div>
					<ol>
						<li style={{ listStyleType: "none" }}>6.1	 Estos Términos constituyen el acuerdo completo
							entre usted y nosotros con respecto al uso de la Aplicación y
							reemplazan cualquier acuerdo o entendimiento previo, ya sea oral o escrito.</li>
						<li style={{ listStyleType: "none" }}>Si alguna disposición de estos Términos se considera
							inválida o inaplicable, dicha disposición se eliminará de estos Términos y las disposiciones
							restantes permanecerán en pleno vigor y efecto.</li>
						<li style={{ listStyleType: "none" }}>6.3	 Nos reservamos el derecho de modificar estos
							Términos en cualquier momento publicando los Términos revisados en la Aplicación.
							Usted está sujeto a los Términos revisados al continuar utilizando la Aplicación
							después de su publicación</li>
					</ol>

					<div className='subtitle-politicas'>
						<h4>7. Contacto</h4>
					</div>
					<ol>
						<li style={{ listStyleType: "none" }}>7.1	Si tiene alguna pregunta sobre estos Términos,
							por favor contáctenos en info_app@constecoin.com.</li>
					</ol>

					<div className='subtitle-politicas'>
						<h4>8. Eliminación de cuenta</h4>
					</div>
					<ol>
						<li style={{ listStyleType: "none" }}>8.1 Para proceder con la eliminación de tu cuenta,
							envía un correo con el asunto &quot;Eliminación de cuenta&quot; a info_app@constecoin.com.
							En el cuerpo del email, incluye los siguientes datos personales del usuario que se
							desea eliminar, por ejemplo:
							<ol>
								<li style={{ listStyle: "disc" }}>Cédula: 1723738475</li>
								<li style={{ listStyle: "disc" }}>Nombre: Juan Ángel</li>
								<li style={{ listStyle: "disc" }}>Apellido: Rodríguez Puchiti</li>
							</ol>
							En un plazo de 24 horas, el equipo de Alerta Global enviará un proceso de
							verificación al dispositivo asociado con la cuenta para asegurar una correcta
							eliminación de la misma.
						</li>
					</ol>
				</div>
			</div>
			<div style={{ width: '100%', textAlign: 'center', marginTop: '15px', marginBottom: '15px', fontSize: '25px' }}>Gracias por usar Alerta Global.</div>
		</>
	);
}

export default Terminos;
