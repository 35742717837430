import { Spinner, Message } from '..'
import useLicensesHook from '../../api/licenses'
import React, { useState, useEffect } from "react"
import { AiOutlineFileDone, AiOutlineRadarChart  } from "react-icons/ai"
import { useLocation  } from 'react-router-dom'
import useBranchLicensesHook from '../../api/branch-licenses'
import { FaPenAlt, FaTrash, FaCheckCircle, FaTimesCircle, FaBroom } from 'react-icons/fa'


const FormBranchLicenses = ({
  id,
  branchLicense,
  edit,
  formCleanHandler,
  isLoading,
  isError,
  error,
}) => {

  const { getLicenses } = useLicensesHook({ })
  const { updateBranchLicense } = useBranchLicensesHook({page:1, q:'' })
  const { mutateAsync: mutateAsyncUpdateBranchLicense } = updateBranchLicense
  const { data } = getLicenses

  // Inicio - BranchLicense y typeNetwork
  const [license, setLicense] = useState('')
  const [validSince, setValidSince] = useState('')
  const [validUntil, setValidUntil] = useState('')
  const [cost, setCost] = useState(0)
  const [maxNumberMembersXBranches, setMaxNumberMembersXBranches] = useState(0)
  const [withNetworks, setWithNetworks] = useState(false)
  const [active, setActive] = useState(false)
  const [editTypeNetwork, setEditTypeNetwork] = useState(false)
  const [typeNetworkName, setTypeNetworkName] = useState('')
  const [maxNumberUsersXNetwork, setMaxNumberUserXNetwork] = useState(0)
  const [activeTypeNetwork, setActiveTypeNetwork] = useState(false)
  var [typeNetworks, setTypeNetworks] = useState([])
  const [errorTypeNetwork, setErrorTypeNetwork] = useState('') 
  const [errorTypeNetworkGeneral, setErrorTypeNetworkGeneral] = useState('') 

  
  var typeNetworkList = [] 

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const branchId = searchParams.get('branch_id')

  const cleanBranchLicense = () => {
      setLicense('')
      setValidSince('')
      setValidUntil('')
      setCost(0)
      setMaxNumberMembersXBranches(0)
      setWithNetworks(false)

      if(document.getElementById("withNetworks") != null) {
        document.getElementById("withNetworks").checked = false
        document.getElementById("active").checked = false
      }

      if(document.getElementById("activeTypeNetwork") != null) {
        document.getElementById("activeTypeNetwork").checked = false
      }
        
      setActive(false)
      setEditTypeNetwork(false)
      setTypeNetworkName('')
      setMaxNumberUserXNetwork(0)
      setActiveTypeNetwork(false)
      setTypeNetworks([])
      setErrorTypeNetwork('')
      setErrorTypeNetworkGeneral('')
      typeNetworkList = []
  }

  const addBranchLicense = async ()  => {

     await mutateAsyncUpdateBranchLicense({ 
          _id: branchId,
          branchId: branchId, 
          licenseName: license,
          validSince: validSince,
          validUntil: validUntil, 
          cost: cost,
          withNetworks: withNetworks,
          maxNumberMembersXBranches: maxNumberMembersXBranches,
          active: active,
          typeNetworkList: typeNetworks
        })
      // Seteamos las variables  
      cleanBranchLicense()
      document.getElementById("close-branch-license-modal").click()
  }

  const editBranchLicense = async () => {

    // Validaciones
    // 1. Si tiene redes debe ingresar al menos un tipo de red
    if(withNetworks) {
      if(typeNetworks.length == 0) {
        setErrorTypeNetwork('Debe ingresar al menos un Tipo de Red')
        return false
      }
    }    

    await mutateAsyncUpdateBranchLicense({  
      _id: id,
      licenseName: license,
      validSince: validSince,
      validUntil: validUntil, 
      cost: cost,
      maxNumberMembersXBranches: maxNumberMembersXBranches,
      withNetworks: withNetworks,
      active: active,
      typeNetworkList: typeNetworks
    })
    
    setLicense('')
    setValidSince('')
    setValidUntil('') 
    setCost(0)
    setMaxNumberMembersXBranches(0)
    setWithNetworks(false)
    setActive(false)
  }

  useEffect(() => {
    
    if(branchLicense != null) {
      
      setCost(branchLicense.cost)
      setWithNetworks(branchLicense.withNetworks)   
      setValidSince(branchLicense.validSince != undefined ? branchLicense.validSince.substring(0, 10):'')
      setValidUntil(branchLicense.validUntil != undefined ? branchLicense.validUntil.substring(0, 10):'') 
      setMaxNumberMembersXBranches(branchLicense.maxNumberMembersXBranches)
      if(branchLicense.withNetworks) {
        document.getElementById("withNetworks").checked = true; 
      }    
      setWithNetworks(branchLicense.withNetworks)
      if(branchLicense.active) {
        document.getElementById("active").checked = true; 
      }    
      setActive(branchLicense.active)
      var typeNetworkListTemporal = []
      if(branchLicense.typeNetworks!= undefined) {
        for (let i = 0; i < branchLicense.typeNetworks.length; i++) {
          typeNetworkListTemporal.push({
            _id:branchLicense.typeNetworks[i]._id,
            name:branchLicense.typeNetworks[i].name,
            maxNumberUsersXNetwork: branchLicense.typeNetworks[i].maxNumberUsersXNetwork,
            active:branchLicense.typeNetworks[i].active,
          })
        }
      }    
      setTypeNetworks(typeNetworkListTemporal)
      setLicense(branchLicense.license.name)
      document.getElementById('license').value = branchLicense.license.name

    } else {
      cleanBranchLicense()
    }    
  }, [branchLicense])

  // Agregar el tipo de red
  const addTypeNetworkBranchLicense = async () => {
   
    // Validacion de que no repitan el tipo de red
    
    var typeNetworkFound = typeNetworks.find((e) => e.name == typeNetworkName)

    console.log(typeNetworkFound)
    if(typeNetworkFound!= undefined) {
      setErrorTypeNetworkGeneral('Nombre de Tipo de Red ya existe. Ingrese otro')
      return false
    } else {  
      typeNetworkList = typeNetworks
      typeNetworkList.push({
        name:typeNetworkName,
        maxNumberUsersXNetwork: maxNumberUsersXNetwork,
        active: activeTypeNetwork
      })
      setTypeNetworks(typeNetworkList)
      await mutateAsyncUpdateBranchLicense({  
        _id: id,
        licenseName: '',
        validSince: '',
        validUntil: '', 
        cost: '',
        maxNumberMembersXBranches: '',
        withNetworks: true,
        active: '',
        typeNetworkList: typeNetworkList
      })
    }
    cleanTypeNetworkBranchLicense()
  }

  
  // Metodo mostrar valores para  editar un tipo de red
  const showEditTypeNetworkFunction = async (typeNetwork) => {

    setEditTypeNetwork(true)
    setTypeNetworkName(typeNetwork.name)
    setMaxNumberUserXNetwork(typeNetwork.maxNumberUsersXNetwork)
    setActiveTypeNetwork(typeNetwork.active)
    if(typeNetwork.active)
      document.getElementById("activeTypeNetwork").checked = true
    else
      document.getElementById("activeTypeNetwork").checked = false

  }  

  // Metodo para  editar un tipo de red
  const editTypeNetworkFunction = async () => {
  
      setEditTypeNetwork(true)
      setTypeNetworkName(typeNetworkName)
      setMaxNumberUserXNetwork(maxNumberUsersXNetwork)
      setActiveTypeNetwork(activeTypeNetwork)

      typeNetworks.map(function(typeNetwork) {
        if(typeNetwork.name == typeNetworkName) {
          typeNetwork.maxNumberUsersXNetwork = maxNumberUsersXNetwork
          typeNetwork.active = activeTypeNetwork
        }
      })

      setTypeNetworks(typeNetworks)
      await mutateAsyncUpdateBranchLicense({  
        _id: id,
        licenseName: '',
        validSince: '',
        validUntil: '', 
        cost: '',
        maxNumberMembersXBranches: '',
        withNetworks: true,
        active: '',
        typeNetworkList: typeNetworks
      })

      cleanTypeNetworkBranchLicense()
      document.getElementById("close-type-network").click() 
  }  

  // Metodo para eliminar un tipo de red
  const deleteTypeNetwork = async (typeNetworkName) => {

    const listTypeNetworks = typeNetworks.filter(typeNetwork =>typeNetwork.name !== typeNetworkName)
   
    setTypeNetworks(listTypeNetworks)

    await mutateAsyncUpdateBranchLicense({  
      _id: id,
      licenseName: '',
      validSince: '',
      validUntil: '', 
      cost: '',
      maxNumberMembersXBranches: '',
      withNetworks: true,
      active: '',
      typeNetworkList: listTypeNetworks
    })
  }



  // Limpiar el tipo de red
  const cleanTypeNetworkBranchLicense = () => {
    setEditTypeNetwork(false)
    setTypeNetworkName('')
    setMaxNumberUserXNetwork(0)
    setActiveTypeNetwork(false)
    if(document.getElementById("activeTypeNetwork") != null) {
      document.getElementById("activeTypeNetwork").checked = false
    }
    setErrorTypeNetwork('')
    setErrorTypeNetworkGeneral('')
}

// Fin - BranchLicense y typeNetwork

  return (
    <div
      className='modal fade'
      id='branchLicenseModal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='branchLicenseModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-pequeno'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='branchLicenseModalLabel'>
              {edit ? 'Editar Licencia por Sucursal' : 'Agregar Licencia a la Sucursal'}
            </h3>
            <button
              type='button'
              id='close-branch-license-modal'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={formCleanHandler}  
            ></button>
          </div>
          <div className='modal-body'>
          {isError && <Message variant='danger'>{error}</Message>}

            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <form >

              <div className='row'>
                  <div className='col-md-6'>
                    <label htmlFor="license"><b>Licencia:</b></label>
                    <select onChange={(event) => setLicense( event.target.value)} 
                          id='license' name='license' className='form-control' value={license}> 
                          <option key="" >Seleccione una opción</option>
                          {data && data.data.map((license) => (
                            <option key={license._id} value={license.name} >{license.name}</option>
                          ))}
                    </select>
                  </div>  
                  <div className='col-md-6'>
                    <label htmlFor="validSince"><b>Vigencia Desde:</b></label>
                    <input
                      type='date'
                      name='validSince'
                      value={validSince}
                      onChange={(event) => setValidSince(event.target.value)}
                      className='form-control'                
                    /> 
                  </div>
                  <div className='col-md-6'>
                    <label htmlFor="validSince"><b>Vigencia Hasta:</b></label>
                      <input
                        type='date'
                        name='validUntil'
                        value={validUntil}
                        onChange={(event) => setValidUntil(event.target.value)}
                        className='form-control'                
                      /> 
                    </div>
                  <div className='col-md-6'>
                      <label htmlFor="cost"><b>Costo:</b></label>
                      <input
                        type='number'
                        name='cost'
                        value={cost}
                        onChange={(event) => setCost(event.target.value)}
                        className='form-control'                
                      /> 
                  </div>
                  <div className='col-md-6'>
                    <label htmlFor="maxNumberMembersXBranches"><b>Nª Máximo Miembros Por Sucursal:</b></label>
                    <input
                        type='number'
                        name='maxNumberMembersXBranches'
                        value={maxNumberMembersXBranches}
                        onChange={(event) => setMaxNumberMembersXBranches(event.target.value)}
                        className='form-control'                
                      />
                  </div>
                  <div className="col-md-6">    
                    <label htmlFor="withNetworks"><b>Con Redes:</b></label>
                    <div className='form-check form-switch form-control'>
                      <input className='form-check-input'
                        type ='checkbox'
                        id ='withNetworks'
                        name ='withNetworks'
                        value ={withNetworks}
                        onChange={(event) => setWithNetworks(event.target.checked)}
                        
                      />
                    <label className='form-check-label' htmlFor='withNetworks'>
                      Si
                    </label>
                  </div>
                </div>
                <div className="col-md-6">    
                  <label htmlFor="active"><b>Estado:</b></label>
                  <div className='form-check form-switch form-control'>
                    <input className='form-check-input'
                      type ='checkbox'
                      id ='active'
                      name ='active'
                      value ={active}
                      onChange={(event) => setActive(event.target.checked)}  
                      />
                  <label className='form-check-label' htmlFor='active'>
                    Activo
                  </label>
                </div>
                  
                <div className="col-md-6">&nbsp;</div>
                  
                </div>    
              </div>
            {withNetworks === false ? '' : 
            (
              <div>
            <center>
              <a className="btn btn-success"  href="#open-modal-type-network" > <AiOutlineRadarChart  />  &nbsp;Agregar un Tipo de Red</a>
            </center>        
            
            
            <center>{ errorTypeNetwork ? ( <span className='text-danger'>{errorTypeNetwork}</span> ):''} </center>
            <br></br>
            <table className='table * table-sm table-border'>
              <thead className='border-0'>
                <tr className='text-center'>
                  <th>Tipo de Red</th>
                  <th># de Usuarios por Red</th>
                  <th>Activo</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                       
              { typeNetworks && typeNetworks.map(( listValue, index ) => {
                return (
                <tr key={index} className='text-center'>
                  <td>{listValue.name}</td>
                  <td>{listValue.maxNumberUsersXNetwork}</td>
                  <td>
                    {listValue.active ? (
                      <FaCheckCircle className='text-success' />
                    ) : (
                      <FaTimesCircle className='text-danger' />
                    )}
                  </td>
                  <td>
                    <div className='btn-group'>
                      <a className="btn btn-primary btn-sm rounded-pill"  href="#open-modal-type-network" onClick={() => showEditTypeNetworkFunction(listValue)} > <FaPenAlt /></a>
                        &nbsp;
                      <button className='btn btn-danger btn-sm ms-1 rounded-pill'  onClick={() => deleteTypeNetwork(listValue.name)}  >
                        <span><FaTrash /></span> 
                      </button>
                    </div>
                  </td>
                </tr> )
                      })}          
                </tbody>     
              </table>

            <div id="open-modal-type-network" className="modal-window-subinternal" style={{marginTop:'200px'}}>
                <div>
                  <b><h5>
                  {editTypeNetwork ? 'Editar Tipo de Red' : 'Agregar Tipo de Red'}
                  </h5></b>
                  <a href="#" id="close-type-network" title="Close" className="modal-close" onClick={ () => cleanTypeNetworkBranchLicense()} >&#x2715;</a>
                  <hr></hr>                        
                  <div className='row'>
                    <div className="col-md-12">
                    </div>
                  </div>
                  <center>{ errorTypeNetworkGeneral ? ( <span className='text-danger'>{errorTypeNetworkGeneral}</span> ):''} </center>
                  &nbsp;
                <div className='row'>
                  
                  <div className="col-md-6">
                    <label htmlFor="typeNetworkName"><b>Tipo de Red:</b></label>
                    <input
                      type='text'
                      name='typeNetworkName'
                      value={typeNetworkName}
                      onChange={(event) => setTypeNetworkName(event.target.value)}
                      className='form-control'
                      disabled={editTypeNetwork ? true : false}
                    /> 
                  </div>
                  <div className='col-md-6'>
                      <label htmlFor="maxNumberUsersXNetwork"><b># Máximo Usuarios Por Red:</b></label>
                      <input
                        type='number'
                        name='maxNumberUsersXNetwork'
                        value={maxNumberUsersXNetwork}
                        onChange={(event) => setMaxNumberUserXNetwork(event.target.value)}
                        className='form-control'                
                      /> 
                  </div>
                  <div className='col-md-6'>
                    <label htmlFor="activeTypeNetwork"><b>Estado:</b></label>
                    <div className='form-check form-switch form-control'>
                      <input className='form-check-input'
                        type ='checkbox'
                        id ='activeTypeNetwork'
                        name ='activeTypeNetwork'
                        value ={activeTypeNetwork}
                        onChange={(event) => setActiveTypeNetwork(event.target.checked)}  
                        />
                      <label className='form-check-label' htmlFor='activeTypeNetwork'>
                        Activo
                      </label>
                    </div>
                  </div>
                  <div className='row'>&nbsp;</div>
                  <div className='row'>
                    <div className="col-md-6">
                      <button type='button' className='btn btn-primary form-control' onClick={ () => editTypeNetwork ? editTypeNetworkFunction(): addTypeNetworkBranchLicense()}  >
                        <AiOutlineRadarChart  /> &nbsp; {editTypeNetwork ? 'Actualizar' : 'Crear '}  
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button type='button' className='btn btn-primary form-control' onClick={ () => cleanTypeNetworkBranchLicense()}  >
                        <FaBroom /> &nbsp; Limpiar
                      </button> 
                    </div>
                  </div>

                </div>
                          
              </div>
            </div>
            </div>
            )
            }    
              <div className='row'>&nbsp;</div>
              <div className='row'>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <button type='button' onClick={edit ? editBranchLicense:addBranchLicense}  className='btn btn-primary form-control'  >
                      <AiOutlineFileDone /> &nbsp; {edit ? 'Actualizar' : 'Agregar '}
                  </button>    
                </div>
                <div className="col-md-4"></div>
              </div>

              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormBranchLicenses
