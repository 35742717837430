import { Spinner, Message } from '..'
import { AiFillProfile, AiTwotoneSafetyCertificate  } from "react-icons/ai"
import {
  inputCheckBox,
  inputText,
  inputNumber,
  staticInputSelect  
} from '../../utils/dynamicForm'

const FormLicenses = ({
  edit,
  formCleanHandler,
  isLoading,
  register,
  isError,
  errors,
  watch,
  isLoadingUpdate,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  error,
}) => {

  const iframeOnLoad = (origin) => {
    
    if(origin == 'service'){
       var iframeService = document.querySelector("#iframe-service");
       
       if(iframeService!=undefined){
        if(iframeService.contentDocument.childElementCount >0){
          var footerService = iframeService.contentDocument.querySelector("footer");
          if(footerService!=undefined){
            footerService.remove();
          }
          var navService = iframeService.contentDocument.querySelector("nav");
          if(navService!=undefined){
            navService.remove();
          } 
        }    
        
      }   
    }    
      
  }

  return (
    <div
      className='modal fade'
      id='licenseModal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='licenseModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-grande'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='licenseModalLabel'>
              {edit ? 'Editar Licencia' : 'Crear Licencia'}
            </h3>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={formCleanHandler}
            ></button>
          </div>
          <div className='modal-body'>

           

              {isLoading ? (
              <Spinner /> ) : isError ? ( <Message variant='danger'>{error}</Message> ) : (
              
              <form onSubmit={handleSubmit(submitHandler)}>

            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <a className="nav-link active" id="icon-tab-0" data-bs-toggle="tab" href="#icon-tabpanel-0" role="tab" aria-controls="icon-tabpanel-0" aria-selected="true"><AiFillProfile /> Datos Generales</a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link" id="icon-tab-1" data-bs-toggle="tab" href="#icon-tabpanel-1" role="tab" aria-controls="icon-tabpanel-1" aria-selected="false"><AiTwotoneSafetyCertificate  /> Servicios </a>
              </li>
            </ul>

            <div className="tab-content pt-3" id="tab-content">
              <div className="tab-pane active" id="icon-tabpanel-0" role="tabpanel" aria-labelledby="icon-tab-0">
              <div className='row'>
                  
                  <div className='col-md-6 col-12'>
                    {inputText({
                      register,
                      errors,
                      label: 'Nombre:',
                      name: 'name',
                      placeholder: 'Nombre',
                    })}
                  </div>
                  <div className='col-md-6 col-12'>
                    {inputText({
                      register,
                      errors,
                      label: 'Nombre Comercial:',
                      name: 'comercialName',
                      placeholder: 'Nombre Comercial',
                    })}
                  </div>

                  <div className='col-md-6 col-12'>
                    {inputNumber({
                      register,
                      errors,
                      label: 'Costo:',
                      name: 'cost',
                      placeholder: 'Costo',
                    })}
                  </div>

                  <div className='col-md-6 col-12'>
                    {staticInputSelect({
                      register,
                      errors,
                      label: 'Tipo:',
                      name: 'type',
                      placeholder: 'Tipo',
                      data: [
                        { name: 'Familiar' },
                        { name: 'Empresarial' },
                        { name: 'Estatal' },
                      ],
                    })}
                  </div>
                  <div className='col-md-6 col-12'>
                    {inputNumber({
                      register,
                      errors,
                      label: 'Tiempo de Validez:',
                      name: 'timeValidity',
                      placeholder: 'Tiempo de Validez',
                    })}
                    &nbsp;
                    {staticInputSelect({
                      register,
                      errors,
                      label: 'Unidad de Medida:',
                      name: 'timeValidityUnit',
                      placeholder: 'Unidad de Medida',
                      data: [
                        { name: 'dias' },
                        { name: 'meses' },
                        { name: 'anos' },
                      ],
                    })}
                  </div>
                  <div className='col-md-6 col-12'>
                    {inputCheckBox({
                      register,
                      errors,
                      watch,
                      name: 'active',
                      label: 'Activo',
                      isRequired: false,
                      placeholder: 'Activo',
                    })}
                  </div>

                </div>    

                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-secondary '
                    data-bs-dismiss='modal'
                    onClick={formCleanHandler}
                  >
                    Cerrar
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary '
                    disabled={isLoadingPost || isLoadingUpdate}
                  >
                    {isLoadingPost || isLoadingUpdate ? (
                      <span className='spinner-border spinner-border-sm' />
                    ) : (
                      'Enviar'
                    )}
                  </button>
                </div>
              </div> 
              <div className="tab-pane" id="icon-tabpanel-1" role="tabpanel" aria-labelledby="icon-tab-1">
                <iframe id="iframe-service" name="iframe-service" src='/admin/auth/services' width="100%"  height="1000px" onLoad={iframeOnLoad('service')} ></iframe>
              </div>
            </div>

               


              </form>
            )}

          </div>
        </div>
      </div>
    </div>
  )
}

export default FormLicenses
