import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import useContactFormsHook from '../../../api/new-contact-forms'
import FormContactForms from '../../../components/contact-forms/FormContactForms'



const NewContactForms = () => {


  const {  postContactForm } =
    useContactFormsHook()

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      auth: false,
    },
  })


  
  const {
    isLoading,
    isError,
    error,
    isSuccess,
    mutateAsync,
  } = postContactForm

  useEffect(() => {
    isSuccess && reset()
  }, [isSuccess])

  const formCleanHandler = () => {
    reset()
  }

  useEffect(() => {
    if (isSuccess ) formCleanHandler()
  }, [isSuccess])


  const submitHandler = (data) => {
     mutateAsync(data)
  }

  

  return (
    <>
      <Helmet>
        <title>Formulario de Contacto</title>
        <meta property='og:title' content='Formularios de Contacto' key='title' />
      </Helmet>
      
   

      <FormContactForms
        formCleanHandler={formCleanHandler}
        errors={errors}
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
        error={error}
        register={register}
        handleSubmit={handleSubmit}
        submitHandler={submitHandler}
        watch={watch}
      />



     
    </>
  )
}

export default NewContactForms
