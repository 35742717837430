import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { confirmAlert } from 'react-confirm-alert'
import { useForm } from 'react-hook-form'
import useContactFormsHook from '../../../api/contact-forms'
import FormContactForms from '../../../components/contact-forms/FormContactForms'
import ViewContactForms from '../../../components/contact-forms/ViewContactForms'

import {
  Spinner,
  Pagination,
  Message,
  Confirm,
} from '../../../components'

const ContactForms = () => {
  const [page, setPage] = useState(1)
  const [id, setId] = useState(null)
  const [edit, setEdit] = useState(false)
  const [q, setQ] = useState('')

  const { getContactForms , postContactForm, updateContactForm, deleteContactForm } =
    useContactFormsHook({
      page,
      q,
    })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      auth: true,
    },
  })

  const { data, isLoading, isError, error, refetch } = getContactForms

  const {
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
    isSuccess: isSuccessUpdate,
    mutateAsync: mutateAsyncUpdate,
  } = updateContactForm

  const {
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    error: errorDelete,
    isSuccess: isSuccessDelete,
    mutateAsync: mutateAsyncDelete,
  } = deleteContactForm

  const {
    isLoading: isLoadingPost,
    isError: isErrorPost,
    error: errorPost,
    isSuccess: isSuccessPost,
    mutateAsync: mutateAsyncPost,
  } = postContactForm

  const formCleanHandler = () => {
    setEdit(false)
    reset()
  }

  useEffect(() => {
    if (isSuccessPost || isSuccessUpdate) formCleanHandler()
  }, [isSuccessPost, isSuccessUpdate])

  useEffect(() => {
    refetch()
  }, [page])

  useEffect(() => {
    if (!q) refetch()
  }, [q])

  const searchHandler = (e) => {
    e.preventDefault()
    refetch()
    setPage(1)
  }

  const deleteHandler = (id) => {
    confirmAlert(Confirm(() => mutateAsyncDelete(id)))
  }

  const submitHandler = (data) => {
    edit
      ? mutateAsyncUpdate({
          _id: id,
          type_identification: data.type_identification,
          identification: data.identification,
          name: data.name,
          lastname: data.lastname,
          email: data.email,
          state: 'Ingresado',
          detail: data.detail,
        })
      : mutateAsyncPost(data)
  }

  const editHandler = (contactform) => {
    setId(contactform._id)
    setEdit(true)
    setValue('type_identification', contactform.type_identification)
    setValue('identification', contactform.identification)
    setValue('name', contactform.name)
    setValue('lastname', contactform.lastname)
    setValue('email', contactform.email)
    setValue('state', contactform.state)
    setValue('detail', contactform.detail)
  }

  return (
    <>
      <Helmet>
        <title>Formularios de Contacto</title>
        <meta property='og:title' content='Formularios de Contacto' key='title' />
      </Helmet>
      {isSuccessDelete && (
        <Message variant='success'>
          El Formulario de Contacto se ha eliminado correctamente.
        </Message>
      )}
      {isErrorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {isSuccessUpdate && (
        <Message variant='success'>
          El Formulario de Contacto se ha actualizado correctamente.
        </Message>
      )}
      {isErrorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
      {isSuccessPost && (
        <Message variant='success'>
          El Formulario de Contacto se ha creado correctamente.
        </Message>
      )}
      {isErrorPost && <Message variant='danger'>{errorPost}</Message>}

      <FormContactForms
        edit={edit}
        formCleanHandler={formCleanHandler}
        isLoading={isLoading}
        isError={isError}
        errors={errors}
        isLoadingUpdate={isLoadingUpdate}
        isLoadingPost={isLoadingPost}
        register={register}
        handleSubmit={handleSubmit}
        submitHandler={submitHandler}
        watch={watch}
        error={error}
      />

      <div className='ms-auto text-end'>
        <Pagination data={data} setPage={setPage} />
      </div>

      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <ViewContactForms
          data={data}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
          isLoadingDelete={isLoadingDelete}
          setQ={setQ}
          q={q}
          searchHandler={searchHandler}
        />
      )}
    </>
  )
}

export default ContactForms
