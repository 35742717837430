import { FaPenAlt,  FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { Search } from '..'

const ViewIndividuals = ({
  data,
  editHandler,
  setQ,
  q,
  searchHandler,
}) => {
  return (
    <div className='table-responsive bg-light p-3 mt-2'>
      <div className='d-flex align-items-center flex-column mb-2'>
        <h3 className='fw-light text-muted'>
          Listadp de Usuarios Individuales <sup className='fs-6'> [{data && data.total}] </sup>
        </h3>
        <button
          className='btn btn-outline-primary btn-sm shadow my-2'
          data-bs-toggle='modal'
          data-bs-target='#individualModal'
        >
          Agregar un Usuario Individual
        </button>
        <div className='col-auto'>
          <Search
            placeholder='Buscar por nombre'
            setQ={setQ}
            q={q}
            searchHandler={searchHandler}
          />
        </div>
      </div>
      <table className='table table-sm table-border text-center'>
        <thead className='border-0'>
          <tr>
            <th>Nombre</th>
            <th>Identificación</th>
            <th>Descripción</th>
            <th>Es Público?</th>
            <th>Activo</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data.data.map((individual) => (
              <tr key={individual._id}>
                <td>{individual.user.name}</td>
                <td>{individual.user.email}</td>
                <td>{individual.subNetworks.length}</td>
                <td>
                  {individual.active ? (
                    <FaCheckCircle className='text-success' />
                  ) : (
                    <FaTimesCircle className='text-danger' />
                  )}
                </td>
                <td>
                  <div className='btn-group'>
                    <button
                      className='btn btn-primary btn-sm rounded-pill'
                      onClick={() => editHandler(individual)}
                      data-bs-toggle='modal'
                      data-bs-target='#individualModal'
                    >
                      <FaPenAlt />
                    </button>

                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default ViewIndividuals
