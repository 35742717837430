import dynamicAPI from './dynamicAPI'
import {useMutation, useQueryClient } from 'react-query'

const url = '/api/auth/contact-forms'

const queryKey = 'contact-forms' 

export default function useNewContactFormsHook() {

  const queryClient = useQueryClient()

  const postContactForm = useMutation(
    async (obj) => await dynamicAPI('post', url, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  return {
    postContactForm,
  }
}
