import { FaPenAlt, FaTrash, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

const ViewNetworks = ({
  data,
  editHandler,
  deleteHandler,
  isLoadingDelete,
}) => {



  
  return (
    <div id="containerNetwork" name="containerNetwork" className='table-responsive bg-light p-3 mt-2'>
      <div className='d-flex align-items-center flex-column mb-2'>
      </div>
      <table className='table table-sm table-border text-center'>
        <thead className='border-0'>
          <tr>
            <th>Nombre</th>
            <th>Usuarios</th>
            <th>Activo</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data.data.map((subNetwork) => (
              <tr key={subNetwork._id}>
                <td>{subNetwork.name}</td>
                <td>{subNetwork.members== undefined ? 0: subNetwork.members.length}</td>
                <td>
                  {subNetwork.active ? (
                    <FaCheckCircle className='text-success' />
                  ) : (
                    <FaTimesCircle className='text-danger' />
                  )}
                </td>

                <td>
                  <div className='btn-group'>
                    <button
                      id={`${subNetwork._id}_edit_button`}
                      className='btn btn-primary btn-sm rounded-pill'
                      onClick={() => editHandler(subNetwork)}
                      data-bs-toggle='modal'
                      data-bs-target='#networkModal'
                    >
                      <FaPenAlt />
                    </button>

                    <button
                      className='btn btn-danger btn-sm ms-1 rounded-pill'
                      onClick={() => { if (window.confirm('Desea eliminar la Red del Miembro? Tenga cuidado se eliminarán todos los usuarios')) deleteHandler(data.network_id+'-'+subNetwork._id+'-'+subNetwork.subNetwork._id) } }
                      disabled={isLoadingDelete}
                    >
                      {isLoadingDelete ? (
                        <span className='spinner-border spinner-border-sm' />
                      ) : (
                        <span>
                          <FaTrash />
                        </span>
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default ViewNetworks
