import { Spinner, Message } from '..'
import { AiFillProfile, AiOutlineUsergroupAdd,AiOutlineDeploymentUnit, AiOutlineUserAdd, AiOutlineEdit  } from "react-icons/ai"
import { FaPenAlt, FaTrash, FaSearch, FaBroom, FaCheckCircle, FaTimesCircle  } from 'react-icons/fa'
import {
  inputCheckBox,
  inputText,
  staticInputSelect,
} from '../../utils/dynamicForm'
import useOrganizationsHook from '../../api/organizations'
import useUsersHook from '../../api/users'
import useUploadHook from '../../api/upload'
import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import "yup-phone"
import PhoneInput from 'react-phone-number-input'


var FormOrganizations = ({
  id,
  edit,
  users,
  image,
  formCleanHandler,
  isLoading,
  register,
  isError,
  errors,
  watch,
  isLoadingUpdate,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  error,
  isAdmin,
  organization,
  userId,
}) => {

  const { updateOrganization } = useOrganizationsHook({ page:1, q:'' })
  const { mutateAsync: mutateAsyncOrganization, error: errorValidations, isLoading: isLoadingUpdateUser } = updateOrganization
  const { postUserByInput } = useUsersHook({ limit: 1, page: 1 })
  const { mutateAsync: mutateAsyncPostSearchUser } = postUserByInput
  const { postUpload } = useUploadHook()
  var [imageUpload, setImageUpload] = useState('')


  const {
    //data: dataUpload,
    isLoading: isLoadingUpload,
    //isError: isErrorUpload,
    //error: errorUpload,
    mutateAsync: mutateAsyncUpload,
    //isSuccess: isSuccessUpload,
  } = postUpload


  const handleFileImageOrganizationChange = (e) => {
    if (e.target.files) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = async () => {
        const formData = new FormData()
        formData.append('file', e.target.files[0])
        const imageUpload = await mutateAsyncUpload({type: 'image-organization', formData, id: id })
        setImageUpload(imageUpload.image)
      }
    }
  }

  useEffect(() => {
    setImageUpload(image)
  }, [image])

  // Inicio - User
  const [idUser, setIdUser] = useState('')
  const [typeIdentificationUser, setTypeIdentificationUser] = useState(undefined)
  const [identificationUser, setIdentificationUser] = useState('')
  const [nameUser, setNameUser] = useState('')
  const [lastNameUser, setLastNameUser] = useState('')
  const [emailUser, setEmailUser] = useState('')
  const [phoneNumberUser, setPhoneNumberUser] = useState()
  const [sexUser, setSexUser] = useState('')
  const [birthdayUser, setBirthdayUser] = useState('')
  const [editUser, setEditUser] = useState(false)
  const [inputSearchUser, setInputSearchUser] = useState('')
  const [isActiveUser, setIsActiveUser] = useState(true)
  const [roleUser, setRoleUser] = useState('')
  const [isDisabledUser, setIsDisabledUser] = useState(true)
  const [isDisabledUserEdit, setIsDisabledUserEdit] = useState(true)
  const [errorsListUser, setErrorsListUser] = useState('')


  const cleanUser = async () => {

    setInputSearchUser('')
    setIsDisabledUser(true)
    setIsDisabledUserEdit(true)
    setIdUser('')
    setTypeIdentificationUser('')
    setIdentificationUser('')
    setBirthdayUser('')
    setSexUser('')
    setNameUser('')
    setLastNameUser('')
    setEmailUser('')
    setPhoneNumberUser('')
    setIsActiveUser(false)
    setRoleUser('')
    setEditUser(false)
    setErrorsListUser('')
    document.getElementById('isActiveUser').removeAttribute('checked')
    
  }

  const addUser = async ()  => {


    let formatDateBirthday = ''
    // Validacion de la fecha por conversion del componente web
    if(birthdayUser!= '') {
      let dateArray= birthdayUser.split('-')
      formatDateBirthday =`${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
    }
  const branchUpdate = await mutateAsyncOrganization({_id: id, 
        name: '',
        typeIdentification: '',
        identification: '',
        description: '',
        isPublic:'',
        active: '',
        users: {
          _id: idUser,
          typeIdentification:typeIdentificationUser,
          identification:identificationUser,
          birthday: formatDateBirthday,     
          sex: sexUser,
          name: nameUser,
          lastname: lastNameUser,
          email: emailUser,
          phone: phoneNumberUser,  
          role: roleUser,
        },
      })

      users.splice(0)

    for (let i = 0; i < branchUpdate.users.length; i++) {
      users.push({
        _id: branchUpdate.users[i].user._id,
        typeIdentification: branchUpdate.users[i].user.typeIdentification,
        identification: branchUpdate.users[i].user.identification,
        birthday: branchUpdate.users[i].user.birthday,
        sex: branchUpdate.users[i].user.sex,
        name: branchUpdate.users[i].user.name,
        lastname: branchUpdate.users[i].user.lastname,
        email: branchUpdate.users[i].user.email,
        phone: branchUpdate.users[i].user.phone,
        active: branchUpdate.users[i].active, 
        role: branchUpdate.users[i].typeUser
      })
    }
    
      cleanUser()
      document.getElementById("close-user-organization").click()
  }

const editUserFunction = (idUser) => {
  setEditUser(true)
  setIsDisabledUser(true)
  setIsDisabledUserEdit(false)
  var userFound = users.find((e) => e._id == idUser)
  
  setIdUser(userFound._id)
  setTypeIdentificationUser(userFound.typeIdentification)
  setIdentificationUser(userFound.identification)
  setBirthdayUser(userFound.birthday != undefined ? userFound.birthday.substring(0, 10):'')
  setSexUser(userFound.sex)
  setNameUser(userFound.name)
  setLastNameUser(userFound.lastname)
  setEmailUser(userFound.email)
  setPhoneNumberUser(userFound.phone)
  setIsActiveUser(userFound.active)
  setRoleUser(userFound.role  == 'Admin'? 'ADMIN_ORGANIZATION':'MONITOR_ORGANIZATION')
  if(userFound.active)
    document.getElementById("isActiveUser").checked = true
  else
    document.getElementById("isActiveUser").checked = false
  
}

const schemaSearchUser = Yup.object().shape({
  search: Yup.string().required("* Ingrese un correo electrónico, un número telefónico o una identificación"),
})

const deleteUserFunction = async (idUser) => {

  const branchUpdate =  await mutateAsyncOrganization({_id: id,
    users: {
      _id: idUser,
    },
    deleteUserId: idUser,
  })

  users.splice(0)

  for (let i = 0; i < branchUpdate.users.length; i++) {
    users.push({
      _id: branchUpdate.users[i].user._id,
      typeIdentification: branchUpdate.users[i].user.typeIdentification,
      identification: branchUpdate.users[i].user.identification,
      birthday: branchUpdate.users[i].user.birthday,
      sex: branchUpdate.users[i].user.sex,
      name: branchUpdate.users[i].user.name,
      lastname: branchUpdate.users[i].user.lastname,
      email: branchUpdate.users[i].user.email,
      phone: branchUpdate.users[i].user.phone,
      active: branchUpdate.users[i].active,
      role: branchUpdate.users[i].typeUser  
    })
  }

}

const searchUser = async () => {

  const retorno = await mutateAsyncPostSearchUser({input:inputSearchUser })
  var errorMessage = ''
  cleanUser()

  if(retorno._id == undefined ) {
    errorMessage = '* No se ha encontrado ningún usuario. Ingrese un nuevo usuario'
    setErrorsListUser(errorMessage)
    setEditUser(false)
    setIsDisabledUser(false)
    setIsDisabledUserEdit(false)
  } else {  
    setEditUser(false)
    setIsDisabledUser(true)
    setIsDisabledUserEdit(false)
  }
  
  setIdUser(retorno && retorno._id != undefined ? retorno._id: '')
  setTypeIdentificationUser(retorno && retorno.typeIdentification != undefined ?retorno.typeIdentification:'')
  setIdentificationUser(retorno && retorno.identification != undefined ? retorno.identification: '')
  setBirthdayUser(retorno && retorno.birthday!= undefined ? retorno.birthday.substring(0, 10):'')
  setSexUser(retorno && retorno.sex != undefined ? retorno.sex : '')
  setNameUser(retorno && retorno.name != undefined ? retorno.name: '')
  setLastNameUser(retorno && retorno.lastname!= undefined ? retorno.lastname: '')
  setEmailUser(retorno && retorno.email != undefined ? retorno.email: '')
  setPhoneNumberUser(retorno && retorno.phone != undefined ? retorno.phone: '')
  setIsActiveUser(retorno && retorno.active != undefined ? retorno.active :false)  
}

const formValidationSearchUser = async () => {
  let validacion = undefined
  try {
  
    validacion = await schemaSearchUser.validate({ search: inputSearchUser })
  }
  catch (errors) {
    setErrorsListUser(errors.message)     
  }  

  if(validacion!== ''){
    searchUser()
  }
}

// Fin - User

  return (
    <div
      className='modal fade'
      id='organizationModal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='organizationModalLabel'
      aria-hidden='true'
    >

    {errorValidations && <Message variant='danger'>{errorValidations}</Message>}
      <div className='modal-dialog modal-dialog-grande'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='organizationModalLabel'>
              {edit ? 'Editar Organización' : 'Crear Organización'}
            </h3>
            <button
              type='button'
              id='close-organization-modal'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={formCleanHandler}
            ></button>
          </div>
          <div className='modal-body'>

            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <a className="nav-link active" id="icon-tab-0" data-bs-toggle="tab" href="#icon-tabpanel-0" role="tab" aria-controls="icon-tabpanel-0" aria-selected="true"><AiFillProfile /> Datos Generales</a>
              </li>
              <li className="nav-item" role="presentation" style={{display: edit === true ? 'block' : 'none'}}>
                <a className="nav-link" id="icon-tab-1" data-bs-toggle="tab" href="#icon-tabpanel-1" role="tab" aria-controls="icon-tabpanel-1" aria-selected="false"><AiOutlineDeploymentUnit /> Sucursales </a>
              </li>
              <li className="nav-item" role="presentation" style={{display: edit === true ? 'block' : 'none'}}>
                <a className="nav-link" id="icon-tab-2" data-bs-toggle="tab" href="#icon-tabpanel-2" role="tab" aria-controls="icon-tabpanel-2" aria-selected="false"><AiOutlineUsergroupAdd /> Administradores </a>
              </li>
            </ul>
            <div className="tab-content pt-3" id="tab-content">
              <div className="tab-pane active" id="icon-tabpanel-0" role="tabpanel" aria-labelledby="icon-tab-0">
                {isLoading ? (
                <Spinner /> ) : isError ? ( <Message variant='danger'>{error}</Message> ) : (
                
                <form onSubmit={handleSubmit(submitHandler)}>
                  <div className='row'>
                    <div className={ edit?'col-md-6':'col-md-12' } >
                      <div className='row'>
                        <div className='col-md-6 col-12'>
                          {isAdmin?  staticInputSelect({
                                    register,
                                    errors,
                                    label: 'Tipo de identificación:',
                                    name: 'typeIdentification',
                                    placeholder: 'Tipo de identificación',
                                    isRequired: false,
                                    data: [
                                      { name: 'RUC' }
                                    ],
                            }): <div><label htmlFor='typeIdentification'><b>Tipo de identificación:</b></label><br></br><label id='typeIdentification' className='form-control' >{ organization.typeIdentification}</label></div> }
                        </div>
                        <div className='col-md-6 col-12'>
                          { isAdmin? inputText({
                            register,
                            errors,
                            label: 'Identificación:',
                            name: 'identification',
                            placeholder: 'Identificación',
                          }): <div><label htmlFor='identification'><b>Identificación:</b></label><br></br><label id='identification' className='form-control' >{ organization.identification}</label></div>}
                        </div>
                        <div className='col-md-6 col-12'>
                          {isAdmin? inputText({
                            register,
                            errors,
                            label: 'Nombres:',
                            name: 'name',
                            placeholder: 'Nombres',
                          }): <div><label htmlFor='name'><b>Nombres:</b></label><br></br><label id='name' className='form-control' >{ organization.name}</label></div>}
                        </div>
                        <div className='col-md-6 col-12'>
                          {isAdmin? inputText({
                            register,
                            errors,
                            label: 'Descripción:',
                            name: 'description',
                            placeholder: 'Descripción',
                          }): <div><label htmlFor='description'><b>Descripción:</b></label><br></br><label id='description' className='form-control' >{ organization.description}</label></div>}
                        </div>
                        <div className='col-md-6 col-12'>
                        <label htmlFor='isPublic'><b>Visibilidad:</b></label>
                          {isAdmin? inputCheckBox({
                            register,
                            errors, 
                            watch,
                            name: 'isPublic',
                            label: 'Es Público?',
                            isRequired: false,
                            placeholder: 'Es Público?',
                          }): organization.isPublic? ( <div className='form-control'><FaCheckCircle className='text-success' /></div> ): ( <div className='form-control'><FaTimesCircle className='text-danger' /></div> )}   
                        </div>
                        <div className='col-md-6 col-12'>
                          <label htmlFor='active'><b>Estado</b></label>
                          {isAdmin? inputCheckBox({
                            register,
                            errors,
                            watch,
                            name: 'active',
                            label: 'Activo',
                            isRequired: false,
                            placeholder: 'Activo',
                          }): organization.active? ( <div className='form-control'><FaCheckCircle className='text-success' /></div> ): ( <div className='form-control'><FaTimesCircle className='text-danger' /></div> )}
                        </div>
                      </div>
                      <br></br>
                      {isAdmin ?(
                      <div className='row'>
                              <div className="col-md-12 text-center">
                              <button
                                  type='submit'
                                  className='btn btn-primary '
                                  disabled={isLoadingPost || isLoadingUpdate}
                                >
                                <AiOutlineEdit/> &nbsp; {isLoadingPost || isLoadingUpdate ? (
                                    <span className='spinner-border spinner-border-sm' />
                                  ) : (
                                    edit ? 'Actualizar' : 'Crear '
                                  )}
                                </button>    
                              </div>
                      </div>):''}
                    </div>
                    {edit ? (
                    <div className='col-md-6'>
                      <div className='row'>
                            <div className='d-flex justify-content-center'>
                              <img
                                src={imageUpload}
                                width='150'
                                height='80'
                              />
                            </div>
                        <label htmlFor="file" className="sr-only">
                          <center>* Suba la imagen de la Organización. Tamaño Ancho:150px - Alto:80px</center>
                        </label>
                        <div className='row'>
                            <center>
                            &nbsp;&nbsp;
                            <label htmlFor="image" className="btn btn-primary btn-block btn-outlined">
                            {isLoadingUpload ? (
                            <span className='spinner-border spinner-border-sm' />
                            ) : (
                              ''
                            )}
                              Subir imagen
                              </label>
                            <input id="image" type="file" accept="image/*" onChange={handleFileImageOrganizationChange} className='form-control' style={{ display: 'none' }} />
                            </center>
                        </div>
                      </div>
                    </div> ) : '' } 
                               
                  </div>
                </form>
                )}
              </div>
              <div className="tab-pane" id="icon-tabpanel-1" role="tabpanel" aria-labelledby="icon-tab-1">
                <iframe src={`/admin/auth/branches?organization_id=${id}&user_id=${userId}`}  width="100%"  height="1200px" ></iframe>
              </div>
              <div className="tab-pane" id="icon-tabpanel-2" role="tabpanel" aria-labelledby="icon-tab-2">
                  <center><a className="btn btn-primary" href="#open-modal-user" onClick={() => cleanUser()}> <AiOutlineUserAdd /> &nbsp;Agregar un Usuario</a></center>
                    <br></br>

                    <table className='table * table-sm table-border'>
                      <thead className='border-0'>
                        <tr className='text-center'>
                          <th>Nombre del Usuario</th>
                          <th>Activo</th>
                          <th>Rol</th>
                          <th>Identificación</th>
                          <th>Número Celular</th>
                          <th>Email</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                      { users.map(( listValue, index ) => {
                        return (
                          <tr key={index} className='text-center'>
                            <td>{listValue.name}&nbsp;{listValue.lastname}</td>
                            <td>
                              {listValue.active ? (
                                <FaCheckCircle className='text-success' />
                              ) : (
                                <FaTimesCircle className='text-danger' />
                              )}
                            </td>
                            <td>{listValue.role}</td>
                            <td>{listValue.identification}</td>
                            <td>{listValue.phone}</td>
                            <td>{listValue.email}</td>
                            <td>
                              <div className='btn-group'>
                                <a className="btn btn-primary btn-sm rounded-pill"  href="#open-modal-user"  onClick={() => editUserFunction(listValue._id)}> <FaPenAlt /></a>
                                <button className='btn btn-danger btn-sm ms-1 rounded-pill'   onClick={() => { if (window.confirm('Desea eliminar el usuario de admistradores de la Organización ?')) deleteUserFunction(listValue._id) } }>
                                  <span><FaTrash /></span>
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}          
                      </tbody>     
                    </table>
                    <div id="open-modal-user" className="modal-window-external" >
                      <div>
                          <b><h5 >
                            {editUser ? 'Editar Usuario' : 'Agregar Usuario'}
                          </h5></b>
                          <a href="#" id="close-user-organization" title="Close" className="modal-close">&#x2715;</a>
                        <hr></hr>
                        <div className='row'>
                          <div className="col-md-12">
                          
                          {editUser == false ? (
                            <div className='row'>
                              <div className="row">
                                <div className="col-md-2">
                                  <label><b>Búsqueda:</b></label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                        type='text'
                                        name='inputSearchUser'
                                        placeholder='Usuarios por identificación, email o teléfono'
                                        value={inputSearchUser}
                                        style={({ width:'100%'})}
                                        onChange={(event) => setInputSearchUser(event.target.value)}      
                                    />
                                </div>
                                <div className="col-md-2">
                                  <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Buscar">
                                    <button type='button' className='btn btn-primary btn-sm rounded-pill'
                                      onClick={() => formValidationSearchUser()}>
                                      <FaSearch />
                                    </button>
                                  </span> 
                                  &nbsp;
                                  <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Limpiar">
                                    <button className='btn btn-primary btn-sm rounded-pill'
                                      onClick=
                                      {() => cleanUser()}>
                                      <FaBroom />
                                    </button>
                                  </span>
                                </div>
                              </div>  
                              &nbsp;
                              <div className='row text-center'>
                                { errorsListUser ? ( <span className='text-danger'>{errorsListUser}</span> ):''}
                              </div>
                              <br></br> 
                              <hr></hr>
                            </div>
                            
                          ):''}
                          
                          </div>
                        </div>
                          
                        <div className='row'>
                          <div className="col-md-6">
                            <label htmlFor="typeIdentificationUser"><b>Tipo Identificación:</b></label>
                            <select onChange={(event) => setTypeIdentificationUser(event.target.value)} disabled={isDisabledUser}
                                name='typeIdentificationUser' className='form-control' value={typeIdentificationUser}> 
                                <option value="" >Seleccione una opción</option>
                                <option value="RUC" >RUC</option>
                                <option value="Cedula" >Cedula</option>
                                <option value="Pasaporte" >Pasaporte</option>
                            </select>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="identificationUser"><b>Identificación:</b></label>
                            <input
                                  type='text'
                                  name='identificationUser'
                                  value={identificationUser}
                                  onChange={(event) => setIdentificationUser(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledUser}
                              /> 
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="nameUser"><b>Nombres:</b></label>
                              <input
                                  type='text'
                                  name='nameUser'
                                  value={nameUser}
                                  onChange={(event) => setNameUser(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledUser}
                              />  
                          </div> 
                          <div className="col-md-6">
                            <label htmlFor="lastNameUser"><b>Apellidos:</b></label>
                              <input
                                  type='text'
                                  name='lastNameUser'
                                  value={lastNameUser}
                                  onChange={(event) => setLastNameUser(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledUser}
                              />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="emailUser"><b>Correo Electrónico:</b></label>
                              <input
                                  type='email'
                                  name='emailUser'
                                  value={emailUser}
                                  onChange={(event) => setEmailUser(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledUser}
                              />
                          </div>  
                          <div className="col-md-6">
                              <label htmlFor="phoneNumberUser"><b>Teléfono:</b></label>    
                              <PhoneInput
                                name="phoneNumberUser"
                                placeholder="Ingrese un número telefónico"
                                value={phoneNumberUser}
                                onChange={setPhoneNumberUser}
                                className='form-control'
                                disabled={isDisabledUser}
                              />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="sexUser"><b>Sexo:</b></label>
                              <select onChange={(event) => setSexUser(event.target.value)} disabled={isDisabledUser}
                                name='sexUser' className='form-control' value={sexUser}> 
                                <option value="" >Seleccione una opción</option>
                                <option value="Masculino" >Masculino</option>
                                <option value="Femenino" >Femenino</option>
                              </select>
                          </div>    
                          <div className="col-md-6">
                            <label htmlFor="birthdayUser"><b>F. Nacimiento:</b></label>
                              <input
                                  type='date'
                                  name='birthdayUser'
                                  value={birthdayUser}
                                  onChange={(event) => setBirthdayUser(event.target.value)}
                                  className='form-control'
                                  disabled={isDisabledUser}
                              />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="roleUser"><b>Rol:</b></label>
                            <select onChange={(event) => setRoleUser(event.target.value)} disabled={isDisabledUserEdit}
                                name='roleUser' className='form-control' value={roleUser}> 
                                <option value="" >Seleccione una opción</option>
                                <option value="ADMIN_ORGANIZATION" >Admin</option>
                                <option value="MONITOR_ORGANIZATION" >Monitor</option>
                            </select>
                          </div>
                          <div className="col-md-6">    
                            <label htmlFor="isActiveUser"><b>Estado:</b></label>
                            <div className='form-check form-switch form-control'>
                              <input className='form-check-input'
                                type ='checkbox'
                                id ='isActiveUser'
                                name ='isActiveUser'
                                value ={isActiveUser}
                                onChange={(event) => setIsActiveUser(event.target.checked)}
                                disabled={isDisabledUserEdit}
                              />
                              <label className='form-check-label' htmlFor='isActiveUser'>
                                Activo
                              </label>
                          </div>
                        </div>
                        <div className='row'>&nbsp;</div>
                        <div className='row col-md-12'>
                            <div className="col-md-4">
                              <p style={({display: 'none'})}>{idUser}</p>
                            </div>
                            <div className="col-md-4">
                              <button type='button' onClick={addUser}  className='btn btn-primary form-control' disabled={isDisabledUserEdit} >
                                <AiOutlineUserAdd /> &nbsp; 

                                {isLoadingUpdateUser ? (
                                    <span className='spinner-border spinner-border-sm' />
                                  ) : ('')}

                                  {editUser ? ' Actualizar' : ' Crear '}

                              </button>    
                            </div>
                            <div className="col-md-4">
                              
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default FormOrganizations
