import { Spinner, Message } from '..'
import { AiFillProfile,AiOutlineFileDone,AiOutlineDeploymentUnit, AiOutlineEdit  } from "react-icons/ai"
import {
  inputCheckBox,
} from '../../utils/dynamicForm'

import React from "react"

const FormIndividuals = ({
  id,
  edit,
  formCleanHandler,
  isLoading,
  register,
  isError,
  errors,
  watch,
  isLoadingUpdate,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  error,
}) => {


  

  return (
    <div
      className='modal fade'
      id='individualModal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='individualModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-grande'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='individualModalLabel'>
              {edit ? 'Editar Usuario Individual' : 'Crear Usuario Individual'}
            </h3>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={formCleanHandler}
            ></button>
          </div>
          <div className='modal-body'>

            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <a className="nav-link active" id="icon-tab-0" data-bs-toggle="tab" href="#icon-tabpanel-0" role="tab" aria-controls="icon-tabpanel-0" aria-selected="true"><AiFillProfile /> Datos Generales</a>
              </li>
              <li className="nav-item" role="presentation" style={{display: edit === true ? 'block' : 'none'}}>
                <a className="nav-link" id="icon-tab-1" data-bs-toggle="tab" href="#icon-tabpanel-1" role="tab" aria-controls="icon-tabpanel-1" aria-selected="false"><AiOutlineDeploymentUnit /> Sucursales </a>
              </li>
              <li className="nav-item" role="presentation" style={{display: edit === true ? 'block' : 'none'}}>
                <a className="nav-link" id="icon-tab-2" data-bs-toggle="tab" href="#icon-tabpanel-2" role="tab" aria-controls="icon-tabpanel-2" aria-selected="false"><AiOutlineFileDone /> Licencias</a>
              </li>

            </ul>
            <div className="tab-content pt-3" id="tab-content">
              <div className="tab-pane active" id="icon-tabpanel-0" role="tabpanel" aria-labelledby="icon-tab-0">

              {isLoading ? (
              <Spinner /> ) : isError ? ( <Message variant='danger'>{error}</Message> ) : (
              
              <form onSubmit={handleSubmit(submitHandler)}>


                <div className='row'>
                  



                  <div className='col-md-6 col-12'>
                    {inputCheckBox({
                      register,
                      errors,
                      watch,
                      name: 'active',
                      label: 'Activo',
                      isRequired: false,
                      placeholder: 'Activo',
                    })}
                  </div>

                </div>

                <div className='row'>
                        <div className="col-md-12 text-center">
                        <button
                            type='submit'
                            className='btn btn-primary '
                            disabled={isLoadingPost || isLoadingUpdate}
                          >
                           <AiOutlineEdit/> &nbsp; {isLoadingPost || isLoadingUpdate ? (
                              <span className='spinner-border spinner-border-sm' />
                            ) : (
                              edit ? 'Actualizar' : 'Crear '
                            )}
                          </button>    
                        </div>
                  </div>

              </form>
            )}

                
              </div>
              <div className="tab-pane" id="icon-tabpanel-1" role="tabpanel" aria-labelledby="icon-tab-1">
                
              </div>
              <div className="tab-pane" id="icon-tabpanel-2" role="tabpanel" aria-labelledby="icon-tab-2">
                <iframe src={`/admin/auth/individual-licenses?individual_id=${id}`} width="100%"  height="1200px"  ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormIndividuals
