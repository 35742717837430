import React, { useEffect } from 'react';
import Particulas from "./particles.js"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { MdCorporateFare, MdFamilyRestroom } from "react-icons/md";
import { CiClock1, CiBullhorn, CiLocationOn } from "react-icons/ci";
import { PiHandTapThin, PiShareNetworkThin } from "react-icons/pi";
import { FaPeopleRobbery, FaCircleArrowRight } from "react-icons/fa6";
import { GiCctvCamera, GiSiren } from "react-icons/gi";
import { BiCheckCircle } from "react-icons/bi"
import './estilos.css'

const FormContactForms = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>

      <div>
        <Particulas />
      </div>

      {/*servicios*/}
      <div className="sectionServices mb-4">
        <div className="top-icon-box position-relative">
          <div className="container position-relative">
            <div className="row gy-2">
              <div className="col-xl-6 col-md-6" data-aos="fade-up">
                <div className='icon-box'>
                  <div className='iconServices'>
                    <MdFamilyRestroom />
                  </div>
                  <h4 className="servicio-title">Servicio familiar</h4>
                  <p>
                    Accede a nuestro servicio familiar. En donde estarás interconectado
                    con tus familiares, amigos y vecinos.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-md-6" data-aos="fade-up">
                <div className="icon-box">
                  <div className="iconServices">
                    <MdCorporateFare />
                  </div>
                  <h4 className="servicio-title">Servicio corporativo</h4>
                  <p>
                    Accede a nuestro servicio Corporativo. En donde estarás
                    interconectado con tus colaboradores y la seguridad de la Corporación
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*porque elegirnos*/}
      <section className='sectionLanding'>
        <div className="container" id="featured">
          <div className="section-header aos-init aos-animate" data-aos="fade-up">
            <h2>Porque elegir ALERTA GLOBAL</h2>
          </div>
          <div className="row">
            <div className="col-md-4 left">
              <div className="list-wrap aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <div className="description">
                  <h4>Respuesta rápida</h4>
                  <p>Nuestra app garantiza una respuesta inmediata en situaciones de emergencia,
                    alertando a tus contactos de confianza y a la comunidad local en segundos.</p>
                </div>
                <div className="icon">
                  <CiClock1 />
                </div>
              </div>

              <div className="list-wrap aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <div className="description">
                  <h4>Sirena comunitaria</h4>
                  <p>Además de notificar a tus amigos, activamos una sirena comunitaria para alertar a
                    los vecinos y disuadir cualquier actividad ilícita.</p>
                </div>
                <div className="icon">
                  <CiBullhorn />
                </div>
              </div>

              <div className="list-wrap aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
                <div className="description">
                  <h4>Geolocalización precisa</h4>
                  <p>Utilizamos tecnología de geolocalización avanzada para proporcionar tu ubicación
                    exacta a tus contactos y a las autoridades.</p>
                </div>
                <div className="icon">
                  <CiLocationOn />
                </div>
              </div>
            </div>
            <div className="col-md-4 p-4 p-sm-5 center">
              <div className="list-center-wrap aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <div className="center-icon">
                  <img src="../../boton-principal.png" alt="icon" />
                </div>
              </div>
            </div>
            <div className="col-md-4 right">
              <div className="list-wrap aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <div className="icon">
                  <PiHandTapThin />
                </div>
                <div className="description">
                  <h4>Interfaz Intuitiva</h4>
                  <p>Nuestra aplicación es fácil de usar, permitiendo que puedas enviar
                    una señal de auxilio o emergencia con solo un clic o con gestos desde tu celular.</p>
                </div>
              </div>

              <div className="list-wrap aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                <div className="icon">
                  <PiShareNetworkThin />
                </div>
                <div className="description">
                  <h4>Red de seguridad</h4>
                  <p>Crea una red de amigos y vecinos que estarán siempre listos para
                    asistirte en caso de cualquier emergencia.</p>
                </div>
              </div>


              <div className="list-wrap aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
                <div className="icon">
                  <FaPeopleRobbery />
                </div>
                <div className="description">
                  <h4>Prevención del crimen</h4>
                  <p>Contribuimos a la seguridad de la comunidad al prevenir actos ilícitos y
                    reducir el tiempo de respuesta en emergencias.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      {/*funcionamiento*/}
      <section className="sectionLanding">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-8">
              <div className="funcionamiento-header funcionamiento-text-left">
                <h2>Funcionamiento</h2>
              </div>
              <div className="contentFuncionamiento ps-0">
                <p>
                  Alerta Global (AG) puede ser activado por la víctima, desde cualquier dispositivo móvil o computador, enviando una señal de alerta inmediata.
                </p>
                <ul>
                  <li>
                    <div>
                      <FaCircleArrowRight className='iconoRow' /> <h5>Envía alerta</h5>
                    </div>
                    <p>
                      La llamada de alerta por inseguridad será comunicada vía App a la red privada
                      de personas de apoyo a la víctima. Además, se reflejarán datos de la persona y
                      su georreferenciación. En la pantalla se mostrará una alerta de auxilio.
                      En los servicios corporativos, se notificará al departamento correspondiente
                      y a la central de seguridad de la corporación.</p>
                  </li>
                  <li>
                    <div>
                      <FaCircleArrowRight className='iconoRow' /> <h5>Activación geolocalización</h5>
                    </div>
                    <p>
                      Al activar la alerta todas las personas de su red podrán conocer su ubicación exacta
                      y en tiempo real para poder dar seguimiento al evento.
                    </p>
                  </li>
                  <li>
                    <div>
                      <FaCircleArrowRight className='iconoRow' /> <h5>Comunicación al personal de seguridad</h5>
                    </div>
                    <p>
                      Al activar la señal de alerta se comunica al personal de seguridad para dar
                      atención a la emergencia y coordinar con los entes respectivos.
                    </p>
                  </li>
                  <li>
                    <div>
                      <FaCircleArrowRight className='iconoRow' /> <h5>Alertas</h5>
                    </div>
                    <p>
                      Al activar la alerta por inseguridad se activará la sirena
                      comunitaria más cercana a su ubicación de esta forma la
                      comunidad del sector podrá también estar alerta ante cualquier eventualidad
                    </p>
                  </li>
                  <li>
                    <div>
                      <FaCircleArrowRight className='iconoRow' /> <h5>Cámaras de seguridad</h5>
                    </div>
                    <p>
                      Se cuenta con un circuito de cámaras de seguridad para visualizar
                      lo que está aconteciendo en el lugar de los hechos.
                    </p>
                  </li>
                  <li>
                    <div>
                      <FaCircleArrowRight className='iconoRow' /> <h5>Asistencia</h5>
                    </div>
                    <p>
                      AG comunica inmediatamente a la central de monitoreo de la organización de asistencia privada,
                      o del gobierno local o nacional con datos de la víctima y sitio del evento.
                      La Organización de Asistencia reporta el evento en el sistema, e inmediatamente comunica al
                      ente respectivo para el auxilio.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 ps-lg-5 position-relative d-none d-lg-block contenedorMock">
              <img src="../../mockup_resized.png" alt="icon" className='img-fluid imgMock' />
            </div>
          </div>

        </div>
      </section>

      {/*precios*/}
      <div className="sectionLanding pricing">
        <div className="container-fluid">
          <div className="container">
            <div className="section-header">
              <h2>Precios de planes</h2>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="card text-center cardPrecie">
                  <div className="title">
                    <h2>Basico</h2>
                  </div>
                  <div className="price">
                    <h4><sup>$</sup>25</h4>
                  </div>
                  <div className="optionPrice">
                    <ul>
                      <li><BiCheckCircle /> Red familiar</li>
                      <li><BiCheckCircle /> ...</li>
                      <li><BiCheckCircle /> ...</li>
                      <li><BiCheckCircle /> ...</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card text-center cardPrecie">
                  <div className="title">
                    <h2>Estandar</h2>
                  </div>
                  <div className="price">
                    <h4><sup>$</sup>50</h4>
                  </div>
                  <div className="optionPrice">
                    <ul>
                      <li><BiCheckCircle /> Red Corporativa</li>
                      <li><BiCheckCircle /> ...</li>
                      <li><BiCheckCircle /> ...</li>
                      <li><BiCheckCircle /> ...</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card text-center cardPrecie">
                  <div className="title">
                    <h2>Premium</h2>
                  </div>
                  <div className="price">
                    <h4><sup>$</sup>100</h4>
                  </div>
                  <div className="optionPrice">
                    <ul>
                      <li><BiCheckCircle /> Rede familiar y corporativa </li>
                      <li><BiCheckCircle /> ...</li>
                      <li><BiCheckCircle /> ...</li>
                      <li><BiCheckCircle /> ...</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Estadisticas*/}
      <div className='sectionLanding'>
        <div id="stats-counter" className="stats-counter">
          <div className="container aos-init aos-animate" data-aos="fade-up">
            <div className="row gy-4 align-items-center">
              <div className="col-lg-12">
                <div className="row call-to-action">
                  <div className="col-lg-4">
                    <div className="stats-item d-flex flex-column align-items-center">
                      <div className="iconEst"><CiBullhorn /></div>
                      <span className="purecounter">600</span>
                      <p><span>Sirenas instaladas</span>para monitoreo</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="stats-item d-flex flex-column align-items-center">
                      <div className="iconEst"><GiCctvCamera /></div>
                      <span className="purecounter">500</span>
                      <p><span>Camaras videovigilancia</span>para monitoreo</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="stats-item d-flex flex-column align-items-center">
                      <div className="iconEst"><GiSiren /></div>
                      <span className="purecounter">400</span>
                      <p><span>Actos ilícitos</span>atendidos oportunamente</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default FormContactForms;