import { useEffect, useState } from 'react'
import { Message } from '../../components'
import useUploadHook from '../../api/upload'
import useProfilesHook from '../../api/profiles'
import { Helmet } from 'react-helmet'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { AiOutlineUser, AiFillHome, AiFillLock, AiOutlineUserAdd, AiOutlineSchedule, AiOutlineAppstoreAdd,AiOutlineKey, AiOutlineClear } from "react-icons/ai";
import { FaPenAlt, FaTrash } from 'react-icons/fa'
import { Spinner } from '../../components'


const Profile = () => {

  // Inicio carga informacion perfil usuario
  const { getProfile, postProfile } = useProfilesHook({})
  var { data, isLoading, isError, error } = getProfile
  const { isSuccess, isLoading: isLoadingPost, isError: isErrorPost, error: errorPost, mutateAsync } = postProfile
  const [message, setMessage] = useState('')
  const [phoneNumber, setPhoneNumber] = useState()
  const [sex, setSex] = useState('')
  const [birthday, setBirthday] = useState('')
  const [typeIdentification, setTypeIdentification] = useState('')
  const [identification, setIdentification] = useState('')


  useEffect(() => {
    setPhoneNumber(!isLoading ? data && data.phone : '')
    setSex(!isLoading ? data && data.sex : '')
    setBirthday(!isLoading ? data && data.birthday && data.birthday.substring(0, 10) : '')
    setTypeIdentification(!isLoading ? data && data.typeIdentification : '')
    setIdentification(!isLoading ? data && data.identification : '')    
  }, [isLoading, data])

// Inicio Actualizar foto de perfil
const { postUpload } = useUploadHook()
var { isError: isErrorUpload, error: errorUpload, mutateAsync: mutateAsyncUpload} = postUpload
const handleFileChange = (e) => {
  document.getElementById("upload-image-profile").style.display = "inline-block";
  if (e.target.files) {
    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = async () => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      const imageUpload = await mutateAsyncUpload({type: 'image-user', formData, id: data._id })
      data.image = imageUpload.image
      document.getElementById("upload-image-profile").style.display= 'none'
    }
  }  
}
// Fin Actualizar foto de perfil


 // Inicio Actualizacion Datos Personales
 const updatePersonalData = async () => {

    const retornoUpdatePersonalData = await mutateAsync({
      phone: phoneNumber,
      sex: sex,
      birthday: birthday,
      typeIdentification: typeIdentification,
      identification: identification,
      type: 'update-personal-data',
    })
    if(retornoUpdatePersonalData!= undefined) {
      setMessage(retornoUpdatePersonalData.message)
    } 

  } 
  // Fin Actualizacion Datos Personales


 // Inicio Cambio de Clave

const [inputChangePassword, setInputChangePassword] = useState({ password: '', confirmPassword: '' })
const [errorChangePassword, setErrorChangePassword] = useState({ password: '', confirmPassword: '' })

const onInputChange = e => {
  const { name, value } = e.target;
  setInputChangePassword(prev => ({
    ...prev,
    [name]: value
  }));
  validateInput(e);
}

const validateInput = e => {
  let { name, value } = e.target;
  setErrorChangePassword(prev => {
    const stateObj = { ...prev, [name]: "" };
 
    switch (name) {
 
      case "password":
        if (!value) {
          stateObj[name] = "Ingrese la Contraseña.";
        } else if (inputChangePassword.confirmPassword && value !== inputChangePassword.confirmPassword) {
          stateObj["confirmPassword"] = "Contraseña y Confirmar Contraseña no coinciden.";
        } else {
          stateObj["confirmPassword"] = inputChangePassword.confirmPassword ? "" : errorChangePassword.confirmPassword;
        }
        break;
 
      case "confirmPassword":
        if (!value) {
          stateObj[name] = "Ingrese la Confirmación de la Contraseña.";
        } else if (inputChangePassword.password && value !== inputChangePassword.password) {
          stateObj[name] = "Contraseña y Confirmar Contraseña no coinciden.";
        }
        break;
 
      default:
        break;
    } 
    return stateObj;
  })
}

const changePassword = async () => {

   const retornoUpdatePassword = await mutateAsync({
      password: inputChangePassword.password,
      type: 'update-password',
    })
    if(retornoUpdatePassword!= undefined) {
      setMessage(retornoUpdatePassword.message)
    } 
}

const cleanPassword = () => {
  setInputChangePassword({password: '', confirmPassword: '' })
  setErrorChangePassword({password: '', confirmPassword: '' })
}

// Fin Cambio de Clave




  

  // Inicio Address
  const [idAddress, setIdAddress] = useState('')
  const [typeAddress, setTypeAddress] = useState('')
  const [mainStreetAddress, setMainStreetAddress] = useState('')
  const [secondaryStreetAddress, setSecondaryStreetAddress] = useState('')
  const [sectorAddress, setSectorAddress] = useState('')
  const [numberAddress, setNumberAddress] = useState('')
  const [locationAddress, setLocationAddress] = useState('')
  const [editAddress, setEditAddress] = useState(false)
  var addressList = []

  const cleanAddress = async () => {
    setIdAddress('')
    setTypeAddress('')
    setMainStreetAddress('')
    setSecondaryStreetAddress('')
    setSectorAddress('')
    setNumberAddress('')
    setLocationAddress('')
    setEditAddress(false)
  }

  const addAddress = async ()  => {
    console.log(idAddress)
    /*
    membersListTemporal.push({
      _id: idMember,
      typeIdentification:typeIdentificationMember,
      identification:identificationMember,
      birthday: birthdayMember,
      sex: sexMember,
      name: nameMember,
      lastname: lastNameMember,
      email: emailMember,
      password: '123456',
      phone: phoneNumberMember,  
    })

    const networkUpdate = await mutateAsyncUpdate({_id: id, 
          name: '',
          type: '',
          userLimit: '',
          active: '',
          members: membersListTemporal,
          deleteMembers: '0',deleteFriends: '0',deleteUsers: '0'})

      // Seteamos las variables  
      setIdMember('')
      setEmailSearchMember('')
      setTypeIdentificationMember('')
      setIdentificationMember('')
      setBirthdayMember('')
      setSexMember('')
      setNameMember('')
      setLastNameMember('')
      setEmailMember(''),
      setPhoneNumberMember('')
      setEditMember(false)
      membersListTemporal = []

      members.splice(0)

      for (let i = 0; i < networkUpdate.member.length; i++) {
        members.push({
          _id: networkUpdate.member[i].user._id,
          typeIdentification: networkUpdate.member[i].user.typeIdentification,
          identification: networkUpdate.member[i].user.identification,
          birthday: networkUpdate.member[i].user.birthday,
          sex: networkUpdate.member[i].user.sex,
          name: networkUpdate.member[i].user.name,
          lastname: networkUpdate.member[i].user.lastname,
          email: networkUpdate.member[i].user.email,
          phone: networkUpdate.member[i].user.phone,  
        })
      }
      */
  }
  const editAddressFunction = (idProfile) => {

    setEditAddress(true)
    var addressFound = data.address.find((e) => e._id == idProfile)
    setIdAddress(addressFound._id)
    setTypeAddress(addressFound.type)
    setMainStreetAddress(addressFound.mainStreet)
    setSecondaryStreetAddress(addressFound.secondaryStreet)
    setSectorAddress(addressFound.sector)
    setNumberAddress(addressFound.number)
    setLocationAddress(addressFound.location)
  }

  const deleteAddressFunction = async (idAddress) => {
  
    const listAddress = data.address.filter(address =>address._id !== idAddress)
    console.log(listAddress)
  /*
    const networkUpdate =  await mutateAsyncUpdate({_id: id, 
      addresss: listAddress,
      deleteAddresss: '1',
    })
  
    console.log(networkUpdate.address)
  
    address.splice(0)
  
  
      for (let i = 0; i < networkUpdate.friend.length; i++) {
        friends.push({
          _id: networkUpdate.friend[i].user._id,
          typeIdentification: networkUpdate.friend[i].user.typeIdentification,
          identification: networkUpdate.friend[i].user.identification,
          birthday: networkUpdate.friend[i].user.birthday,
          sex: networkUpdate.friend[i].user.sex,
          name: networkUpdate.friend[i].user.name,
          lastname: networkUpdate.friend[i].user.lastname,
          email: networkUpdate.friend[i].user.email,
          phone: networkUpdate.friend[i].user.phone,  
        })
      }
      */
  }

  // Inicio Device
  const [idDevice, setIdDevice] = useState('')
  const [typeDevice, setTypeDevice] = useState('')
  const [nameDevice, setNameDevice] = useState('')
  const [macAddressDevice, setMacAddressDevice] = useState('')
  const [editDevice, setEditDevice] = useState(false)
  
  var deviceList = []

  const cleanDevice = async () => {
    setIdDevice('')
    setTypeDevice('')
    setNameDevice('')
    setMacAddressDevice('')
    setEditDevice(false)
  }

  const addDevice = async ()  => {
    console.log(idDevice)
    /*
    membersListTemporal.push({
      _id: idMember,
      typeIdentification:typeIdentificationMember,
      identification:identificationMember,
      birthday: birthdayMember,
      sex: sexMember,
      name: nameMember,
      lastname: lastNameMember,
      email: emailMember,
      password: '123456',
      phone: phoneNumberMember,  
    })

    const networkUpdate = await mutateAsyncUpdate({_id: id, 
          name: '',
          type: '',
          userLimit: '',
          active: '',
          members: membersListTemporal,
          deleteMembers: '0',deleteFriends: '0',deleteUsers: '0'})

      // Seteamos las variables  
      setIdMember('')
      setEmailSearchMember('')
      setTypeIdentificationMember('')
      setIdentificationMember('')
      setBirthdayMember('')
      setSexMember('')
      setNameMember('')
      setLastNameMember('')
      setEmailMember(''),
      setPhoneNumberMember('')
      setEditMember(false)
      membersListTemporal = []

      members.splice(0)

      for (let i = 0; i < networkUpdate.member.length; i++) {
        members.push({
          _id: networkUpdate.member[i].user._id,
          typeIdentification: networkUpdate.member[i].user.typeIdentification,
          identification: networkUpdate.member[i].user.identification,
          birthday: networkUpdate.member[i].user.birthday,
          sex: networkUpdate.member[i].user.sex,
          name: networkUpdate.member[i].user.name,
          lastname: networkUpdate.member[i].user.lastname,
          email: networkUpdate.member[i].user.email,
          phone: networkUpdate.member[i].user.phone,  
        })
      }
      */
  }
  const editDeviceFunction = (idDevice) => {

    setEditDevice(true)
    var deviceFound = data.device.find((e) => e._id == idDevice)
    setIdDevice(deviceFound._id)
    setTypeDevice(deviceFound.type)
    setNameDevice(deviceFound.name)
    setMacAddressDevice(deviceFound.macAddress)
  }

  const deleteDeviceFunction = async (idDevice) => {
  
    const listDevice = data.device.filter(device =>device._id !== idDevice)
    console.log(listDevice)
  /*
    const networkUpdate =  await mutateAsyncUpdate({_id: id, 
      addresss: listDevice,
      deleteDevices: '1',
    })
  
    console.log(networkUpdate.address)
  
    address.splice(0)
  
  
      for (let i = 0; i < networkUpdate.friend.length; i++) {
        friends.push({
          _id: networkUpdate.friend[i].user._id,
          typeIdentification: networkUpdate.friend[i].user.typeIdentification,
          identification: networkUpdate.friend[i].user.identification,
          birthday: networkUpdate.friend[i].user.birthday,
          sex: networkUpdate.friend[i].user.sex,
          name: networkUpdate.friend[i].user.name,
          lastname: networkUpdate.friend[i].user.lastname,
          email: networkUpdate.friend[i].user.email,
          phone: networkUpdate.friend[i].user.phone,  
        })
      }
      */
  }

 


  

 
 

 









/*  const submitHandler = (data) => {
    console.log('xxxxx') 
    console.log(data.password)
    if(data.password==''){ 
      mutateAsync({
        phone: phoneNumber,
        sex: data.sex,
        birthday: data.birthday,
        typeIdentification: data.typeIdentification,
        identification: data.identification,
      })
      }
      else {
        mutateAsync({
          phone: phoneNumber,
          sex: data.sex,
          birthday: data.birthday,
          typeIdentification: data.typeIdentification,
          identification: data.identification,
        })
        }
      } 
  */

  return (
    <>
      <Helmet>
        <title>Perfil del Usuario</title>
        <meta property='og:title' content='Perfil' key='title' />
      </Helmet>
      <div id="containerNetwork" name="containerNetwork" className='table-responsive bg-light p-3 mt-2'>
        <div className='d-flex align-items-center flex-column mb-2'>
          <h3 className='fw-light font-monospace text-center'>Perfil del Usuario</h3>
          {isErrorPost && <Message variant='danger'>{errorPost}</Message>}
          {isErrorUpload && <Message variant='danger'>{errorUpload}</Message>}
          {isError && <Message variant='danger'>{error}</Message>}
          {isSuccess && (
            <Message variant='success'>{message}</Message>
          )}
            
          {isLoading && <Spinner />}
          
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <a className="nav-link active" id="icon-tab-0" data-bs-toggle="tab" href="#icon-tabpanel-0" role="tab" aria-controls="icon-tabpanel-0" aria-selected="true"><AiOutlineUser /> Datos Generales</a>
            </li>
            <li className="nav-item" role="presentation">
              <a className="nav-link" id="icon-tab-1" data-bs-toggle="tab" href="#icon-tabpanel-1" role="tab" aria-controls="icon-tabpanel-1" aria-selected="false"><AiFillHome /> Direcciones </a>
            </li>
            <li className="nav-item" role="presentation">
              <a className="nav-link" id="icon-tab-2" data-bs-toggle="tab" href="#icon-tabpanel-2" role="tab" aria-controls="icon-tabpanel-2" aria-selected="false"><AiFillLock /> Cambio Contraseña</a>
            </li>
            <li className="nav-item" role="presentation">
              <a className="nav-link" id="icon-tab-3" data-bs-toggle="tab" href="#icon-tabpanel-3" role="tab" aria-controls="icon-tabpanel-3" aria-selected="false"><AiOutlineAppstoreAdd  /> Mis Dispositivos</a>
            </li>
          </ul>
          <div className="tab-content pt-3" id="tab-content">
            <div className="tab-pane active" id="icon-tabpanel-0" role="tabpanel" aria-labelledby="icon-tab-0">
              
                <div className='row'>
                  <div className='col-md-6'>
                      {data && data.image && (
                        <div className='d-flex justify-content-center'>
                          <img
                            src={data && data.image}
                            alt=''
                            className='rounded-circle'
                            width='150'
                            height='150'
                          />
                        </div>
                      )}
                    <div className='row'>
                      <label htmlFor="file" className="sr-only">
                        &nbsp;
                      </label>
                      <div className='row'>
                          <center>
                          &nbsp;&nbsp;
                          <label htmlFor="image" className="btn btn-primary btn-block btn-outlined">
                          
                            <span id="upload-image-profile" className='spinner-border spinner-border-sm' style={{ display: 'none' }} />
                            &nbsp;
                            Actualice su foto de perfil
                            </label>
                          <input id="image" type="file" accept="image/*" onChange={handleFileChange} className='form-control' style={{ display: 'none' }} />
                          </center>
                      </div>
                      
                    </div>
                    <hr></hr>    
                    <div className='row text-center'>
                      <label><b>Nombre Completo:</b></label>
                    </div>
                    <div className='row text-center'>
                      <label>{data && data.name} {data && data.lastname}</label>
                    </div>
                    <div className='row text-center'>
                      <label><b>Correo electrónico:</b></label>
                    </div>
                    <div className='row text-center'>
                      <label>{data && data.email}</label>
                    </div>
              
                  </div>
                  <div className='col-md-6'>
                     
                    <div className='row'>
                      <label htmlFor="phone"><b>Teléfono:</b></label>    
                      <PhoneInput
                        name="phone"
                        placeholder="Ingrese un número telefónico"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        className='form-control'
                      />  
                    </div>
                    <div className='row'>
                      <div className="col-md-6">
                      <label htmlFor="sex"><b>Sexo:</b></label>
                            <select onChange={(event) => setSex(event.target.value)} 
                                name='sex' className='form-control' value={sex}> 
                                <option key="" >Seleccione una opción</option>
                                <option key="Masculino" >Masculino</option>
                                <option key="Femenino" >Femenino</option>
                            </select>
                      </div>
                      <div className="col-md-6">
                            <label htmlFor="birthday"><b>F. Nacimiento:</b></label>
                            <input
                                  type='date'
                                  name='birthday'
                                  value={birthday}
                                  onChange={(event) => setBirthday(event.target.value)}
                                  className='form-control'
                              />
                      </div>  
                    </div>
                    <div className='row'>
                      <div className="col-md-6">
                        <label htmlFor="typeIdentification"><b>Tipo Identificación:</b></label>
                        <select onChange={(event) => setTypeIdentification(event.target.value)} 
                            name='typeIdentification' className='form-control' value={typeIdentification}> 
                            <option key="" >Seleccione una opción</option>
                            <option key="RUC" >RUC</option>
                            <option key="Cedula" >Cedula</option>
                            <option key="Pasaporte" >Pasaporte</option>
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="identificationUser"><b>Identificación:</b></label>
                        <input
                          type='text'
                          name='identification'
                          value={identification}
                          onChange={(event) => setIdentification(event.target.value)}
                          className='form-control'
                        />
                      </div>
                    </div>
                    <br></br>
                    <div className='row'>
                      <div className="col-md-1">
                        
                      </div>
                      <div className="col-md-10">
                        <button type='button' onClick={() => updatePersonalData()}  className='btn btn-primary form-control' disabled={isLoadingPost}>
                          {isLoadingPost ? (
                            <span className='spinner-border spinner-border-sm' />
                          ) : (
                            'Actualizar Datos Personales'
                          )}
                        </button>    
                      </div>
                      <div className="col-md-1">
                        
                      </div>
                    </div> 
                  </div>
                </div>        
                  
            </div>
            <div className="tab-pane" id="icon-tabpanel-1" role="tabpanel" aria-labelledby="icon-tab-1">

              <center><a className="btn btn-primary" href="#open-modal-address" onClick={() => cleanAddress()}> <AiOutlineSchedule  /> &nbsp;Agregar una Dirección</a></center>
              <br></br>

              <table className='table * table-sm table-border'>
                <thead className='border-0'>
                  <tr className='text-center'>
                    <th>Tipo</th>
                    <th>Calle Principal</th>
                    <th>Calle Secundaria</th>
                    <th>Sector</th>
                    <th>Número</th>
                    <th>Localización</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                          
                { addressList.map(( listValue, index ) => {
                return (
                  <tr key={index} className='text-center'>
                    <td>{listValue.type}</td>
                    <td>{listValue.mainStreet}</td>
                    <td>{listValue.secondaryStreet}</td>
                    <td>{listValue.sector}</td>
                    <td>{listValue.number}</td>
                    <td>
                      <div className='btn-group'>
                        <a className="btn btn-primary btn-sm rounded-pill"  href="#open-modal-address"  onClick={() => editAddressFunction(listValue._id)}> <FaPenAlt /></a>
                        <button className='btn btn-danger btn-sm ms-1 rounded-pill'   onClick={() => { if (window.confirm('Desea eliminar la dirección ?')) deleteAddressFunction(listValue._id) } }>
                          <span><FaTrash /></span>
                        </button>
                      </div>
                    </td>
                  </tr>
                  )
                })}          
                </tbody>     
              </table>
                <div id="open-modal-address" className="modal-window-internal">
                <div>
                  <b><h5>
                  {editAddress ? 'Editar Dirección' : 'Agregar Dirección'}
                  </h5></b>
                  <a href="#" title="Close" className="modal-close">&#x2715;</a>
                  <hr></hr>  
                  <div className='row'>
                    <div className="col-md-6"> 
                      <label htmlFor="typeAddress"><b>Tipo:</b></label>
                      <select onChange={(event) => setTypeAddress(event.target.value)} 
                        name='typeAddress' className='form-control' value={typeAddress}> 
                        <option key="" >Seleccione una opción</option>
                        <option key="Trabajo" >Trabajo</option>
                        <option key="Domicilio" >Domicilio</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="mainStreetAddress"><b>Calle Principal:</b></label>
                      <input
                        type='text'
                        name='mainStreetAddress'
                        value={mainStreetAddress}
                        onChange={(event) => setMainStreetAddress(event.target.value)}
                        className='form-control'
                      /> 
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="secondaryStreetAddress"><b>Calle Secundaria:</b></label>
                      <input
                        type='text'
                        name='secondaryStreetAddress'
                        value={secondaryStreetAddress}
                        onChange={(event) => setSecondaryStreetAddress(event.target.value)}
                        className='form-control'
                      /> 
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="sectorAddress"><b>Sector:</b></label>
                      <input
                        type='text'
                        name='sectorAddress'
                        value={sectorAddress}
                        onChange={(event) => setSectorAddress(event.target.value)}
                        className='form-control'
                      /> 
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="numberAddress"><b>Número:</b></label>
                      <input
                        type='text'
                        name='numberAddress'
                        value={numberAddress}
                        onChange={(event) => setNumberAddress(event.target.value)}
                        className='form-control'
                      /> 
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="locationAddress"><b>Localización:</b></label>
                      <input
                        type='text'
                        name='locationAddress'
                        value={locationAddress}
                        onChange={(event) => setLocationAddress(event.target.value)}
                        className='form-control'
                      /> 
                    </div>
                    <div className='row'>&nbsp;</div>
                      <div className='row'>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                          <button type='button' onClick={addAddress}  className='btn btn-primary form-control'  > 
                            <AiOutlineUserAdd /> &nbsp; {editAddress ? 'Actualizar' : 'Crear '}
                          </button>    
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>            
                  </div>
                </div>            

            </div>
            <div className="tab-pane" id="icon-tabpanel-2" role="tabpanel" aria-labelledby="icon-tab-2"> 
              <div className="card">
                <div className="card-header">
                  <div className="alert alert-primary" role="alert">
                  Ingrese los valores requeridos para realizar el proceso de cambio de contraseña.<br></br>
                  Mínimo 6 caracteres, al menos 1 letra minúscula, 1 letra mayúscula y 1 número.
                  </div>
                </div>
                <div className="card-body">
                    
                  <div className="row">
                      <div className='col-md-6'>
                        <input
                          type="password"
                          name="password"
                          placeholder='Ingrese la Contraseña'
                          value={inputChangePassword.password}
                          onChange={onInputChange}
                          onBlur={validateInput} className='form-control' ></input>
                        <center>{errorChangePassword.password && <span className='err' style={({color:'red'})}>{errorChangePassword.password}</span>}</center>
                      </div>
                      <div className='col-md-6'>
                        <input
                          type="password"
                          name="confirmPassword"
                          placeholder='Confirme la Contraseña'
                          value={inputChangePassword.confirmPassword}
                          onChange={onInputChange}
                          onBlur={validateInput} className='form-control'></input>
                        <center>{errorChangePassword.confirmPassword && <span className='err' style={({color:'red'})}>{errorChangePassword.confirmPassword}</span>}</center>
                      </div>
                  </div>
                  <br></br>
                  <div className="row">
                      <div className='col-md-6'>
                          <button type='button' className='btn btn-primary form-control' disabled={isLoading}  onClick={changePassword}>
                          {isLoading ? (
                            <span className='spinner-border spinner-border-sm' />
                          ) : ( 
                            ''
                          )} <AiOutlineKey  /> &nbsp; Cambiar
                        </button> &nbsp;
                      </div>
                      <div className='col-md-6'>
                        <button type='button' className='btn btn-primary form-control'  onClick={() => cleanPassword()}>
                          <AiOutlineClear  /> &nbsp; Limpiar
                        </button>
                      </div>
                  </div>
                
                </div>       
              </div>
            </div>
            <div className="tab-pane" id="icon-tabpanel-3" role="tabpanel" aria-labelledby="icon-tab-3">
            <center><a className="btn btn-primary" href="#open-modal-device" onClick={() => cleanDevice()}> <AiOutlineSchedule  /> &nbsp;Agregar un Dispositivo</a></center>
              <br></br>

              <table className='table * table-sm table-border'>
                <thead className='border-0'>
                  <tr className='text-center'>
                    <th>Tipo</th>
                    <th>Nombre</th>
                    <th>Mac Address</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                          
                { deviceList.map(( listValue, index ) => {
                return (
                  <tr key={index} className='text-center'>
                    <td>{listValue.type}</td>
                    <td>{listValue.name}</td>
                    <td>{listValue.macAddress}</td>
                    <td></td>
                    <td>
                      <div className='btn-group'>
                        <a className="btn btn-primary btn-sm rounded-pill"  href="#open-modal-device"  onClick={() => editDeviceFunction(listValue._id)}> <FaPenAlt /></a>
                        <button className='btn btn-danger btn-sm ms-1 rounded-pill'   onClick={() => { if (window.confirm('Desea eliminar el dispositivo ?')) deleteDeviceFunction(listValue._id) } }>
                          <span><FaTrash /></span>
                        </button>
                      </div>
                    </td>
                  </tr>
                  )
                })}          
                </tbody>     
              </table>
                <div id="open-modal-device" className="modal-window-internal">
                <div>
                  <b><h5>
                  {editDevice ? 'Editar Dispositivo' : 'Agregar Dispositivo'}
                  </h5></b>
                  <a href="#" title="Close" className="modal-close">&#x2715;</a>
                  <hr></hr>  
                  <div className='row'>
                    <div className="col-md-6"> 
                      <label htmlFor="typeDevice"><b>Tipo:</b></label>
                      <select onChange={(event) => setTypeDevice(event.target.value)} 
                        name='typeDevice' className='form-control' value={typeDevice}> 
                        <option key="" >Seleccione una opción</option>
                        <option key="BLC" >BLC</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="nameDevice"><b>Nombre:</b></label>
                      <input
                        type='text'
                        name='nameDevice'
                        value={nameDevice}
                        onChange={(event) => setNameDevice(event.target.value)}
                        className='form-control'
                      /> 
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="macAddressDevice"><b>MAC Address:</b></label>
                      <input
                        type='text'
                        name='macAddressDevice'
                        value={macAddressDevice}
                        onChange={(event) => setMacAddressDevice(event.target.value)}
                        className='form-control'
                      /> 
                    </div>
            
                    <div className='row'>&nbsp;</div>
                      <div className='row'>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                          <button type='button' onClick={addDevice}  className='btn btn-primary form-control'  > 
                            <AiOutlineUserAdd /> &nbsp; {editDevice ? 'Actualizar' : 'Crear '}
                          </button>    
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>            
                  </div>
                </div>
                        
            </div>
            
          </div>
        </div>      
      </div>          
    </>
  )
}

export default Profile
