import dynamicAPI from './dynamicAPI'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useLocation  } from 'react-router-dom'

const url = '/api/auth/individual-licenses'

const queryKey = 'individual-licenses' 

export default function useIndividualLicensesHook(props) {
  const { page = 1, id, q = '', limit = 25 } = props
  const queryClient = useQueryClient()

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const individual_id = searchParams.get('individual_id')

  const getIndividualLicenses = individual_id!=undefined ? useQuery(
    queryKey,
    async () =>
      await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}&Individual_id=${individual_id}`, {}),
    { retry: 0 }
  ):''

  const getIndividualLicenseById = useQuery(
    queryKey,
    async (id) => await dynamicAPI('get', `${url}/${id}`, {}),
    { retry: 0, enabled: !!id }
  )

  const updateIndividualLicense = useMutation(
    async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  const deleteIndividualLicense = useMutation(
    async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  const postIndividualLicense = useMutation(
    async (obj) => await dynamicAPI('post', url, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  return {
    getIndividualLicenses,
    updateIndividualLicense,
    deleteIndividualLicense,
    postIndividualLicense,
    getIndividualLicenseById,
  }
}
