const Footer = () => {
   const date = new Date()
   const currentYear = date.getFullYear()

  return (
    <footer className='text-primary container-fluid'>
      <div className='row'>
        <div className='col text-center py-1 footer font-monospace bg-light my-auto'>
          <br />
          Copyright {currentYear} &copy; Todos los derechos reservados - Desarrollado por {' '}
          <a target='_blank' href='https://constecoin.com/' rel='noreferrer'>
            Constecoin
          </a> - {' '} <a href='/auth/terminos-condiciones' rel='noreferrer'>Términos y Condiciones</a>
          <br />
          <br />
          
        </div>
      </div>
    </footer>
  )
}

export default Footer
