import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import useIndividualLicensesHook from '../../../api/individual-licenses'

import FormIndividualLicenses from '../../../components/individual-licenses/FormIndividualLicenses'
import ViewIndividualLicenses from '../../../components/individual-licenses/ViewIndividualLicenses'

import {
  Spinner,
  Pagination,
  Message,
} from '../../../components'

const IndividualLicenses = () => {
  const [page, setPage] = useState(1)
  const [edit, setEdit] = useState(false)
  const [q, setQ] = useState('')
  const [id, setId] = useState(null)
  const [individualLicenseObject, setIndividualLicenseObject] = useState(null)

  const { getIndividualLicenses, deleteIndividualLicense } =
    useIndividualLicensesHook({
      page,
      q,
    })

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      auth: true,
    },
  })

  
  const { data, isLoading, isError, error, refetch } = getIndividualLicenses



  const {
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    error: errorDelete,
    isSuccess: isSuccessDelete,
    mutateAsync: mutateAsyncDelete,
  } = deleteIndividualLicense



  const formCleanHandler = () => {
    setEdit(false)
    reset()
  }

  useEffect(() => {
    refetch()
  }, [page])

  useEffect(() => {
    if (!q) refetch()
  }, [q])

  const searchHandler = (e) => {
    e.preventDefault()
    refetch()
    setPage(1)
  }

  const deleteHandler = (id) => {
    mutateAsyncDelete(id)
  }

  const editHandler = (individualLicense) => {
    refetch()
    setId(individualLicense._id)
    setEdit(true)
    setIndividualLicenseObject(individualLicense)
  }

  return (
    <>
      <Helmet>
        <title>Historial de Licencias</title>
        <meta property='og:title' content='Licencias' key='title' />
      </Helmet>
      {isSuccessDelete && (
        <Message variant='success'>
          Licencia eliminada correctamente.
        </Message>
      )}
      {isErrorDelete && <Message variant='danger'>{errorDelete}</Message>}


      <FormIndividualLicenses
        id={id}
        individualLicenseObject={individualLicenseObject}
        edit={edit}
        formCleanHandler={formCleanHandler}
        isLoading={isLoading}
        isError={isError}
        errors={errors}
        register={register}
        handleSubmit={handleSubmit}
        watch={watch}
        error={error}
      />

      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <ViewIndividualLicenses
          data={data}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
          isLoadingDelete={isLoadingDelete}
          setQ={setQ}
          q={q}
          searchHandler={searchHandler}
        />
      )}
      <div className='ms-auto'>
        <center><Pagination data={data} setPage={setPage} /></center>
      </div>
    </>
  )
}

export default IndividualLicenses
