import { FaPenAlt,  FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { Search } from '..'

const ViewOrganizations = ({
  data,
  editHandler,
  setQ,
  q,
  searchHandler,
  isAdmin
}) => {
  return (
    <div className='table-responsive bg-light p-3 mt-2'>
      <div className='d-flex align-items-center flex-column mb-2'>
        <h3 className='fw-light text-muted'>
        { isAdmin==true ? 
          <p> Listado de organizaciones <sup className='fs-6'>[{data && data.length}] </sup></p>
          : 'Configuraciones de la Organización'
        }
        </h3>
        {isAdmin ? (
        <button
          className='btn btn-outline-primary btn-sm shadow my-2'
          data-bs-toggle='modal'
          data-bs-target='#organizationModal'
          onClick={() =>document.getElementById("icon-tab-0").click()}
        >
          Agregar una organización
        </button>):''}


        {isAdmin ? (
        <div className='col-auto' >
          <Search
            placeholder='Buscar por nombre'
            setQ={setQ}
            q={q}
            searchHandler={searchHandler}
          />
        </div>):''}

      </div>
      <table className='table table-sm table-border text-center'>
        <thead className='border-0'>
          <tr>
            <th>Nombre</th>
            <th>Identificación</th>
            <th>Descripción</th>
            <th>Es Público?</th>
            <th>Activo</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody>
          {data ?
            data.map((organization) => (
              <tr key={organization._id}>
                <td>{organization.name}</td>
                <td>{organization.identification}</td>
                <td>{organization.description}</td>
                <td>
                  {organization.isPublic ? (
                    <FaCheckCircle className='text-success' />
                  ) : (
                    <FaTimesCircle className='text-danger' />
                  )}
                </td>
                <td>
                  {organization.active ? (
                    <FaCheckCircle className='text-success' />
                  ) : (
                    <FaTimesCircle className='text-danger' />
                  )}
                </td>

                <td>
                  <div className='btn-group'>
                    <button
                      className='btn btn-primary btn-sm rounded-pill'
                      onClick={() => editHandler(organization)}
                      data-bs-toggle='modal'
                      data-bs-target='#organizationModal'
                    >
                      <FaPenAlt />
                    </button>

                  </div>
                </td>
              </tr>
            )):''}
        </tbody>
      </table>
    </div>
  )
}

export default ViewOrganizations
