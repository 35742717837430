import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import useIndividualsHook from '../../../api/individuals'
import {
  Spinner,
  Pagination,
  Message,
} from '../../../components'
import FormIndividuals from '../../../components/individuals/FormIndividuals'
import ViewIndividuals from '../../../components/individuals/ViewIndividuals'

const Individuals = () => {
  const [page, setPage] = useState(1)
  const [id, setId] = useState('')
  const [edit, setEdit] = useState(false)
  const [q, setQ] = useState('')

  const { getIndividuals, postIndividual, updateIndividual } =
    useIndividualsHook({
      page,
      q,
    })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      auth: true,
    },
  })

  const { data, isLoading, isError, error, refetch } = getIndividuals

  const {
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
    isSuccess: isSuccessUpdate,
    mutateAsync: mutateAsyncUpdate,
  } = updateIndividual


  const {
    isLoading: isLoadingPost,
    isError: isErrorPost,
    error: errorPost,
    isSuccess: isSuccessPost,
    mutateAsync: mutateAsyncPost,
  } = postIndividual

  const formCleanHandler = () => {
    setEdit(false)
    reset()
  }

  useEffect(() => {
    if (isSuccessPost || isSuccessUpdate) formCleanHandler()
  }, [isSuccessPost, isSuccessUpdate])

  useEffect(() => {
    refetch()
  }, [page])

  useEffect(() => {
    if (!q) refetch()
  }, [q])

  const searchHandler = (e) => {
    e.preventDefault()
    refetch()
    setPage(1)
  }

  const submitHandler = (data) => {
    edit
      ? mutateAsyncUpdate({
          _id: id,
          name: data.name,
          typeIdentification: data.typeIdentification,
          identification: data.identification,
          description: data.description,
          ispublic: data.ispublic,
          active: data.active,
        })
      : mutateAsyncPost(data)
  }

  const editHandler = (individual) => {
    setId(individual._id)
    setEdit(true)
    setValue('active', individual.active)
    setValue('userTypeIdentification', individual.user.typeIdentification)
    setValue('userIdentification', individual.user.identification)
    setValue('userBirthday', individual.user.birthday)
    setValue('userSex', individual.user.sex)
    setValue('userName', individual.user.name)
    setValue('userLastName', individual.user.lastname)
    setValue('userEmail', individual.user.email)
    setValue('userPhone', individual.user.phone)
  }

  return (
    <>
      <Helmet>
        <title>Usuarios Individuales</title>
        <meta property='og:title' content='Usuarios Individuales' key='title' />
      </Helmet>
      {isSuccessUpdate && (
        <Message variant='success'>
         El Usuario Individual se ha actualizado correctamente.
        </Message>
      )}
      {isErrorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
      {isSuccessPost && (
        <Message variant='success'>
          El Usuario Individual se ha creado correctamente.
        </Message>
      )}
      {isErrorPost && <Message variant='danger'>{errorPost}</Message>}

      <FormIndividuals
        id={id}
        edit={edit}
        formCleanHandler={formCleanHandler}
        isLoading={isLoading}
        isError={isError}
        errors={errors}
        isLoadingUpdate={isLoadingUpdate}
        isLoadingPost={isLoadingPost}
        register={register}
        handleSubmit={handleSubmit}
        submitHandler={submitHandler}
        watch={watch}
        error={error}
      />

      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <ViewIndividuals
          data={data}
          editHandler={editHandler}
          setQ={setQ}
          q={q}
          searchHandler={searchHandler}
        />
      )}
      <div className='ms-auto'>
        <center><Pagination data={data} setPage={setPage} /></center>
      </div>
    </>
  )
}

export default Individuals
